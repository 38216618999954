import { IconEdit, Link, Spacer, Text } from 'dss-ui-library';
import { FC } from 'react';
import styles from './AddressRow.module.scss';

export interface AddressFields {
  zipCode: string;
  street: string;
  city: string;
  houseNumber: string;
}

interface AddressRowProps {
  address: AddressFields;
  onChangeAddressClick?(): void;
  changeable?: boolean;
}

const AddressRow: FC<AddressRowProps> = ({
  address,
  onChangeAddressClick,
  changeable = true,
}) => {
  const city = address.city ? ` ${address.city}` : '';
  return (
    <div className={styles.sublineWrapper}>
      <div className={styles.checkedAddress} data-cs-mask>
        <Text appearance="t4">
          {`${address.zipCode}${city}, ${address.street} ${address.houseNumber}`}
        </Text>
      </div>
      {changeable && (
        <Spacer l={1}>
          <Link
            appearance="t5_2"
            e2e={`availability-re-check`}
            onClick={() => onChangeAddressClick?.()}
            url="#availability-check"
            className={styles.linkCentered}
            icon={<IconEdit color="blue" width={24} height={24} />}
          >
            Adresse ändern
          </Link>
        </Spacer>
      )}
    </div>
  );
};

export { AddressRow };
