import { FC, Fragment } from 'react';
import Image from 'next/image';
import {
  Grid,
  Spacer,
  Text,
  RichText,
  ImageProps,
  TextElements,
} from 'dss-ui-library';
import { ButtonLink, ButtonLinkProps } from '../../components';
import styles from './MixedContent.module.scss';
import cn from 'classnames';
import { getImageProps } from '@ncs-frontend-monorepo/utils';

export interface ModuleMixedContentElement {
  subline?: string;
  text: string;
  button?: ButtonLinkProps;
  image: ImageProps;
  imageposition: 'left' | 'right';
  imageSize: 'medium' | 'small';
  headlineElement?: TextElements;
}

export interface MixedContentProps {
  headline?: string;
  subline?: string;
  imagecard: ModuleMixedContentElement[];
  headlineElement?: TextElements;
}
export const MixedContent: FC<MixedContentProps> = ({
  headline,
  subline,
  imagecard = [],
  headlineElement = 'h2',
}) => (
  <Grid>
    {headline && (
      <Grid.Row>
        <Grid.Column marginBottom={subline ? 1 : 2}>
          <Text appearance="t1_2" color="blue" element={headlineElement}>
            {headline}
          </Text>
        </Grid.Column>
      </Grid.Row>
    )}
    {subline && (
      <Grid.Row>
        <Grid.Column marginBottom={1}>
          <Text appearance="t4" element="p">
            {subline}
          </Text>
        </Grid.Column>
      </Grid.Row>
    )}
    {imagecard.map((element, i) => (
      <Fragment key={`element-${i}-${element.text}`}>
        <Grid.Row>
          <Grid.Column
            className={cn(styles.imageColumn, {
              [styles.imageRight]: element.imageposition === 'right',
            })}
            s={element.imageSize === 'small' ? 6 : 12}
            m={element.imageSize === 'medium' ? 6 : 4}
            stretch
          >
            <Image
              {...getImageProps(element.image)}
              className={styles.image}
              sizes="100vw"
            />
          </Grid.Column>
          <Grid.Column
            s={element.imageSize === 'small' ? 6 : 12}
            m={element.imageSize === 'medium' ? 6 : 8}
          >
            {element.subline && (
              <Spacer b={2} block>
                <Text
                  appearance="t2_2"
                  color="blue"
                  element={element.headlineElement}
                >
                  {element.subline}
                </Text>
              </Spacer>
            )}

            <RichText text={element.text} appearance="t4" />
            {element.button && (
              <Spacer t={4} block>
                <ButtonLink {...element.button}>
                  {element.button.text}
                </ButtonLink>
              </Spacer>
            )}
          </Grid.Column>
        </Grid.Row>
        {i < imagecard.length - 1 && <div className={styles.separator} />}
      </Fragment>
    ))}
  </Grid>
);

export default MixedContent;
