import { Spacer, Text } from 'dss-ui-library';
import { AddressFields, AddressRow } from './/AddressRow';

interface ModalContentWrapperProps {
  title: string;
  children: React.ReactNode;
  address?: AddressFields;
  addressLabel?: string;
  resetAddress?: () => void;
}

export const ModalContentWrapper: React.FC<ModalContentWrapperProps> = ({
  title,
  children,
  addressLabel,
  address,
  resetAddress,
}) => {
  return (
    <>
      <Spacer b={2} r={4} block>
        <Text appearance="t2" color="blue">
          {title}
        </Text>
      </Spacer>
      {addressLabel && <Text appearance="t4_2">{addressLabel}</Text>}
      {address && (
        <AddressRow address={address} onChangeAddressClick={resetAddress} />
      )}
      {children}
    </>
  );
};
