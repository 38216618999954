const prefix = process.env.NEXT_PUBLIC_SITE_PREFIX || '/';

export const isInternalLink = (url: string): boolean => {
  if (!url) {
    return false;
  }

  if (/^(mailto|tel|https?):/.test(url)) {
    return false;
  }

  // TODO: Better use the contentcontext instead of env-variables
  if (url.startsWith(prefix)) {
    // gk
    if (url === `${prefix}/login`) {
      return false;
    }
    return true;
  }

  return false;
};

// Add a slash to the path of the URL
export const formatUrl = (url: string, contactTrack?: string): string => {
  const queryStart = url.indexOf('?');
  const fragmentStart = url.indexOf('#');

  let first = url;
  let last = '';

  // Split url at the '?' or '#' mark
  if (queryStart !== -1) {
    first = url.substring(0, queryStart);
    last = url.substring(queryStart);
  } else if (fragmentStart !== -1) {
    first = url.substring(0, fragmentStart);
    last = url.substring(fragmentStart);
  }

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('secret')) {
      if (last.startsWith('?')) {
        last = '&' + last.substring(1);
      }
      last =
        `?secret=${encodeURIComponent(String(searchParams.get('secret')))}` +
        last;
    }
  }

  if (contactTrack) {
    if (last.startsWith('?')) {
      last = '&' + last.substring(1);
    }
    last = `?contactTrack=${encodeURIComponent(contactTrack)}` + last;
  }

  return first + last;
};
