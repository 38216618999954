import {
  Availability,
  isBandwidthAvailable,
  Portfolio,
} from '@ncs-frontend-monorepo/availability';
import { Product } from '../../../';
import { PlanState } from './usePlanState';
export function getNotConnectedPlanState({
  plan,
  ftthPresalesInformation,
  fallbackPlanState,
  promotions,
  portfolio,
}: {
  plan: Product;
  ftthPresalesInformation: Availability['ftthPresalesInformation'];
  fallbackPlanState: PlanState;
  promotions: Availability['promotions']['order'];
  portfolio?: Portfolio;
}) {
  const { internet } = plan;
  const internetDownload = internet?.download || 0;

  if (ftthPresalesInformation === null) {
    return fallbackPlanState;
  } else {
    // Prefer Presale
    const availablePromotion = promotions
      ?.sort((a, b) => (a.maxDownload > b.maxDownload ? -1 : 1))
      ?.find((promotion) => promotion.maxDownload >= internetDownload);

    // return available portfolio
    return {
      isDisabled: !isBandwidthAvailable({
        bandwidth: internetDownload,
        ftthPresalesInformation,
      }),
      isPresale: false,
      isPlanStateChecked: true,
      portfolioMismatch:
        !isBandwidthAvailable({
          bandwidth: internetDownload,
          ftthPresalesInformation,
        }) &&
        availablePromotion &&
        availablePromotion.portfolio !== portfolio,
      availablePortfolio: availablePromotion?.portfolio,
      hasNetTVInternet: false,
    };
  }
}
