import {
  AccessType,
  FTTHPresalesInformation,
} from '@ncs-frontend-monorepo/availability';
import { PropertyOwnerRole } from '../../constants';

export const shouldRemoveGEEStep = (
  ftthPresalesInformation: FTTHPresalesInformation,
  propertyOwnerRole?: PropertyOwnerRole | null,
) => {
  // special handling for wholebuy
  if (
    ftthPresalesInformation?.mergedAccessType &&
    [AccessType.FTTH_BSA_L2, AccessType.FTTH_BSA_L2_DG].includes(
      ftthPresalesInformation?.mergedAccessType,
    )
  ) {
    const { wholeBuy } = ftthPresalesInformation;

    switch (true) {
      case propertyOwnerRole === PropertyOwnerRole.owner:
        return true;
      case wholeBuy?.eigentuemerdatenErforderlich:
        return false;
      default:
        return true;
    }
  }

  // remove pod step if user is not owner and has granted gee
  return (
    propertyOwnerRole !== PropertyOwnerRole.owner &&
    ftthPresalesInformation?.hasGrantedGee
  );
};
