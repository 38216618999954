import { FC, ReactNode } from 'react';
import { TelljaButtonProps, TelljaWidgets } from './Tellja';
import { ButtonProps } from 'dss-ui-library';
import dynamic from 'next/dynamic';
import { HEYFLOW_WIDGET, HeyFlowWidgetParams } from './HeyFlow';

const TelljaButton = dynamic(() => import('./Tellja/TelljaButton'), {
  ssr: false,
});
const TelljaWrapper = dynamic(() => import('./Tellja/TelljaWrapper'), {
  ssr: false,
});
const HeyFlow = dynamic(() => import('./HeyFlow'), { ssr: false });

type PartnerWidgets = TelljaWidgets | typeof HEYFLOW_WIDGET;

export type WidgetButtonProps = ButtonProps & { text: string };

export enum PartnerWidgetType {
  Wrapper = 'wrapper',
  Button = 'button',
}

export interface PartnerWidgetProps {
  children?: ReactNode;
  widget: PartnerWidgets;
  widgetParam?: string;
  type?: PartnerWidgetType;
  button?: TelljaButtonProps['button'] | HeyFlowWidgetParams['button'];
  backgroundColor?: HeyFlowWidgetParams['backgroundColor'];
  e2e?: string;
}

export const PartnerWidget: FC<PartnerWidgetProps> = ({
  widget,
  widgetParam = '',
  type,
  button,
  backgroundColor,
  e2e,
  children,
}) => {
  switch (true) {
    case Object.values(TelljaWidgets).includes(widget as TelljaWidgets):
      return type === PartnerWidgetType.Wrapper ? (
        <TelljaWrapper
          widget={widget as TelljaWidgets}
          widgetParam={widgetParam}
          e2e={e2e}
        >
          {children}
        </TelljaWrapper>
      ) : (
        <TelljaButton
          widget={widget as TelljaWidgets}
          widgetParam={widgetParam}
          button={button as TelljaButtonProps['button']}
        />
      );
    case widget === HEYFLOW_WIDGET:
      return (
        <HeyFlow
          widgetParam={widgetParam}
          button={button as HeyFlowWidgetParams['button']}
          backgroundColor={backgroundColor}
        />
      );
    default:
      return null;
  }
};
