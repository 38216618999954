import { LandlinePromotion, Portfolio } from '../../interfaces';
import { isFiberPlan } from './checkPlan';

export function getPortfolio(
  availablePromotions?: LandlinePromotion[] | null,
): Portfolio {
  if (!availablePromotions) return 'FIBER';
  if (availablePromotions.length > 0) {
    return isFiberPlan(availablePromotions[0].technology) ? 'FIBER' : 'CLASSIC';
  }
  return 'FIBER';
}
