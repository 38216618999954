export enum Technology {
  ADSL2_IP = 'ADSL2_IP',
  VDSL2_FTTB = 'VDSL2_FTTB',
  FTTH = 'FTTH',
  GFAST = 'GFAST',
  DSL_ISDN = 'DSL_ISDN',
  DSL_ANALOG = 'DSL_ANALOG',
  CABLE = 'CABLE',
  VDSL_BSA_L2 = 'VDSL_BSA_L2',
  VDSL_BSA_L3 = 'VDSL_BSA_L3',
  FTTC = 'FTTC',
  FTTH_BSA_L2 = 'FTTH_BSA_L2',
  FTTH_BSA_L2_DG = 'FTTH_BSA_L2_DG',
  ADSL = 'ADSL',
}
