import { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Grid, Text, IconChevron, BreadcrumbItem } from 'dss-ui-library';
import styles from './Breadcrumb.module.scss';
import { BaseLink } from '../../components/Link';
import { useHorizontalScroll } from './useHorizontalScroll';

export type BreadcrumbItem = {
  urlPath: string | null;
  title: string;
  pageId: number;
};

interface BreadcrumbProps {
  breadcrumbs?: BreadcrumbItem[];
  hasBorderTop?: boolean;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
  breadcrumbs = [],
  hasBorderTop = false,
}) => {
  if (breadcrumbs.length === 0) return null;

  const scrollBarRef = useRef<HTMLUListElement>(null);
  const [gradient, setGradient] = useState({
    showLeft: false,
    showRight: false,
  });

  useHorizontalScroll({ ref: scrollBarRef, disableAtBreakpoint: 768 });

  useEffect(() => {
    // scroll to the end to always display current breadcrumb on mobile
    if (scrollBarRef.current && breadcrumbs) {
      scrollBarRef.current.scrollLeft = scrollBarRef.current.scrollWidth;
    }
  }, [breadcrumbs]);

  // show slight gradient when content is overflowing
  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const offset = 20;
    const currentScrollPos = e.currentTarget.scrollLeft;
    const start = 0;
    const end = e.currentTarget.scrollWidth - e.currentTarget.clientWidth;

    setGradient({
      showLeft: currentScrollPos > start + offset,
      showRight: currentScrollPos < end - offset,
    });
  };

  const e2eTag = (title: string) => `breadcrumb-${title}`.replace(/ /g, '_');

  return (
    <nav
      className={cx(styles.breadcrumbWrapper, {
        [styles.hasBorderTop]: hasBorderTop,
      })}
    >
      <Grid>
        <div
          className={cx(
            styles.breadcrumbGradientLeft,
            styles.breadcrumbGradient,
            {
              [styles.hideGradient]: !gradient.showLeft,
            },
          )}
        />
        <div
          className={cx(
            styles.breadcrumbGradientRight,
            styles.breadcrumbGradient,
            {
              [styles.hideGradient]: !gradient.showRight,
            },
          )}
        />
        <ul
          onScroll={handleScroll}
          className={cx(styles.items, styles.noScrollbar)}
          ref={scrollBarRef}
        >
          {breadcrumbs.map((item) => (
            <li
              key={item.title}
              className={styles.item}
              data-e2e={`navigation-${e2eTag(item.title)}`}
            >
              {item.urlPath ? (
                <BaseLink
                  color="darkGrey"
                  underlined
                  className={styles.test}
                  appearance="t7"
                  e2e={e2eTag(item.title)}
                  url={item.urlPath}
                >
                  {item.title}
                </BaseLink>
              ) : (
                <Text appearance="t7">{item.title}</Text>
              )}
              <IconChevron
                className={styles.chevron}
                rotation="right"
                small
                width={12}
                height={12}
              />
            </li>
          ))}
        </ul>
      </Grid>
    </nav>
  );
};
