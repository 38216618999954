import { Grid, RichText, Spacer, TabSliderTab } from 'dss-ui-library';
import { useMemo } from 'react';
import { ButtonLink } from '../../components/ButtonLink';
import Image from 'next/image';
import styles from './TabSlider.module.scss';
import cn from 'classnames';
import {
  getImageOrientation,
  ImageOrientation,
} from '../../utils/getImageOrientation';
import { LinkProps } from '../../components';

export interface SubContentElement {
  uid: number;
  type: 'text' | 'textpic';
  header: string;
  headerLink?: string;
  bodytext: string;
  button?: {
    text?: string;
    link?: LinkProps;
  };
  buttonLink?: string;
  imageOrient: ImageOrientation;
  images?: {
    src: string;
    width: number;
    height: number;
    title: string;
    description: string;
    alt: string;
  }[];
  imageCols: number;
}

interface UseSubContentElementsParams {
  subContentElements: SubContentElement[];
}

export function useSubContentElements({
  subContentElements,
}: UseSubContentElementsParams) {
  const tabs: TabSliderTab[] = useMemo(() => {
    return subContentElements.map((contentElement) => {
      const imageOrient = getImageOrientation(contentElement.imageOrient);
      return {
        title: contentElement.header,
        content: (
          <div key={contentElement.uid}>
            <Spacer t={1} />
            <Grid.Row>
              <Grid.Column
                className={cn({
                  [styles.alignRight]: imageOrient === 'imageLeftTextBeside',
                  [styles.alignLeft]: imageOrient === 'imageRightTextBeside',
                })}
                s={12 - contentElement.imageCols}
              >
                <RichText text={contentElement.bodytext} appearance="t4" />
                {contentElement.button && contentElement.button.link?.url && (
                  <Spacer y={1}>
                    <ButtonLink link={contentElement.button.link}>
                      {contentElement.button.text}
                    </ButtonLink>
                  </Spacer>
                )}
              </Grid.Column>
              {contentElement.images?.[0] && (
                <Grid.Column s={contentElement.imageCols}>
                  <Spacer t={1} block />
                  <Image
                    className={styles.image}
                    alt={contentElement.images[0].alt ?? ''}
                    src={contentElement.images[0].src}
                    width={800}
                    height={800}
                    aria-description={contentElement.images[0].description}
                    title={contentElement.images[0].title}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </div>
        ),
      };
    });
  }, [subContentElements]);

  return tabs;
}
