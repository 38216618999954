import { useCallback } from 'react';

type UseScrollToOptions = {
  dataName?: string;
  block?: ScrollLogicalPosition;
  timeout?: number;
};

/**
 * Scrolls to given data-name attribute or ref
 * @param target React.RefObject<HTMLElement> | string
 * @returns
 */
export function useScrollTo(
  target?: React.RefObject<HTMLElement> | string,
  options?: UseScrollToOptions,
) {
  const dataName = options?.dataName ?? 'e2e';
  const block = options?.block ?? 'start';
  const querySelector = typeof target === 'string' ? target : null;
  let timeoutScroll: NodeJS.Timeout; // temporary workaround (chrome) for fixing hydration errors

  const scrollTo = useCallback(() => {
    const scrollIntoViewOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block,
    };

    timeoutScroll = setTimeout(() => {
      // handle query selector
      if (querySelector) {
        const element = document.querySelector(
          `[data-${dataName}="${querySelector}"]`,
        );
        element?.scrollIntoView(scrollIntoViewOptions);
      }

      // handle ref
      if (typeof target !== 'string' && target?.current) {
        target.current.scrollIntoView(scrollIntoViewOptions);
      }
    }, options?.timeout || 0);

    return () => {
      clearTimeout(timeoutScroll);
    };
  }, [dataName, block, querySelector]);

  return scrollTo;
}
