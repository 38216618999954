import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getEnv } from '@ncs-frontend-monorepo/utils';
import { getUserManagerSettings } from './userManagerSettings';

interface AuthContext {
  signIn(): Promise<void>;
  signOut(): Promise<void>;
  getUser(): Promise<User>;
  getLocalUser(): User;
  triggerUserLoadedEvent(): Promise<User>;
  isAuthenticated: boolean;
  isInitialized: boolean;
}

const Context = createContext<AuthContext | null>(null);
Context.displayName = 'AuthContext';

export const useAuthContext: () => AuthContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useAuthContext must be used within a <AuthProvider> tag');
  }
  return contextState;
};

type AuthProviderProps = {
  value?: Partial<{
    isAuthenticated: boolean;
    getLocalUser: () => User | null;
  }>;
  children?: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ value, children }) => {
  const [userManager, setUserManager] = useState<UserManager>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const _userManagerSettings = getUserManagerSettings(
      getEnv().PK.NEXT_PUBLIC_AUTH_DOMAIN,
    );

    const _userManager = new UserManager({
      ..._userManagerSettings,
      redirect_uri: `${window.location.origin}/privatkunden/ftth/bestellung`,
      silent_redirect_uri: `${window.location.origin}/privatkunden/ftth/bestellung`,
      post_logout_redirect_uri: `${window.location.origin}/privatkunden/ftth/bestellung`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    _userManager.events.addUserLoaded(() => {
      setIsAuthenticated(true);
    });

    _userManager.events.addAccessTokenExpired(async () => {
      await _userManager.clearStaleState();
      setIsAuthenticated(false);
    });

    setUserManager(_userManager);
  }, []);

  useEffect(() => {
    if (userManager) {
      const now = Math.round(new Date().getTime() / 1000);
      setIsAuthenticated(getLocalUser()?.expires_at >= now);
    }
  }, [userManager]);

  const signIn = () => {
    return userManager?.signinRedirect();
  };

  const triggerUserLoadedEvent = () => {
    return userManager?.signinRedirectCallback();
  };

  const signOut = async () => {
    setIsAuthenticated(false);
    const idToken = getLocalUser().id_token;

    await userManager.clearStaleState();
    await userManager.removeUser();
    await userManager.signoutRedirect({
      id_token_hint: idToken,
    });
  };

  const getUser = async () => {
    const user = await userManager.getUser();
    if (!user) {
      return userManager.signinRedirectCallback().catch(() => null);
    }
    return user;
  };

  const getLocalUser = (): User | null => {
    const settings = userManager.settings;

    return (
      JSON.parse(
        localStorage.getItem(
          `oidc.user:${settings?.authority}:${settings?.client_id}`,
        ),
      ) || null
    );
  };

  return (
    <Context.Provider
      value={{
        signIn,
        signOut,
        getUser,
        getLocalUser,
        triggerUserLoadedEvent,
        isAuthenticated,
        isInitialized: !!userManager,
        ...value,
      }}
    >
      {children}
    </Context.Provider>
  );
};
