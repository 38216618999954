import { FC, Fragment } from 'react';
import {
  Grid,
  Text,
  Spacer,
  RichText,
  Accordion as DSSAccordion,
  TextElements,
} from 'dss-ui-library';

export interface AccordionProps {
  headline: string;
  list: AccordionList[];
  headlineElement?: TextElements;
}

export interface AccordionList {
  text: string;
  headline: string;
  headlineElement?: TextElements;
}

export const Accordion: FC<AccordionProps> = ({
  headline,
  list,
  headlineElement,
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Spacer b={2}>
            <Text appearance="t2" color="blue" element={headlineElement}>
              {headline}
            </Text>
          </Spacer>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {list.map((item, index) => (
            <Fragment key={`accordion-${index}`}>
              <DSSAccordion
                title={item.headline}
                headlineElement={item.headlineElement}
              >
                <RichText text={item.text} />
                <Spacer b={2} block />
              </DSSAccordion>
              {index < list.length - 1 && <Spacer b={3} block />}
            </Fragment>
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Accordion;
