import { OrderCheckoutVariant } from '../../constants';

export const hasExistingCustomerSteps = (
  checkoutVariant?: OrderCheckoutVariant,
) =>
  (checkoutVariant &&
    [
      OrderCheckoutVariant.CUSTOMER_EXISTING,
      OrderCheckoutVariant.AGENT_CUSTOMER_EXISTING,
    ].includes(checkoutVariant)) ||
  false;
