import { FC } from 'react';
import {
  Grid,
  Spacer,
  Text as DSSText,
  Background,
  RichText,
  TextElements,
} from 'dss-ui-library';

export interface TextProps {
  headline?: string;
  text?: string;
  backgroundColor?: 'white' | 'blue' | 'grey' | 'superlight-grey';
  headlineElement?: TextElements;
}

const Text: FC<TextProps> = ({
  headline,
  text,
  backgroundColor = 'white',
  headlineElement = 'h2',
}) => {
  const headlineColor = backgroundColor === 'blue' ? 'white' : 'blue';
  return (
    <Background
      color={backgroundColor === 'superlight-grey' ? 'grey' : backgroundColor}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column m={10}>
            <DSSText
              element={headlineElement}
              color={headlineColor}
              appearance="t1_2"
            >
              {headline}
            </DSSText>
            {text && (
              <Spacer t={3} block>
                <RichText text={text} onBackground={backgroundColor} />
              </Spacer>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Background>
  );
};

export default Text;
