import {
  DeviceCategory,
  DeviceOwnership,
  InstallationServiceBasket,
  PriceCondition,
  Product,
  ProductId,
  SelectedDevice,
  TemplateGroup,
} from '../';
import { FetchAddressReponse } from '@ncs-frontend-monorepo/availability';

export interface BasketProduct
  extends Omit<
    Product,
    | 'priceInfo'
    | 'name'
    | 'shortname'
    | 'installationServices'
    | 'group'
    | 'isWithoutRuntimeAvailable'
    | 'pricing'
    | 'runtime'
    | 'devices'
    | 'ontDevices'
    | 'displayProposals'
    | 'groups'
  > {
  group: TemplateGroup;
  device?: SelectedDevice;
  ontDevice?: SelectedDevice;
  tvDevice?: SelectedDevice;
  installationAddress: FetchAddressReponse;
  installationService?: string;
  plannedAvailabilityDate?: string;
  plannedAvailabilityDateDescription?: string;
  pubId?: string;
  isPreSale?: boolean; // GK only
  basketId?: string; // NotConnected only
}

export enum BasketItemSubType {
  NET_TV_INTERNET = 'NET_TV_INTERNET',
  NET_TV_APP = 'NET_TV_APP',
  NET_TV_CABLE = 'NET_TV_CABLE',
}

export enum BasketItemType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  SUB_TOTAL = 'SUB_TOTAL',
  INTERNET = 'INTERNET',
  TELEPHONY = 'TELEPHONY',
  COM_CENTER_BASIC = 'COM_CENTER_BASIC',
  NET_SERVICE = 'NET_SERVICE',
  INSTALLATION_SERVICE = 'INSTALLATION_SERVICE',
  IAD_DEVICE = 'IAD_DEVICE',
  ONT_DEVICE = 'ONT_DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_SHIPPING = 'DEVICE_SHIPPING',
  TV = 'TV',
  TV_DEVICE = 'TV_DEVICE',
  TV_DEVICE_BYOD = 'TV_DEVICE_BYOD',
  TV_SHIPPING = 'TV_SHIPPING',
}

export enum BasketPositionType {
  TOTAL = 'TOTAL',
  HOUSE_CONNECTION = 'HOUSE_CONNECTION',
  PRODUCT = 'PRODUCT',
  OPTIONS = 'OPTIONS',
  DEVICE = 'DEVICE',
  PROVISIONING = 'PROVISIONING',
  DEVICE_MULTIPLE = 'DEVICE_MULTIPLE',
}

interface BasketItemPricing {
  monthly: PriceCondition | null;
  onetime: PriceCondition | null;
}

export interface BasketPositionItem {
  type: BasketItemType;
  subtype?: BasketItemSubType;
  pricing: BasketItemPricing;
  name?: string;
  download?: number;
  service?: InstallationServiceBasket;
  id?: string;
  category?: DeviceCategory;
  ownership?: DeviceOwnership;
}

export type BasketPosition = {
  type: BasketPositionType;
  name?: string;
  templateId?: ProductId;
  templateGroup?: TemplateGroup;
  items: BasketPositionItem[];
};

export type ImmutableBasket = {
  basketId: string;
  basket: BasketPosition[];
};

export interface ShoppingCartData {
  basketTemplateGroup: TemplateGroup;
  basket: BasketPosition[];
}
