import { hasCableTV, Promotions } from '@ncs-frontend-monorepo/availability';
import { CABLE_TV_COMBI_GROUPS, TemplateGroup } from '../../constants';

export function isDisabledCableTVGroup({
  templateGroup,
  availableTv,
}: {
  templateGroup: TemplateGroup;
  availableTv: Promotions['tvPromotions'];
}) {
  if (
    templateGroup === TemplateGroup.TV_CABLE ||
    CABLE_TV_COMBI_GROUPS.includes(templateGroup)
  ) {
    return !hasCableTV(availableTv);
  }
  return false;
}
