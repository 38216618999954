import { useCallback } from 'react';
import { useAvailabilityStore } from './availabilityStore';
import { AvailabilityLoggingInfo, checkAvailability } from '../requests';
import { AddressFields } from '../components';
import { initialAddress, initialAvailability } from './initialValues';

type HandleAvailabilityCheckParams = {
  address?: AddressFields | null;
  loggingInfo?: AvailabilityLoggingInfo;
  isNotConnectedCheck?: boolean;
};

/**
 * Provides the current state of the availability check
 */
export function useAvailability() {
  const store = useAvailabilityStore();

  const handleAvailabilityCheck = useCallback(
    async ({
      address,
      loggingInfo,
      isNotConnectedCheck = false,
    }: HandleAvailabilityCheckParams) => {
      if (!address) return;

      // set store address to given param value - will be overwritten by response data
      store.setAddress({ ...initialAddress, ...address });

      // fetch new availability based on address
      const newAvailability = await checkAvailability({
        ...address,
        loggingInfo,
      });

      const {
        promotions: { availablePromotions, presalePromotion },
        isFTTHPlanned,
      } = newAvailability;

      // update store based on availability check response data
      if (availablePromotions || presalePromotion) {
        store.setAvailability(newAvailability);
        store.setHasAvailability(true);
      } else if (isFTTHPlanned || isNotConnectedCheck) {
        store.setAvailability({
          ...initialAvailability,
          address: newAvailability.address,
          objectInformation: newAvailability.objectInformation,
          isFTTHPlanned,
          isChecked: true,
        });
        store.setHasAvailability(true);
      } else {
        store.setIsChecked(true);
        store.setHasAvailability(false);
      }
    },
    [],
  );

  return {
    address: store.availability.address,
    availability: store.availability,
    hasAvailability: store.hasAvailability,
    resetAvailabilityCheck: store.resetAvailability,
    handleAvailabilityCheck,
  };
}
