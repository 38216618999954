import { Promotions } from '@ncs-frontend-monorepo/availability';
import { CABLE_TV_GROUPS, TemplateGroup } from '../../constants';
import { isDisabledCableTVGroup } from './isDisabledCableTVGroup';

export type CheckHasMismatchingTVParams = {
  templateGroup: TemplateGroup;
  isInternetAvailable: boolean;
  isPortfolioMismatch: boolean;
  availableTv: Promotions['tvPromotions'];
};

export function checkHasMismatchingTV({
  templateGroup,
  isInternetAvailable,
  isPortfolioMismatch,
  availableTv,
}: CheckHasMismatchingTVParams): boolean {
  if (isInternetAvailable && CABLE_TV_GROUPS.includes(templateGroup)) {
    if (isPortfolioMismatch) {
      // Mismatch if template group is not tv-internet
      return templateGroup !== TemplateGroup.TV_INTERNET;
    }

    // If no portfolio mismatch, check if the cable TV group is disabled based on available tv promotions
    return isDisabledCableTVGroup({ templateGroup, availableTv });
  }

  // If it's not a TV group or internet isn't available, return the portfolio mismatch status
  return isPortfolioMismatch;
}
