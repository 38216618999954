import { createRef, FC, useState } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames/bind';
import styles from './Card.module.scss';
import {
  Grid,
  ImageProps,
  CardTeasers,
  CardTeaser,
  Spacer,
  Footnote,
  FootnoteProps,
  TextElements,
  ListIcon,
} from 'dss-ui-library';
import { ButtonLinkProps, LinkProps } from '../../components';
import { useRouter } from 'next/router';
import { isInternalLink } from '../../components/Link/helpers';
import { SyntheticEvent } from 'react';
import { PartnerWidgetProps } from '../../components';
import { CardTeaserWrapper } from './CardTeaserWrapper';
import Image from 'next/image';
import { getImageProps } from '@ncs-frontend-monorepo/utils';

const cx = classNames.bind(styles);

declare global {
  interface Array<T> {
    chunk(n: number): T[][];
  }
}

interface CardItem {
  headline: string;
  text?: string;
  button?: ButtonLinkProps;
  image?: ImageProps;
  icon?: number;
  pretext?: string;
  price: number;
  interval?: string;
  footnote?: FootnoteProps;
  paginationCount?: number;
  partnerWidget?: PartnerWidgetProps['widget'];
  partnerWidgetParam?: PartnerWidgetProps['widgetParam'];
}
export interface CardProps {
  headline?: string;
  backgroundColor?: string;
  columnCount: number;
  showPagination?: boolean;
  paginationCount?: number;
  paginationMaxPages?: number;
  paginationAlign?: 'Center' | 'Left' | 'Right';
  list?: Array<CardItem>;
  headlineElement?: TextElements;
}

const Pagination = dynamic<any>(
  () => import('dss-ui-library').then(({ Pagination }) => Pagination),
  { ssr: false },
);

const Card: FC<CardProps> = ({
  list = [],
  paginationAlign = 'Center',
  ...props
}) => {
  const router = useRouter();
  const cardsRef = createRef<HTMLDivElement>();
  let paginatedList: Array<CardProps['list']> = [];

  const [paginationSettings, setPaginationSettings] = useState({
    currentPage: 1,
    startPage: 1,
    endPage: 1,
  });

  // Split array into array for each page in pagination
  if (props.showPagination) {
    if (!Array.prototype.chunk) {
      Array.prototype.chunk = function (n: number) {
        if (!this.length) {
          return [];
        }
        return [this.slice(0, n)].concat(this.slice(n).chunk(n));
      };
    }

    if (props.paginationCount) {
      paginatedList = list.chunk(props.paginationCount) || [];
    }
  }

  let cardlist: CardItem[] = list;
  if (props.showPagination) {
    // use splitted array for paginated list or default list if no pagination
    cardlist =
      (paginatedList && paginatedList[paginationSettings.currentPage - 1]) ||
      [];
  }

  let visibleSteps = 1;

  if (paginatedList && props.paginationMaxPages) {
    visibleSteps =
      props.paginationMaxPages >= paginatedList.length
        ? paginatedList.length
        : props.paginationMaxPages;
  }

  // scroll to top of list after pagination change
  const executeScroll = () => {
    if (cardsRef.current) {
      const yOffset = -150;
      const element = cardsRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleClick = (e: MouseEvent | SyntheticEvent, link?: LinkProps) => {
    if (link?.url) {
      if (isInternalLink(link.url)) {
        e.preventDefault();
        router.push(link.url);
      } else {
        window.open(
          link.url,
          link.target,
          link.target === '_blank' ? 'noopener' : undefined,
        );
      }
    }
  };

  const handlePaginationChange = (
    currentPage: number,
    startPage: number,
    endPage: number,
  ) => {
    if (currentPage !== paginationSettings.currentPage) {
      setPaginationSettings({ currentPage, startPage, endPage });
      executeScroll();
    } else {
      setPaginationSettings({ currentPage, startPage, endPage });
    }
  };

  return (
    <div
      ref={cardsRef}
      className={cx('backgroundColor' + props.backgroundColor + '')}
    >
      <CardTeasers
        background={props.backgroundColor}
        headline={props.headline}
        columnCount={props.columnCount}
        withBottomMargin={false}
        headlineElement={props.headlineElement}
      >
        {cardlist.map((card, index: number) => (
          <CardTeaserWrapper
            key={card.headline + card.text}
            handleClick={handleClick}
            partnerWidgetParam={card.partnerWidgetParam}
            partnerWidget={card.partnerWidget}
          >
            <CardTeaser
              image={card.image}
              background={props.backgroundColor === 'grey' ? 'white' : 'grey'}
              customImageNode={(imageProps) => (
                <Image
                  {...getImageProps(imageProps)}
                  className={styles.image}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
              )}
              e2e={`card-teaser-colocation-${index}`}
              headline={card.headline}
              subline={card.text || ''}
              {...(card.icon &&
                card.icon > 0 && {
                  icon: (
                    <ListIcon
                      iconType={card.icon}
                      iconColor="blue"
                      size={100}
                    />
                  ),
                })}
              {...(card.price && {
                pretext: card?.pretext || '',
                price: card.price,
                interval: card?.interval || '',
                footnote: card.footnote && (
                  <Footnote number={card.footnote.number} />
                ),
              })}
              {...(card.button && {
                cta: {
                  mode: 'text',
                  url: card.button.link.url,
                  target: card.button.link.target,
                  text: card.button.text || '',
                  onClick: (e) => handleClick(e, card.button?.link),
                },
              })}
            />
          </CardTeaserWrapper>
        ))}
      </CardTeasers>

      {props.showPagination && (
        <Grid>
          <Grid.Row>
            <Grid.Column
              {...{
                hCenter: paginationAlign === 'Center',
                hStart: paginationAlign === 'Left',
                hEnd: paginationAlign === 'Right',
              }}
            >
              <Spacer t={3} block />
              <Pagination
                start={1}
                end={paginatedList ? paginatedList.length : 10}
                visibleSteps={visibleSteps}
                onChange={handlePaginationChange}
                e2e="card-teaser-pagination"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default Card;
