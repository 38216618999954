import { FC } from 'react';
import styles from './Counter.module.scss';

export interface DigitsProps {
  digit: number;
  digitLabel: string;
}

const Digits: FC<DigitsProps> = ({ digit, digitLabel }) => {
  return (
    <div className={styles.timeWrapper}>
      <div className={styles.digitWrapper}>
        <div className={styles.digit}>{digit}</div>
      </div>
      <span className={styles.digitLabel}>{digitLabel}</span>
    </div>
  );
};

export default Digits;
