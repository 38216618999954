import { GeeStatus } from '../../constants';

/**
 * Returns true if the given geeStatus is granted and the geeValidFrom is at most 3 years in the past.
 * @param {GeeStatus} geeStatus - Status to be checked.
 * @param {string | null} geeValidFrom - Date from when the status is valid, in 'dd.mm.yyyy' format.
 * @returns {boolean} True if the status is granted and the date is within the past three years.
 */
export const hasGrantedGee = (
  geeStatus: GeeStatus,
  geeValidFrom: string | null,
): boolean => {
  if (geeStatus !== GeeStatus.ERTEILT || !geeValidFrom) return false;

  const dateParts = geeValidFrom.split('.').map(Number);
  if (dateParts.length !== 3 || dateParts.some(isNaN)) return false;

  const [day, month, year] = dateParts;
  const geeValidFromDate = new Date(year, month - 1, day);

  if (isNaN(geeValidFromDate.getTime())) return false;

  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

  return geeValidFromDate >= threeYearsAgo;
};
