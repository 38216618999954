import { Technology } from '@ncs-frontend-monorepo/availability';
import {
  correlationIdHeader,
  fetcher,
  getCorrelationId,
  getEnv,
  isNC,
  Mandant,
} from '@ncs-frontend-monorepo/utils';
import { ProductId } from '../constants';
import { Product } from '../interfaces';

interface GetProductParams {
  templateId: ProductId;
  promotionId?: string;
  withoutRuntime?: boolean;
  maxDownload?: number;
  technology?: Technology;
  zipCode?: string;
  mandant?: Mandant;
  pubId?: string;
}

export async function getProducts({
  templateId,
  promotionId,
  withoutRuntime,
  maxDownload,
  technology,
  zipCode,
  mandant,
  pubId,
}: GetProductParams): Promise<Product | null> {
  try {
    const searchParams = new URLSearchParams({
      templateId,
      mandant: mandant ? mandant : isNC() ? 'NC' : 'NA',
      ...(promotionId && { promotionId }),
      ...(withoutRuntime !== undefined && {
        withoutRuntime: withoutRuntime ? 'true' : 'false',
      }),
      ...(maxDownload && { maxDownload: String(maxDownload) }),
      ...(technology && { technology }),
      ...(zipCode && { zipCode }),
      ...(pubId && { pubId }),
    });

    return await fetcher(
      `${getEnv().ORDER_SERVICE_URL}/products?${searchParams}`,
      {
        headers: {
          [correlationIdHeader]: getCorrelationId(),
        },
      },
    );
  } catch {
    return null;
  }
}
