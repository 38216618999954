class CustomError {
  message: string;
  status: number;

  constructor(statusCode = 404, message = '') {
    this.status = statusCode;
    this.message = message;
  }
}

export default CustomError;
