import {
  Fallback,
  NetTvType,
  Technology,
} from '@ncs-frontend-monorepo/availability';
import { Device, OntDevice } from './devices';
import { ProductGroups, ProductId, TemplateGroup } from '../constants';
import { BusinessUnit } from '@ncs-frontend-monorepo/utils';

export interface Product {
  id: ProductId;
  promotionId: string;
  pubId?: string;
  group: TemplateGroup;
  groups: ProductGroups[];
  name: string;
  businessUnit?: BusinessUnit;
  runtime: number;
  technology: Technology;
  telephony: Telephony | null;
  fallback: Fallback;
  isWithoutRuntime: boolean;
  isWithoutRuntimeAvailable: boolean;
  internet: Internet | null;
  tv: NetTv | null;
  installationServices: InstallationService[];
  pricing: {
    monthly: PriceCondition;
    onetime: PriceCondition;
    totalDiscount: PriceConditionDiscount;
  };
  items?: Item[];
  devices: Device[];
  ontDevices?: Device[];
  displayProposals: DisplayProposal[];
  houseConnection?: HouseConnection | null;
}

export interface DisplayProposal {
  id: string;
  displayName: string;
  displayText: string;
  discount: {
    value: number;
    duration: number;
    total: number;
    displayProposalId: string;
    proposalName: string;
  };
}

export interface HouseConnection {
  pricing: {
    onetime: PriceCondition;
  };
}

export interface InstallationService {
  id: string;
  priceInfo: PriceInfo;
}

export interface Internet {
  name?: string;
  download: number;
  upload: number;
}

export interface Item {
  siebelProductRowId: string;
  type: 'IAD_SHIPPING' | 'EXCHANGE_FEE' | 'NET_SERVICE';
  ecoName?: string;
  name: string;
  pricing: {
    monthly?: PriceCondition;
    onetime?: PriceCondition;
  };
}

export interface NetTv {
  type: NetTvType;
  devices: Device[];
}

export interface PriceConditionDiscount {
  value: number;
  duration: number;
  total: number;
  displayProposalId: string | null;
  proposalName: string | null;
}

export interface PriceCondition {
  price: number | null;
  discountedPrices: PriceConditionDiscounted[];
  discounts: PriceConditionDiscount[];
}

export interface PriceInfo {
  price: number;
  specialOfferPrice: number | null;
  specialOfferDuration: number | null;
}

export interface PriceConditionDiscounted {
  price: number;
  start: number;
  end: number;
  duration: number;
}

export enum Telephony {
  NATIONAL_FLAT = 'NATIONAL_FLAT',
  INTERNATIONAL_FLAT_1 = 'INTERNATIONAL_FLAT_1',
}

export interface AvailableProductOptions {
  devices: Device[];
  ontDevices: OntDevice[];
  installationServices: InstallationService[];
}
