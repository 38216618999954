import { CheckoutStepId } from '../constants';

export * from './devices';
export * from './products';
export * from './basket';
export * from './template';

export interface CheckoutSteps {
  id: CheckoutStepId;
  title: string;
  headline: string;
  buttonText: string;
  error?: string;
}
