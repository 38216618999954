import { useContentContext } from '../../ContentContext';
import { useEffect, useState } from 'react';
import { TelljaWidgets } from './index';
import { generateUID } from 'dss-ui-library';

export const useTelljaScript = ({
  widget,
  widgetParam,
}: {
  widget: TelljaWidgets;
  widgetParam?: string;
}) => {
  const { site, partnerWidgets } = useContentContext();
  const [placementId, setPlacementId] = useState<string>();
  const [containerId, setContainerId] = useState<string>();

  useEffect(() => {
    if (!containerId) {
      return;
    }
    const widgetId =
      widget === TelljaWidgets.BRAND
        ? partnerWidgets?.tellja?.[site.toLowerCase() as TelljaWidgets]
        : partnerWidgets?.tellja?.[widget];
    const widgetName =
      widget === TelljaWidgets.BRAND
        ? (site.toLowerCase() as TelljaWidgets)
        : widget;

    const urlParam =
      widgetParam ||
      window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    //next/script can't be used here yet (check https://github.com/vercel/next.js/issues/30984)
    const telljaScript = document.createElement('script');
    telljaScript.id = `tellja-script-${containerId}-${widgetId}`;
    telljaScript.async = true;
    telljaScript.src = `https://t.tellja.eu/widget/button/${widgetId}?t_art_number=${widgetName}&btn=transparent&t_mc=${urlParam}&placementid=${containerId}-${widgetId}`;
    document.body.append(telljaScript);
    setPlacementId(`${containerId}-${widgetId}`);

    return () => {
      telljaScript.remove();
    };
  }, [containerId]);

  useEffect(() => {
    setContainerId(generateUID());
  }, []);

  return {
    placementId,
  };
};
