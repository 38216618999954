import { StorageType } from '.';

function getStorage(type: StorageType): Storage | null {
  if (typeof window === 'undefined') return null;
  const storage: Storage =
    type === 'sessionStorage' ? window.sessionStorage : localStorage;
  return storage;
}

export function getFromStorage<T>(type: StorageType, key: string) {
  const storage = getStorage(type);
  if (!storage) return null;

  const storageItemJSON = storage.getItem(key);
  if (!storageItemJSON) return null;

  const storageItem: T = JSON.parse(storageItemJSON);
  return storageItem;
}

export function setStorage<T>(type: StorageType, key: string, item: T) {
  const storage = getStorage(type);
  if (!storage) return;

  const storageItemJSON = JSON.stringify(item);
  storage.setItem(key, storageItemJSON);
}
