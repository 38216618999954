import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { ContactExistingCustomerRequest } from '../Forms/Contact/Views/ExistingCustomer/UploadForm/model';
import { ContactNewCustomerRequest } from '../Forms/Contact/Views/NewCustomer/model';
import { ResellerContactRequest } from '../Forms/ResellerContact/model';
import { TariffAdvisorValues } from '../TariffAdvisor';
import { orderReducer } from './orderReducer';
import { Mandant, storageAvailable } from '@ncs-frontend-monorepo/utils';
import { getAddressParams } from '@ncs-frontend-monorepo/availability';

export type OrderState = {
  address: AddressSelection | null;
  orderFormData: { [key: string]: string | number | boolean | [] };
  formData: FormData;
};

export type ContactExistingCustomerFormData = Omit<
  ContactExistingCustomerRequest,
  'file-upload-ids'
>;

interface FormData {
  contactNewCustomerForm?: ContactNewCustomerRequest;
  contactExistingCustomerForm?: ContactExistingCustomerFormData;
  tariffAdvisorForm?: TariffAdvisorValues;
  resellerContact?: ResellerContactRequest;
  isCustomer?: boolean;
}

export interface AddressSelection {
  zipCode: string;
  street: string;
  city: string;
  district?: string;
  houseNumber: string;
  houseSeqNumber?: number;
  mandant?: Mandant;
}

export const initialState: OrderState = {
  address: null,
  orderFormData: null,
  formData: null,
};

export enum OrderActionType {
  SET_ADDRESS = 'SET_ADDRESS',
  CLEAR_ORDER_FORM_DATA = 'CLEAR_ORDER_FORM_DATA',
  SAVE_ORDER_FORM_DATA = 'SAVE_ORDER_FORM_DATA',
  SAVE_FORM_DATA = 'SAVE_FORM_DATA',
}

type ActionSetAddress = {
  type: OrderActionType.SET_ADDRESS;
  payload: {
    address: OrderState['address'];
  };
};

type ActionSaveOrderFormData = {
  type: OrderActionType.SAVE_ORDER_FORM_DATA;
  payload: OrderState['orderFormData'];
};

type ActionSaveFormData = {
  type: OrderActionType.SAVE_FORM_DATA;
  payload: OrderState['formData'];
};

type ActionclearOrderFormData = {
  type: OrderActionType.CLEAR_ORDER_FORM_DATA;
};

export type OrderAction =
  | ActionSetAddress
  | ActionSaveOrderFormData
  | ActionclearOrderFormData
  | ActionSaveFormData;

export interface OrderContext {
  setAddress: (address: AddressSelection) => void;
  saveOrderFormData: (orderFormData: OrderState['orderFormData']) => void;
  clearOrderFormData: () => void;
  orderFormData: OrderState['orderFormData'];
  formData: OrderState['formData'];
  saveFormData: (formData: OrderState['formData']) => void;
}

export enum LocalStorageKeys {
  Address = 'address',
  LastUpdate = 'lastUpdate',
}

export enum SessionStorageKeys {
  OrderFormData = 'orderFormData',
  FormData = 'genericFormData',
}

export const storageExpiration = 90 * 24 * 60 * 60 * 1000; // 90 days

const hasStorage = storageAvailable();

const deleteStorage = () => {
  if (hasStorage) {
    Object.values(SessionStorageKeys).forEach((key) => {
      window.sessionStorage.removeItem(key);
    });
  }
};

const initializer = (initialValue: Partial<OrderState>): OrderState => {
  if (initialValue) {
    return {
      ...initialState,
      ...initialValue,
    };
  }
  if (hasStorage) {
    const lastUpdate = window.localStorage.getItem(LocalStorageKeys.LastUpdate);
    if (parseInt(lastUpdate) + storageExpiration < Date.now()) {
      deleteStorage();
    }
    return {
      address:
        getAddressParams() ||
        JSON.parse(window.localStorage.getItem(LocalStorageKeys.Address)) ||
        initialState.address,
      orderFormData:
        JSON.parse(
          window.sessionStorage.getItem(SessionStorageKeys.OrderFormData),
        ) || initialState.orderFormData,
      formData:
        JSON.parse(
          window.sessionStorage.getItem(SessionStorageKeys.FormData),
        ) || initialState.formData,
    };
  }
  return initialState;
};

const Context = createContext<OrderContext | null>(null);
Context.displayName = 'OrderContext';

const useOrderContext: () => OrderContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useOrderContext must be used within a <OrderProvider> tag',
    );
  }
  return contextState;
};

type OrderProviderProps = {
  value?: Partial<OrderState>;
  children?: ReactNode;
};

const OrderProvider: FC<OrderProviderProps> = ({ value, children }) => {
  const [hasConsent, setHasConsent] = useState(true);
  const [state, dispatch] = useReducer(orderReducer, value, initializer);
  const consentUpdate = () => {
    setHasConsent(window['Cookiebot'].consent.preferences);
  };

  const setAddress = (address: OrderState['address']) => {
    dispatch({
      type: OrderActionType.SET_ADDRESS,
      payload: { address },
    });
  };

  const saveOrderFormData = (orderFormData: OrderState['orderFormData']) => {
    dispatch({
      type: OrderActionType.SAVE_ORDER_FORM_DATA,
      payload: orderFormData,
    });
  };

  const clearOrderFormData = () => {
    dispatch({ type: OrderActionType.CLEAR_ORDER_FORM_DATA });
  };

  const saveFormData = (payload: OrderState['formData']) => {
    dispatch({
      type: OrderActionType.SAVE_FORM_DATA,
      payload,
    });
  };

  useEffect(() => {
    setHasConsent(window['Cookiebot']?.consent?.preferences || true);
    window.addEventListener('CookiebotOnDecline', consentUpdate);
    window.addEventListener('CookiebotOnAccept', consentUpdate);

    return () => {
      window.removeEventListener('CookiebotOnDecline', consentUpdate);
      window.removeEventListener('CookiebotOnAccept', consentUpdate);
    };
  }, []);

  useEffect(() => {
    const { address, orderFormData, formData } = state;

    // always store necessary values
    if (hasStorage) {
      window.localStorage.setItem(
        LocalStorageKeys.Address,
        JSON.stringify(address),
      );
    }

    // store optional values if consent is given
    if (hasStorage && hasConsent) {
      if (
        orderFormData &&
        'personal-password' in orderFormData &&
        'personal-password-confirm' in orderFormData
      ) {
        // prevent that the password will be saved into the session storage
        // this is only for the FTTH MVP. We should evaluate if https://github.com/dchest/tweetnacl-js is a suitable solution
        delete orderFormData['personal-password'];
        delete orderFormData['personal-password-confirm'];
      }

      window.sessionStorage.setItem(
        SessionStorageKeys.OrderFormData,
        JSON.stringify(orderFormData),
      );

      window.localStorage.setItem(
        LocalStorageKeys.LastUpdate,
        JSON.stringify(Date.now()),
      );

      window.sessionStorage.setItem(
        SessionStorageKeys.FormData,
        JSON.stringify(formData),
      );
    }
  }, [state, hasConsent]);

  useEffect(() => {
    if (!hasConsent) {
      deleteStorage();
    }
  }, [hasConsent]);

  return (
    <Context.Provider
      value={{
        setAddress,
        saveOrderFormData,
        clearOrderFormData,
        saveFormData,
        ...state,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { OrderProvider, useOrderContext };
