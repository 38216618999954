import { Grid, Text } from 'dss-ui-library';
import { FC } from 'react';
import { ChevronLink, LinkProps } from '../../components/Link';

export interface BackLinkProps {
  link: LinkProps & { text?: string };
}
const BackLink: FC<BackLinkProps> = ({ link }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <ChevronLink {...link} rotation="left">
          <Text appearance="t4_2">{link.text}</Text>
        </ChevronLink>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default BackLink;
