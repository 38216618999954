import { useEffect, useState, useCallback, useRef, RefObject } from 'react';

import { getObserver } from '../helper/intersectionObserver';

export const useIntersectionObserver = (
  wrapperRef: RefObject<Element | null>,
  threshold?: number[],
  rootMargin?: string,
): { entry: IntersectionObserverEntry | null } => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const [isReady, setIsReady] = useState(false);
  const observer = useRef<IntersectionObserver>(null);
  const intersectionThreshold =
    threshold || [...Array(100)].map((_, i) => i * 0.01);

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        setEntry(entry);
      });
    },
    [],
  );

  useEffect(() => {
    if (!isReady || !wrapperRef?.current) {
      return;
    }

    if (observer.current) observer.current.disconnect();
    const newObserver = getObserver(
      null,
      observer,
      intersectionCallback,
      intersectionThreshold,
      rootMargin,
    );

    newObserver.observe(wrapperRef.current);

    return () => newObserver?.disconnect();
  }, [wrapperRef?.current?.children?.length, isReady]);

  useEffect(() => {
    async function checkPolyFills() {
      if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
      }
      setIsReady(true);
    }
    checkPolyFills();
  }, []);

  return {
    entry,
  };
};
