import * as React from 'react';
import { Text, Spacer, Grid } from 'dss-ui-library';
import { BusinessUnit, MandantType } from '@ncs-frontend-monorepo/utils';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import { ModalContentWrapper } from '../ModalContentWrapper';
import { AddressFields } from '../AddressRow';
import { getSiteName } from '../../utils/getSiteName';
import { getMandantUrl } from '../../utils';

interface MandantMismatchProps {
  address: AddressFields;
  resetAddress: () => void;
  businessUnit?: BusinessUnit;
  mandantType?: MandantType;
  fallback: JSX.Element;
  onRedirect?: ({ targetUrl }: { targetUrl: string }) => void;
}

export function MandantMismatch({
  address,
  businessUnit = 'PK',
  mandantType,
  fallback,
  resetAddress,
  onRedirect,
}: MandantMismatchProps): React.ReactNode {
  const { correctSite, currentSite } = getSiteName('mismatch');

  const targetUrl = React.useMemo(
    () => getMandantUrl(address, businessUnit),
    [address, businessUnit],
  );

  // generic error for unsupported mandants
  if (mandantType === null) return fallback;

  return (
    <ModalContentWrapper
      title={`Deine Adresse liegt im Anschlussgebiet von ${correctSite}.`}
      address={address}
      addressLabel="Stimmt deine Adresse?"
      resetAddress={resetAddress}
    >
      <Text appearance={'t4'}>
        An deiner Adresse surfst du im glasfaserschnellen Netz von {correctSite}
        . Ein Anschluss von {currentSite} ist bei dir nicht verfügbar. Wechsel
        jetzt zur Seite von {correctSite} und sicher dir tolle Angebote.
      </Text>
      <Spacer t={3} block />
      {targetUrl && (
        <Grid.Row>
          <Grid.Column hEnd>
            <ButtonLink
              link={{ url: targetUrl }}
              onClick={() => onRedirect && onRedirect({ targetUrl })}
              btnColor="red"
            >
              Weiter zu {correctSite}
            </ButtonLink>
          </Grid.Column>
        </Grid.Row>
      )}
    </ModalContentWrapper>
  );
}
