import CustomError from '../ErrorClass';
import { fetchLogging } from '../logging';

export * from './requestHeaders';

export function assertHTTPStatus(response: Response): void {
  if (response.ok || response.status === 300) {
    return;
  }

  throw new CustomError(response.status, response.statusText);
}

export const fetcher = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  passCatch = false,
) => {
  try {
    fetchLogging({
      message: 'Requesting',
      url: input as string,
      error: false,
    });

    const response = init ? await fetch(input, init) : await fetch(input);
    assertHTTPStatus(response);

    fetchLogging({
      message: 'Success',
      url: input as string,
      error: false,
    });

    return response.status === 204 ? null : await response.json();
  } catch (e) {
    // if the error has a status code, throw it as the cause
    if (typeof e === 'object' && e != null && Object.hasOwn(e, 'status')) {
      const [status, message] = Object.values(e);
      throw new Error(`${message}`, { cause: status });
    }

    const message = typeof e === 'object' ? JSON.stringify(e) : (e as string);
    fetchLogging({ message, url: input as string, error: true });
    if (passCatch) {
      throw new Error();
    } else {
      return null;
    }
  }
};

export const fetcherText = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<string | null> => {
  try {
    fetchLogging({
      message: 'Requesting',
      url: input as string,
      error: false,
    });

    const response = init ? await fetch(input, init) : await fetch(input);
    assertHTTPStatus(response);

    fetchLogging({
      message: 'Success',
      url: input as string,
      error: false,
    });

    return await response.text();
  } catch (e) {
    const message = typeof e === 'object' ? JSON.stringify(e) : (e as string);
    fetchLogging({ message, url: input as string, error: true });

    return null;
  }
};

export const fetcherBoolean = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<boolean> => {
  try {
    fetchLogging({
      message: 'Requesting',
      url: input as string,
      error: false,
    });

    const response = init ? await fetch(input, init) : await fetch(input);
    assertHTTPStatus(response);

    fetchLogging({
      message: 'Success',
      url: input as string,
      error: false,
    });

    return true;
  } catch (e) {
    const message = typeof e === 'object' ? JSON.stringify(e) : (e as string);
    fetchLogging({ message, url: input as string, error: true });
    return false;
  }
};
