import { ImageLoaderProps } from 'next/image';
import { ImageProps } from 'dss-ui-library';

export function imageLoader({
  src,
  width,
  quality = 75,
  subImages = {},
}: {
  src: ImageLoaderProps['src'];
  width: ImageLoaderProps['width'];
  quality?: ImageLoaderProps['quality'];
  subImages: ImageProps['subImages'];
}): string {
  const generatedSource = subImages[String(width)]?.src || src;

  return createNextImageSrc(
    encodeURIComponent(generatedSource),
    String(width),
    quality,
  );
}

export function setNextImageSrc({
  imageSrc,
  quality = 75,
}: {
  imageSrc: ImageProps;
  quality?: ImageLoaderProps['quality'];
}) {
  let nextSubImages = {};
  Object.values(imageSrc?.subImages || []).forEach((subImage, ix) => {
    const width = Object.keys(imageSrc?.subImages || [])[ix];
    nextSubImages = {
      [width]: {
        ...subImage,
        src: `${createNextImageSrc(
          encodeURIComponent(subImage.src),
          width,
          quality,
        )}${
          subImage.factor && subImage.factor > 1
            ? `, ${createNextImageSrc(
                encodeURIComponent(subImage.src),
                String(subImage.width),
                quality,
              )} 2x`
            : ''
        }`,
      },
      ...nextSubImages,
    };
  });

  return {
    ...imageSrc,
    src: createNextImageSrc(
      encodeURIComponent(imageSrc.src),
      imageSrc.width ? String(imageSrc.width) : '1440',
      quality,
    ),
    subImages: nextSubImages,
  };
}

function createNextImageSrc(src: string, width: string, quality: number) {
  return `/_next/image?url=${src}&w=${width}&q=${quality}`;
}
