import {
  Animation,
  ChevronLinkProps,
  IconChevron,
  px2rem,
  withHover,
} from 'dss-ui-library';
import { FC } from 'react';
import { BaseLink, BaseLinkProps } from '../BaseLink';

export const ChevronLink: FC<BaseLinkProps & Omit<ChevronLinkProps, 'href'>> =
  withHover(
    ({ children, hover, rotation = 'right', distance, indent, ...props }) => (
      <BaseLink
        {...props}
        icon={
          <Animation.Slide
            in={hover}
            direction={rotation}
            distance={
              distance !== undefined ? distance : rotation === 'right' ? 4 : 2
            }
          >
            <IconChevron
              rotation={rotation}
              small
              style={
                indent && {
                  marginLeft: px2rem(-6),
                }
              }
              width={24}
              height={24}
            />
          </Animation.Slide>
        }
      >
        {children}
      </BaseLink>
    ),
  );
