import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    s: 768,
    m: 1024,
    l: 1440,
    xl: 1920,
  },
});

export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
