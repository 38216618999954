import {
  Availability,
  NetTvType,
  Portfolio,
  Promotions,
} from '@ncs-frontend-monorepo/availability';
import { Product } from '../../../';
import { PlanState } from './usePlanState';
import { checkHasMismatchingTV } from '../../../utils/tv/checkHasMismatchingTV';

export type GetPlanStateParams = {
  plan: Product;
  plannedAvailability: Availability['planned'];
  maxAvailableDownload: number | null;
  fallbackPlanState: PlanState;
  availableTv: Promotions['tvPromotions'];
  promotions: Availability['promotions']['order'];
  portfolio?: Portfolio;
};

export function getPlanState({
  plan,
  plannedAvailability,
  maxAvailableDownload = 0,
  fallbackPlanState,
  promotions,
  portfolio,
  availableTv,
}: GetPlanStateParams) {
  const { internet, group } = plan;
  const internetDownload = internet?.download || 0;

  if (maxAvailableDownload === null && plannedAvailability === null) {
    return fallbackPlanState;
  } else {
    const plannedMaxAvailableDownload =
      plannedAvailability?.maxAvailableDownload || 0;

    const availablePromotion = promotions
      ?.sort((a, b) => (a.maxDownload < b.maxDownload ? -1 : 1))
      ?.find((promotion) => promotion.maxDownload >= internetDownload);

    const hasPlannedDownload =
      internetDownload >
      Math.max(
        Number(maxAvailableDownload) || 0,
        Number(plannedAvailability?.maxAvailableDownload) || 0,
      );

    const isPortfolioMismatch = !!(
      portfolio && availablePromotion?.portfolio !== portfolio
    );

    const hasMismatchingTV = checkHasMismatchingTV({
      templateGroup: group,
      availableTv,
      isPortfolioMismatch,
      isInternetAvailable: Number(maxAvailableDownload) > 0,
    });

    return {
      isDisabled: !!(hasPlannedDownload || hasMismatchingTV),
      isPresale:
        internetDownload > Number(maxAvailableDownload) &&
        plannedMaxAvailableDownload >= internetDownload,
      isPlanStateChecked: true,
      portfolioMismatch: portfolio
        ? availablePromotion && availablePromotion.portfolio !== portfolio
        : false,
      availablePortfolio: availablePromotion?.portfolio,
      hasNetTVInternet:
        availableTv?.includes(NetTvType.NET_TV_INTERNET) || false,
    };
  }
}
