import { FC, useEffect, useState } from 'react';
import Digits from './Digits';

export interface TimerProps {
  datetime: string;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const Timer: FC<TimerProps> = ({ datetime }) => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const target = new Date(datetime.replace(/\s+/g, 'T'));

      const now = new Date();
      const diff = target.getTime() - now.getTime();
      const day = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hour = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minute = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const second = Math.floor((diff % (1000 * 60)) / 1000);
      setDays(day);
      setHours(hour);
      setMinutes(minute);
      setSeconds(second);
    }, 1000);

    return () => clearInterval(interval);
  }, [datetime]);

  return (
    <>
      <Digits digit={days} digitLabel={'Tage'} />
      <Digits digit={hours} digitLabel={'Stunden'} />
      <Digits digit={minutes} digitLabel={'Minuten'} />
      <Digits digit={seconds} digitLabel={'Sekunden'} />
    </>
  );
};

export default Timer;
