import { BaseLinkProps as DSSBaseLinkProps } from 'dss-ui-library';
import { FC } from 'react';
import { BaseLink, BaseLinkProps } from '../BaseLink';

export const TextLink: FC<BaseLinkProps & Omit<DSSBaseLinkProps, 'href'>> = ({
  children,
  appearance = 't4',
  ...props
}) => {
  return (
    <BaseLink appearance={appearance} underlined {...props}>
      {children}
    </BaseLink>
  );
};
