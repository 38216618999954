import { useCallback } from 'react';

interface SetCookieProps {
  name: string;
  value: string;
  expiresInDays?: number;
  path?: string;
  domain?: string;
}

interface RemoveCookieProps {
  name: string;
  domain?: string;
}

export const useCookies = () => {
  const setCookie = useCallback((props: SetCookieProps): string => {
    const { name, value, expiresInDays = 0, path = '/', domain = '' } = props;
    let expires = '0';

    if (expiresInDays !== 0) {
      const date = new Date();
      date.setDate(date.getDate() + expiresInDays);
      expires = date.toUTCString();
    }
    const encodedValue = btoa(value);
    document.cookie = `${name}=${encodedValue}; expires=${expires}; path=${path}; ${
      domain !== '' && `domain=${domain}`
    };`;
    return encodedValue;
  }, []);

  const getCookie = useCallback((name: string): string => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? atob(parts[1]) : r;
    }, '');
  }, []);

  const removeCookie = useCallback((props: RemoveCookieProps) => {
    const { name, domain = '' } = props;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; ${
      domain !== '' && `domain=${domain};`
    }`;
  }, []);

  if (typeof window === 'undefined') {
    return {
      setCookie: () => null,
      getCookie: () => null,
      removeCookie: () => null,
    };
  } else {
    return {
      setCookie,
      getCookie,
      removeCookie,
    };
  }
};
