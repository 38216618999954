import { FC, ReactNode } from 'react';
import { useTelljaScript } from './useTelljaScript';
import styles from './TelljaWrapper.module.scss';
import { TelljaWidgets } from './index';
import { PartnerWidgetProps } from '../PartnerWidget';

interface TelljaWrapperProps {
  children?: ReactNode;
  widget: TelljaWidgets;
  widgetParam: PartnerWidgetProps['widgetParam'];
  e2e?: string;
}

export const TelljaWrapper: FC<TelljaWrapperProps> = ({
  widget,
  widgetParam,
  e2e,
  children,
}) => {
  const { placementId } = useTelljaScript({
    widgetParam,
    widget,
  });
  return (
    <div
      className={styles.telljaWrapper}
      data-e2e={`widget-${e2e || widgetParam}`}
    >
      {children}
      <div id={placementId} className={styles.telljaLink} />
    </div>
  );
};
export default TelljaWrapper;
