import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAvailability } from './useAvailability';
import { getAddressParams } from '../utils';
import { AddressFields } from '../components';

export function useInitializeAvailabilityStore() {
  const { handleAvailabilityCheck, address } = useAvailability();
  const hasRunRef = useRef(false);

  const addressToCheck = useMemo(
    () => getAddressParams() || address,
    [address],
  );

  const isValidAddress = useCallback(
    (address?: AddressFields | null) =>
      address?.houseNumber &&
      address?.street &&
      address?.zipCode &&
      address?.city,
    [],
  );

  useEffect(() => {
    if (hasRunRef.current === false && isValidAddress(addressToCheck)) {
      handleAvailabilityCheck({ address: addressToCheck });
      hasRunRef.current = true;
    }
  }, [addressToCheck, isValidAddress]);
}
