import { Availability, FetchAddressReponse } from '../interfaces';

export const initialAddress: FetchAddressReponse = {
  city: '',
  houseNumber: '',
  street: '',
  zipCode: '',
  areaCode: '',
  district: '',
  hausLfdnr: 0,
  mandant: null,
  source: '',
  client: '',
};

export const initialAvailability: Availability = {
  address: null,
  promotions: {
    order: null,
    availablePromotions: null,
    presalePromotion: null,
    tvPromotions: null,
    fallback: null,
  },
  maxDownload: null,
  planned: null,
  ftthPresalesInformation: null,
  list: [],
  portfolio: 'CLASSIC',
  objectInformation: null,
  isChecked: false,
  isValidAddress: false,
};
