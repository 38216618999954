import { IconChat, SkeletonView, Text } from 'dss-ui-library';
import { FC, MouseEvent } from 'react';
import useSWR from 'swr';
import { useContentContext } from '../../components/ContentContext';
import { TilesGroup, TilesGroupProps } from '../TilesGroup';
import { TilesGroupItems } from '../TilesGroup/TilesGroup';
import { fetcher } from '@ncs-frontend-monorepo/utils';

interface ChatConfig {
  window: string;
  chatStatus: string;
}

const configNC = {
  window: 'https://livechat.netcologne.de/netcologne',
  chatStatus:
    'https://livechat.netcologne.de/chatRest/status?category=Chat_Allgemein_NC',
};

const configNA = {
  window: 'https://livechat.netaachen.de/netaachen',
  chatStatus:
    'https://livechat.netaachen.de/chatRest/status?category=Chat_Allgemein_NA',
};

const chatWindowConfig = {
  width: 590,
  height: 840,
};

interface ChatAvailability {
  available: boolean;
}

export interface ContactTilesGroupProps
  extends Omit<TilesGroupProps, 'columnCount' | 'breakpoint'> {
  chat: boolean;
  fullWidth?: boolean;
  headline?: string;
}

export const ContactTilesGroup: FC<ContactTilesGroupProps> = ({
  chat = false,
  items = [],
  backgroundColor = 'white',
  fullWidth = false,
  headline,
}) => {
  const { site } = useContentContext();
  const config: ChatConfig = site === 'NetAachen' ? configNA : configNC;
  const { data, isValidating } = useSWR<ChatAvailability>(
    chat ? config.chatStatus : null,
    {
      revalidateOnMount: true,
      fetcher,
    },
  );

  let displayedItems: ContactTilesGroupProps['items'] = items;

  if (chat) {
    const showChatWindow = (e: MouseEvent<HTMLElement> | undefined) => {
      e?.preventDefault();
      const leftPos = window.innerWidth - chatWindowConfig.width;
      const topPos = window.outerHeight - chatWindowConfig.height;
      window.open(
        config.window,
        '',
        `height=${chatWindowConfig.height}, width=${chatWindowConfig.width},top=${topPos},left=${leftPos},status=yes,toolbar=no,menubar=no,location=no`,
      );
    };

    const text = data?.available ? (
      'Brauchst du Hilfe? Tritt direkt mit uns in Kontakt.'
    ) : isValidating ? (
      <SkeletonView single height="56px" background="white" />
    ) : (
      <Text appearance="t4">
        Der Chat steht leider momentan nicht zur Verfügung, wir aber schon.
        <br /> Bitte wähle eine der anderen Möglichkeiten.
      </Text>
    );

    const chatItem: TilesGroupItems = {
      component: 'chat',
      id: 999,
      props: {
        headline: 'Chatte mit uns',
        text,
        icon: <IconChat width={48} color="blue" />,
        ...(data?.available && {
          link: {
            url: config.window,
            onClick: showChatWindow,
            text: 'Zum Chat',
          },
        }),
      },
    };

    displayedItems = [chatItem].concat(items);
  }

  return (
    <TilesGroup
      columnCount={2}
      breakpoint="s"
      items={displayedItems}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      headline={headline}
    />
  );
};

export default ContactTilesGroup;
