import { FC } from 'react';
import {
  Text,
  TextElements,
  Slider as SliderUI,
  SliderSettings,
  Slide,
  SlideProps,
  Button,
  ButtonProps,
  ImageConfigTypes,
  ImageSrc,
  optimizedImageSrc,
  Picture,
} from 'dss-ui-library';
import { PartnerWidget, PartnerWidgetProps, LinkProps } from '../../components';
import styles from './Slider.module.scss';
import { setNextImageSrc } from '../../utils';

interface NormalizedSlideProps extends SlideProps {
  headline: string;
  subline: string;
  button?: ButtonProps & {
    contactTrack: string;
    text: string;
    link: LinkProps;
  };
  partner?: {
    widget?: PartnerWidgetProps['widget'];
    param?: PartnerWidgetProps['widgetParam'];
  };
}

export interface SliderProps {
  settings: SliderSettings;
  sliderItems: NormalizedSlideProps[];
}

export interface SliderPropsFetched {
  pagination: boolean;
  navigation: boolean;
  autoslide: number;
  infinite?: boolean;
  autoplay?: boolean;
  slides: SlidePropsFetched[];
  useOptimizedImages?: boolean;
}

interface SlidePropsFetched {
  headline: string;
  headlineElement: TextElements;
  backgroundColor: SlideProps['backgroundColor'];
  copytext: string;
  textColor: string;
  image: ImageSrc;
  layout: string;
  button?: {
    type: string;
    contactTrack: string;
    text: string;
    link: LinkProps;
  };
  partnerWidget?: PartnerWidgetProps['widget'];
  partnerWidgetParam?: PartnerWidgetProps['widgetParam'];
}

const normalizeSliderData = ({
  pagination,
  navigation,
  autoslide,
  slides,
  useOptimizedImages = true,
}: SliderPropsFetched): SliderProps => {
  const settings = {
    dots: pagination,
    arrows: navigation,
    autoplaySpeed: autoslide,
    autoplay: true,
    infinite: slides.length > 1,
    lazyLoad: 'ondemand' as any,
  };

  const sliderItems = slides.map((slide: SlidePropsFetched) => {
    const optimizedImages = optimizedImageSrc({
      imageSrc: slide.image,
      config:
        slide.layout === 'full'
          ? ImageConfigTypes.SliderFullWidth
          : ImageConfigTypes.SliderSquare,
    });

    return {
      headline: slide.headline,
      headlineElement: slide.headlineElement || 'h1',
      backgroundColor: slide.backgroundColor,
      subline: slide.copytext,
      image: (
        <div className={styles.image}>
          <Picture
            {...(useOptimizedImages
              ? setNextImageSrc({ imageSrc: optimizedImages })
              : optimizedImages)}
            includingLength={false}
          />
        </div>
      ),
      layout: (slide.layout === 'half'
        ? 'image-right'
        : 'full-width') as SlideProps['layout'],
      ...(slide.partnerWidget && {
        partner: {
          widget: slide.partnerWidget,
          param: slide.partnerWidgetParam || '',
        },
      }),
      ...(slide.button && {
        button: {
          ...slide.button,
          type: 'button' as ButtonProps['type'],
          variant: slide.button.type as ButtonProps['variant'],
          e2e: slide.button.contactTrack,
          color:
            slide.button.type === 'primary'
              ? ('red' as ButtonProps['color'])
              : ['white', 'grey'].includes(slide.backgroundColor as string)
              ? 'blue'
              : 'white',
        },
      }),
    };
  });

  return { settings, sliderItems };
};

const Slider: FC<SliderPropsFetched> = (props) => {
  const sliderData = normalizeSliderData(props);
  return (
    <SliderUI settings={sliderData.settings}>
      {sliderData.sliderItems.map(
        ({ button, ...slide }: NormalizedSlideProps) => (
          <Slide key={slide.headline + slide.subline} {...slide}>
            {button &&
              (slide?.partner?.widget ? (
                <PartnerWidget
                  widget={slide.partner.widget}
                  widgetParam={slide.partner.param}
                  button={button}
                  backgroundColor={slide.backgroundColor}
                />
              ) : (
                <Button
                  {...button}
                  onClick={() => {
                    if (button.link.target === '_blank') {
                      window.open(button.link.url, button.link.target);
                    } else {
                      window.location.href = button.link.url;
                    }
                  }}
                >
                  <Text appearance="t4_2">{button.text}</Text>
                </Button>
              ))}
          </Slide>
        ),
      )}
    </SliderUI>
  );
};

export default Slider;
