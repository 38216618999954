import { ProductId } from '@ncs-frontend-monorepo/order';
import {
  LandlinePromotion,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export const fallbackProductIds = [
  ProductId.NETSPEED_175,
  ProductId.NETSPEED_PHONE_175,
  ProductId.NETSPEED_TV_175,
  ProductId.NETSPEED_PHONE_TV_175,
  ProductId.NETSPEED_25,
  ProductId.NETSPEED_PHONE_25,
  ProductId.NETSPEED_TV_25,
  ProductId.NETSPEED_PHONE_TV_25,
  ProductId.NETSPEED_YOUNG_175,
  ProductId.NETSPEED_YOUNG_PHONE_175,
  ProductId.NETSPEED_YOUNG_TV_175,
  ProductId.NETSPEED_YOUNG_PHONE_TV_175,
  ProductId.NETSPEED_YOUNG_25,
  ProductId.NETSPEED_YOUNG_PHONE_25,
  ProductId.NETSPEED_YOUNG_TV_25,
  ProductId.NETSPEED_YOUNG_PHONE_TV_25,
];

export const getPromotionWithMaxDownload = (
  promotions: LandlinePromotion[],
): LandlinePromotion | null =>
  promotions?.length > 0
    ? promotions?.reduce((p, c) => (p.maxDownload > c.maxDownload ? p : c))
    : null;

export const hasTechnologies = ({
  technologies,
  availablePromotions,
  presalePromotion,
}: {
  technologies: Technology[];
  availablePromotions?: LandlinePromotion[];
  presalePromotion?: LandlinePromotion;
}): boolean =>
  availablePromotions?.some((promotion) =>
    technologies.includes(promotion?.technology),
  ) ||
  technologies.includes(presalePromotion?.technology) ||
  false;
