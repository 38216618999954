import { useCallback } from 'react';
import { Product, ProductGroups, TemplateGroup } from '../index';
import { Text } from 'dss-ui-library';
import { getFormattedPriceString, isGK } from '@ncs-frontend-monorepo/utils';

export const usePrice = (
  plan: Product,
  isLongTerm: boolean,
  wordBreakOnMobile = true,
) => {
  const { pricing, houseConnection } = plan;
  const hasDiscountedMonthlyPrice =
    pricing.monthly?.discountedPrices.length > 0;
  const getPrice = useCallback(() => {
    return isLongTerm
      ? hasDiscountedMonthlyPrice
        ? pricing.monthly.discountedPrices[0].price
        : pricing.monthly.price
      : pricing.monthly.price;
  }, [
    isLongTerm,
    pricing.monthly.discountedPrices,
    pricing.monthly.price,
    hasDiscountedMonthlyPrice,
  ]);

  const getPlanFlag = useCallback(() => {
    const hasDiscountedPrice = pricing.totalDiscount?.total > 0;

    switch (true) {
      case plan.group === TemplateGroup.TV_CABLE:
        return pricing.monthly.discountedPrices.length > 0
          ? `${pricing.monthly.discountedPrices[0].duration + ' Monate'}`
          : 'dauerhaft';
      case !hasDiscountedPrice || !isLongTerm:
        return 'dauerhaft';
      default:
        return `${isGK() ? 'Sie sparen' : 'Du sparst'} ${
          pricing.totalDiscount.total
        } €`;
    }
  }, [isLongTerm, plan, pricing]);

  const getPriceInfo = useCallback(() => {
    const showHouseConnection =
      plan.houseConnection &&
      plan.houseConnection?.pricing.onetime.price > 0 &&
      plan.groups.includes(ProductGroups.FTTH_FACTORY);
    const isYoung = plan.groups.includes(ProductGroups.YOUNG);
    const priceInfo = hasDiscountedMonthlyPrice && isLongTerm && (
      <>
        ab dem{' '}
        <span data-e2e="special-offer-duration">
          {pricing.monthly.discountedPrices[0].end + 1}
        </span>
        . Monat {wordBreakOnMobile && <br className="fresnel-lessThan-s" />}
        <strong data-e2e="regular-offer-price">
          {getFormattedPriceString(pricing.monthly.price)}
        </strong>{' '}
        mtl.
      </>
    );

    const youngInfo = isYoung && (
      <>
        {hasDiscountedMonthlyPrice && isLongTerm ? ' für' : 'Für'} die gesamte
        Studien- und Ausbildungszeit
      </>
    );

    const homeConnection = showHouseConnection && (
      <div suppressContentEditableWarning data-e2e="price-house-connection">
        Hausanschluss ggf. kostenpflichtig
      </div>
    );

    return (
      <Text appearance="t5">
        {priceInfo}
        {youngInfo}
        {homeConnection}
      </Text>
    );
  }, [
    hasDiscountedMonthlyPrice,
    houseConnection?.pricing.onetime.price,
    isLongTerm,
    plan.groups,
    pricing.monthly.discountedPrices,
    pricing.monthly.price,
    wordBreakOnMobile,
  ]);

  const price = getPrice();
  const planFlag = getPlanFlag();
  const priceInfo = getPriceInfo();

  return {
    price,
    planFlag,
    priceInfo,
  };
};
