import { OrderAction, OrderActionType, OrderState } from './';

export const orderReducer = (
  state: OrderState,
  action: OrderAction,
): OrderState => {
  switch (action.type) {
    case OrderActionType.SET_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
      };

    case OrderActionType.SAVE_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: { ...action.payload },
      };

    case OrderActionType.CLEAR_ORDER_FORM_DATA:
      return {
        ...state,
        orderFormData: null,
      };

    case OrderActionType.SAVE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
