import { MandantType, getEnv, isNC } from '@ncs-frontend-monorepo/utils';

export function getSiteName(mandantType?: MandantType) {
  const currentSite = getEnv().SITE;
  let correctSite = currentSite;
  if (mandantType === 'mismatch') {
    correctSite = isNC() ? 'NetAachen' : 'NetCologne';
  }
  return { correctSite, currentSite };
}
