import { AvailabilityStatus, fiberTechnologies } from '../constants';
import { LandlinePromotion } from '../interfaces';

export function getAlternativeProductAvailability(
  promotions: LandlinePromotion[],
) {
  const allowedAvailabilities = [
    AvailabilityStatus.AVAILABLE,
    AvailabilityStatus.AVAILABLE_INSTALL_GFAST,
    AvailabilityStatus.PRESALE,
    AvailabilityStatus.REALIZABLE,
    AvailabilityStatus.REALIZABLE_INSTALL_GFAST,
  ];

  const promotion = promotions.find(
    (promotion) =>
      allowedAvailabilities.some(
        (allowed) => allowed === promotion.availability,
      ) && fiberTechnologies.includes(promotion.technology),
  );

  return promotion?.availability || AvailabilityStatus.NOT_AVAILABLE;
}
