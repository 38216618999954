import { Technology } from '../template';

export const DEFAULT_FIBER_TECHNOLOGY = Technology.FTTH;
export const DEFAULT_CLASSIC_TECHNOLOGY = Technology.CABLE;

export const classicTechnologies = [
  Technology.ADSL2_IP,
  Technology.DSL_ISDN,
  Technology.DSL_ANALOG,
  Technology.CABLE,
  Technology.VDSL_BSA_L2,
  Technology.VDSL_BSA_L3,
  Technology.FTTC,
  Technology.ADSL,
];

export const fiberTechnologies = [
  Technology.FTTH,
  Technology.GFAST,
  Technology.VDSL2_FTTB,
  Technology.FTTH_BSA_L2,
  Technology.FTTH_BSA_L2_DG,
];
