import { FC } from 'react';
import cx from 'classnames';
import { Text, ButtonProps } from 'dss-ui-library';
import styles from './Tellja.module.scss';
import { useTelljaScript } from './useTelljaScript';

export enum TelljaWidgets {
  BRAND = 'brand',
  NETCOLOGNE = 'netcologne',
  NETAACHEN = 'netaachen',
  NETSPEED = 'netspeed',
  NETSPEEDYOUNG = 'netspeedyoung',
  NETSPEEDGLASFASER = 'netspeedglasfaser',
}

export type TelljaButtonProps = {
  widget: TelljaWidgets;
  widgetParam?: string;
  button: ButtonProps & { text: string };
};

export const TelljaButton: FC<TelljaButtonProps> = ({
  widget,
  widgetParam,
  button,
}) => {
  const { placementId } = useTelljaScript({ widget, widgetParam });
  const buttonClasses = cx(styles.telljaButtonWrapper, {
    [styles.button]: true,
    [styles[button.variant]]: !!button.variant,
    [styles[button.color]]: !!button.color,
  });

  return (
    <>
      {placementId && (
        <div className={buttonClasses} data-e2e={`widget-${button.e2e}`}>
          <span className={styles.label}>
            <Text appearance="t4_2">{button.text}</Text>
          </span>
          <span className={styles.scalableBackground} />
          <div id={placementId} className={styles.telljaButtonContent} />
        </div>
      )}
    </>
  );
};

export default TelljaButton;
