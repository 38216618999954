export const HotlineNumber = {
  NetCologne: {
    PK: {
      formatted: '0221 – 2222 800',
      tel: '02212222800',
    },
    GK: {
      formatted: '0241 91852 - 840',
      tel: '024191852840',
    },
  },
  NetAachen: {
    PK: {
      formatted: '0800 - 2222 333',
      tel: '08002222333',
    },
    GK: {
      formatted: '0221 2222 - 5151',
      tel: '022122225151',
    },
  },
} as const;
