import { FC } from 'react';
import Image from 'next/image';
import {
  Grid,
  Spacer,
  Text,
  Separator,
  Background,
  BackgroundProps,
  ImageProps,
} from 'dss-ui-library';
import styles from './Testimonial.module.scss';
import { Link, LinkLocation, LinkProps } from '../../components';
import { getImageProps } from '@ncs-frontend-monorepo/utils';

export interface TestimonialProps {
  image: ImageProps;
  description: string;
  text: string;
  linkText?: string;
  link?: LinkProps;
  backgroundColor: BackgroundProps['color'];
}

const Testimonial: FC<TestimonialProps> = ({
  image,
  description,
  text,
  linkText,
  link,
  backgroundColor,
}) => {
  return (
    <Background color={backgroundColor}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Separator color="blue" height={4} />
            <Spacer block b={3} />
            <Grid.Row>
              <Grid.Column s={4}>
                <div className={styles.imgWrapper}>
                  <Image
                    {...getImageProps(image)}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  />
                </div>
              </Grid.Column>

              <Grid.Column s={6}>
                <div>
                  <Spacer block b={1}>
                    <Text appearance="t2_3" color="blue">
                      {description}
                    </Text>
                  </Spacer>
                  <Text appearance="t5">{text}</Text>
                  {link && (
                    <Spacer block t={1}>
                      <Link
                        appearance="t5"
                        url={link.url}
                        e2e={'testimonial-link'}
                        className={styles.textlink}
                        linkLocation={LinkLocation.TESTIMONIAL}
                      >
                        {linkText}
                      </Link>
                    </Spacer>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Spacer block t={3} />
            <Separator color="blue" height={4} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Background>
  );
};

export default Testimonial;
