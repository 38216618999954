import { AvailabilityStatus } from '../constants';
import { FTTHPresalesInformation, ObjectInformation } from '../interfaces';
import { hasGrantedGee, isFTTHOffered } from './notConnected';

export function createFTTHPresaleInformatino({
  objectInformation,
  alternativeProductAvailability,
}: {
  objectInformation: ObjectInformation;
  alternativeProductAvailability: AvailabilityStatus;
}): FTTHPresalesInformation {
  return {
    minAvailableDownload: objectInformation?.minBandwidth,
    maxAvailableDownload: Number(
      objectInformation?.plannedBandwidth.replace(/\D/g, ''),
    ),
    isOffered: isFTTHOffered(
      objectInformation?.status,
      alternativeProductAvailability,
    ),
    landingPage: objectInformation?.landingpagePk,
    availabilityDate: objectInformation?.availabilityDate,
    availabilityDateRaw: objectInformation?.availabilityDateRaw,
    availabilityDateHalfYear: objectInformation?.availabilityDateHalfYear,
    status: objectInformation?.status,
    houseConnectionPrice: objectInformation?.houseConnectionPrice,
    alternativeProductAvailability,
    hasGrantedGee: hasGrantedGee(
      objectInformation?.geeStatus,
      objectInformation?.geeValidFrom,
    ),
    expansionAccessType: objectInformation?.ausbauAccessTyp,
    mergedAccessType: objectInformation?.mergedAccessType,
    wholeBuy: objectInformation?.wholebuy,
  };
}
