import { FC } from 'react';
import styles from './Image.module.scss';
import NextImage from 'next/image';
import { Text, Grid, Spacer, ImageProps as UIImageProps } from 'dss-ui-library';
import { getImageProps } from '@ncs-frontend-monorepo/utils';
export interface ImageProps {
  image: UIImageProps;
  description?: string;
}

const Image: FC<ImageProps> = ({ image, description }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column m={8}>
          <figure className={styles.moduleImageFigure}>
            <NextImage
              {...getImageProps(image)}
              src={image.src}
              sizes="100vw"
              className={styles.moduleImageImg}
            />
            {description && (
              <Spacer t={1} block>
                <figcaption className={styles.moduleImageCaption}>
                  <Text appearance="t5">{description}</Text>
                </figcaption>
              </Spacer>
            )}
          </figure>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Image;
