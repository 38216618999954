import { NetTvType } from '../../constants';
import { Promotions } from '../../interfaces';

export function hasCableTV(tvPromotions: Promotions['tvPromotions']): boolean {
  return [
    NetTvType.NET_TV_CABLE,
    NetTvType.NET_TV_FTTH,
    NetTvType.NET_TV_CABLE_GWG_NEUSS,
  ].some((type) => tvPromotions?.includes(type));
}
