export const getObserver = (
  root: Element | Document | null = null,
  ref: React.MutableRefObject<IntersectionObserver | null>,
  callback: (entries: IntersectionObserverEntry[]) => void,
  threshold: number | number[],
  rootMargin?: string,
): IntersectionObserver => {
  const observer = ref.current;
  if (observer !== null) {
    return observer;
  }
  const newObserver = new IntersectionObserver(callback, {
    root,
    rootMargin: rootMargin || '0px',
    threshold,
  });
  ref.current = newObserver;
  return newObserver;
};
