import {
  Separator,
  Spacer,
  Text,
  TextAppearances,
  RichText,
  TextElements,
  ListIcon,
} from 'dss-ui-library';
import { FC, isValidElement, MouseEvent, ReactNode } from 'react';
import { ChevronLink } from '../Link';
import styles from './Tile.module.scss';

export interface TileProps {
  headline: string;
  text: string | ReactNode;
  icon: number | ReactNode;
  link?: {
    url: string;
    target?: string;
    title?: string;
    text?: string;
    e2e?: string;
    pageId?: number;
    onClick?: (e?: MouseEvent<HTMLElement>) => void;
  };
  linkAppearance?: TextAppearances;
  color?: 'superlight-grey' | 'blue';
  headlineElement?: TextElements;
}

export const Tile: FC<TileProps> = ({
  headline,
  icon,
  text,
  link,
  linkAppearance,
  color = 'superlight-grey',
  headlineElement = 'h2',
}) => {
  return (
    <div className={styles.tile}>
      <Text appearance="t4_2" color="blue" element={headlineElement}>
        {headline}
      </Text>
      <Spacer t={1} block />
      <Separator height={4} color={color} />
      <Spacer t={2} block />
      <div className={styles.content}>
        <Spacer r={2}>
          {typeof icon === 'number' && (
            <ListIcon iconType={icon} iconColor="blue" size={48} />
          )}
          {isValidElement(icon) && icon}
        </Spacer>
        <div className={styles.text}>
          {typeof text === 'string' && <RichText text={text} />}
          {isValidElement(text) && text}
          {link && (
            <>
              <Spacer t={3} block />
              <ChevronLink
                appearance={linkAppearance}
                url={link.url}
                target={link.target}
                onClick={link.onClick}
                title={link.title}
                e2e={link.e2e}
                indent
              >
                {link.text}
              </ChevronLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
