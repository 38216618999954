import * as generateUID from 'uuid';
import { storageAvailable } from '../storage/available';

let correlationId = '';
export const correlationIdKey = 'correlationId';

export const generateCorrelationId = () => {
  correlationId =
    (storageAvailable() && window.sessionStorage?.getItem(correlationIdKey)) ||
    '';
  if (!correlationId) {
    correlationId = generateUID.v4();
    storageAvailable() &&
      window.sessionStorage?.setItem(correlationIdKey, correlationId);
  }
  return correlationId;
};

export const getCorrelationId = () => {
  return correlationId === '' ? generateCorrelationId() : correlationId;
};

export const removeCorrelationId = () => {
  correlationId = '';
  window?.sessionStorage?.removeItem(correlationIdKey);
};
