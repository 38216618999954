import { FC } from 'react';
import { Grid, Spacer, Text, TextElements } from 'dss-ui-library';
import { Tile, TileProps } from '../../components/Tile';
import styles from './TilesGroup.module.scss';
import cn from 'classnames';
import { ButtonLink, ButtonList } from '../../components/ButtonLink';

export interface TilesGroupItems {
  component: string;
  id: number;
  props: TileProps;
}

export interface TilesGroupProps {
  columnCount: number;
  breakpoint: 's' | 'm' | 'l' | 'xl';
  backgroundColor?: 'white' | 'superlight-grey';
  fullWidth?: boolean;
  items: TilesGroupItems[];
  buttonList?: ButtonList;
  headline?: string;
  headlineElement?: TextElements;
}

const columnMap: { [column: number]: number } = {
  2: 6,
  3: 4,
};

export const TilesGroup: FC<TilesGroupProps> = ({
  columnCount,
  breakpoint,
  backgroundColor,
  fullWidth = false,
  items = [],
  buttonList,
  headline,
  headlineElement = 'h2',
}) => {
  const backgroundClass = `background-${backgroundColor}`;
  const breakpointConfig = {
    [breakpoint]: columnMap[columnCount],
  };
  return (
    <div className={cn(styles.tiles, styles[backgroundClass])}>
      <Grid fullWidth={fullWidth} className={styles.grid}>
        {headline && (
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t1_2" color="blue" element={headlineElement}>
                {headline}
              </Text>
              <Spacer t={3} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={styles.tilesRow}>
          {items.map((item: TilesGroupItems, tileIdx: number) => (
            <Grid.Column
              {...breakpointConfig}
              key={`tile-${tileIdx}`}
              marginBottom={5}
            >
              <div className={styles.tileWrapper}>
                <Tile
                  {...item.props}
                  color={
                    backgroundColor === 'superlight-grey'
                      ? 'blue'
                      : 'superlight-grey'
                  }
                />
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
        {buttonList && (
          <>
            <Spacer t={5} block />
            <Grid.Row>
              <Grid.Column showXs hideS>
                {buttonList &&
                  buttonList.map(({ button }, index) => (
                    <Spacer b={1} key={index} block>
                      <ButtonLink {...button} btnFullwidth>
                        {button.text}
                      </ButtonLink>
                    </Spacer>
                  ))}
              </Grid.Column>
              <Grid.Column end hide showS>
                {buttonList &&
                  buttonList.map(({ button }, index) => (
                    <Spacer r={1} key={index}>
                      <ButtonLink {...button} btnFullwidth={false}>
                        {button.text}
                      </ButtonLink>
                    </Spacer>
                  ))}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </div>
  );
};

export default TilesGroup;
