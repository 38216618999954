import { ImageProps } from 'dss-ui-library';
import { pick } from '.';

export const getImageProps = (image: ImageProps) => {
  const props = pick(
    image,
    'alt',
    'className',
    'src',
    'width',
    'height',
    'title',
  );

  return {
    ...props,
    alt: props?.alt ?? '',
  };
};
