import { FTTHPresalesInformation } from '../../../interfaces';

interface IsBandwidthAvailableParams {
  bandwidth: number;
  ftthPresalesInformation: FTTHPresalesInformation;
}

export function isBandwidthAvailable({
  bandwidth,
  ftthPresalesInformation,
}: IsBandwidthAvailableParams): boolean {
  return !!(
    ftthPresalesInformation &&
    ftthPresalesInformation.isOffered &&
    ftthPresalesInformation.minAvailableDownload &&
    ftthPresalesInformation.minAvailableDownload <= bandwidth &&
    ftthPresalesInformation.maxAvailableDownload >= bandwidth
  );
}
