import { ExpansionStatus } from '../constants';
import { Availability } from '../interfaces';

export type CheckIsValidAddressParams = {
  address: Availability['address'];
  maxDownload: Availability['maxDownload'];
  planned: Availability['planned'];
  status?: ExpansionStatus;
};

export function checkIsValidAddress({
  address,
  maxDownload,
  planned,
  status,
}: CheckIsValidAddressParams): boolean {
  if (!address || !status) return false;

  return !!(
    maxDownload ||
    planned ||
    ![ExpansionStatus.NOT_PLANNED, ExpansionStatus.OUT_OF_AREA].includes(status)
  );
}
