import { create } from 'zustand';
import { ProductGroups, ProductModifiers } from '../../constants';
import { createJSONStorage, persist } from 'zustand/middleware';
import { BasketProduct, ShoppingCartData } from '../../interfaces';

export interface OrderStoreState {
  modifier: ProductModifiers;
  updateModifier: (modifier: ProductModifiers) => void;
  resetModifier: () => void;
  selectedOptions: ProductGroups[];
  updateSelectedOptions: (option: ProductGroups[]) => void;
  isYoung: boolean;
  setIsYoung: (isYoung: boolean) => void;
  isCustomer: boolean;
  setIsCustomer: (isCustomer: boolean) => void;
  basket: BasketProduct | null;
  updateBasket: (basket: BasketProduct) => void;
  shoppingCartData: ShoppingCartData | null;
  updateShoppingCartData: (shoppingCartData: ShoppingCartData) => void;
  resetBasket: () => void;
  reset: () => void;
}

const defaultOrderStore = {
  modifier: ProductModifiers.withoutAll,
  selectedOptions: [],
  isYoung: false,
  isCustomer: false,
  basket: null,
  shoppingCartData: null,
};

export const useOrderStore = create<OrderStoreState>()(
  persist(
    (set) => ({
      ...defaultOrderStore,
      updateModifier: (modifier) =>
        set((state) => ({
          ...state,
          modifier,
        })),
      updateSelectedOptions: (selectedOptions) =>
        set((state) => ({
          ...state,
          selectedOptions,
        })),
      resetModifier: () =>
        set({
          modifier: ProductModifiers.withoutAll,
          selectedOptions: [],
        }),
      setIsYoung: (isYoung) =>
        set({
          isYoung,
        }),
      setIsCustomer: (isCustomer) =>
        set({
          isCustomer,
        }),
      updateBasket: (basket) =>
        set((state) => ({
          ...state,
          basket,
        })),
      updateShoppingCartData: (shoppingCartData) =>
        set((state) => ({
          ...state,
          shoppingCartData,
        })),
      resetBasket: () =>
        set((state) => ({
          ...state,
          basket: defaultOrderStore.basket,
        })),
      reset: () => set(() => defaultOrderStore),
    }),
    {
      name: 'orderStore',
      storage: createJSONStorage(() =>
        window['Cookiebot']?.consent?.preferences
          ? localStorage
          : sessionStorage,
      ),
    },
  ),
);
