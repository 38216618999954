export enum TemplateGroup {
  NETSPEED = 'NETSPEED',
  KOMBI_PHONE = 'KOMBI_PHONE',
  KOMBI_TV = 'KOMBI_TV',
  KOMBI_TV_CABLE = 'KOMBI_TV_CABLE',
  KOMBI_PHONE_TV = 'KOMBI_PHONE_TV',
  KOMBI_PHONE_TV_CABLE = 'KOMBI_PHONE_TV_CABLE',
  NETSPEED_YOUNG = 'NETSPEED_YOUNG',
  NETSPEED_YOUNG_KOMBI_PHONE = 'NETSPEED_YOUNG_KOMBI_PHONE',
  NETSPEED_YOUNG_KOMBI_TV = 'NETSPEED_YOUNG_KOMBI_TV',
  NETSPEED_YOUNG_KOMBI_PHONE_TV = 'NETSPEED_YOUNG_KOMBI_PHONE_TV',
  CAMPAIGN_FLEX = 'CAMPAIGN_FLEX',
  CAMPAIGN_FLEX_PHONE = 'CAMPAIGN_FLEX_PHONE',
  CAMPAIGN_FLEX_TV = 'CAMPAIGN_FLEX_TV',
  CAMPAIGN_FLEX_TV_APP = 'CAMPAIGN_FLEX_TV_APP',
  CAMPAIGN_FLEX_PHONE_TV = 'CAMPAIGN_FLEX_PHONE_TV',
  CAMPAIGN_FLEX_PHONE_TV_APP = 'CAMPAIGN_FLEX_PHONE_TV_APP',
  TV_CABLE = 'TV_CABLE',
  TV_CABLE_GWG_NEUSS = 'TV_CABLE_GWG_NEUSS',
  TV_INTERNET = 'TV_INTERNET',
  FTTH_FACTORY = 'FTTH_FACTORY',
  FTTH_FACTORY_2023_09 = 'FTTH_FACTORY_2023_09',
  FTTH_FACTORY_2023_09_PHONE = 'FTTH_FACTORY_2023_09_PHONE',
  FTTH_FACTORY_2023_09_TV = 'FTTH_FACTORY_2023_09_TV',
  FTTH_FACTORY_2023_09_PHONE_TV = 'FTTH_FACTORY_2023_09_PHONE_TV',
  FTTH_FACTORY_2024_09 = 'FTTH_FACTORY_2024_09',
  FTTH_FACTORY_2024_09_PHONE = 'FTTH_FACTORY_2024_09_PHONE',
  FTTH_FACTORY_2024_09_TV = 'FTTH_FACTORY_2024_09_TV',
  FTTH_FACTORY_2024_09_PHONE_TV = 'FTTH_FACTORY_2024_09_PHONE_TV',
}

export const FTTH_FACTORY_GROUPS = [
  TemplateGroup.FTTH_FACTORY_2024_09,
  TemplateGroup.FTTH_FACTORY_2024_09_PHONE,
  TemplateGroup.FTTH_FACTORY_2024_09_TV,
  TemplateGroup.FTTH_FACTORY_2024_09_PHONE_TV,
];

export const YOUNG_GROUPS = [
  TemplateGroup.NETSPEED_YOUNG,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_PHONE,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_TV,
  TemplateGroup.NETSPEED_YOUNG_KOMBI_PHONE_TV,
];
