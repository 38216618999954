import { useEffect } from 'react';

export function useHorizontalScroll({
  ref,
  disableAtBreakpoint,
}: {
  ref: React.RefObject<HTMLElement | null>;
  disableAtBreakpoint?: number;
}) {
  useEffect(() => {
    if (ref.current) {
      const onWheel = (e: WheelEvent) => {
        const isDisabled =
          disableAtBreakpoint && window.innerWidth >= disableAtBreakpoint;
        if (isDisabled || e.deltaY === 0) return;
        e.preventDefault(); // prevents vertical scroll on body
        ref.current?.scrollTo({
          left: ref.current?.scrollLeft + e.deltaY,
          behavior: 'smooth',
        });
      };
      ref.current.addEventListener('wheel', onWheel);
      return () => ref.current?.removeEventListener('wheel', onWheel);
    }
  }, [disableAtBreakpoint]);
}
