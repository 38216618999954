import { useEffect, useState } from 'react';
import { Product, ProductGroups, CABLE_TV_COMBI_GROUPS } from '../../../';
import {
  Portfolio,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { getNotConnectedPlanState } from './getNotConnectedPlanState';
import { getPlanState } from './getPlanState';
import { getTVPlanState } from './getTVPlanState';

export interface PlanState {
  isDisabled: boolean;
  isPresale: boolean;
  isPlanStateChecked: boolean;
  portfolioMismatch?: boolean;
  availablePortfolio?: Portfolio;
  hasNetTVInternet: boolean;
}

export const defaultPlanState: PlanState = {
  isDisabled: false,
  isPresale: false,
  isPlanStateChecked: false,
  portfolioMismatch: false,
  availablePortfolio: 'FIBER',
  hasNetTVInternet: false,
};

interface UsePlanStateParams {
  plan: Product;
  isFTTHPresale?: boolean;
  portfolio?: Portfolio;
}

export function usePlanState({
  plan,
  isFTTHPresale = false,
  portfolio,
}: UsePlanStateParams) {
  const { address, availability } = useAvailability();
  const [planState, setPlanState] = useState(defaultPlanState);

  useEffect(() => {
    if (!plan) {
      return;
    }
    if (
      plan.groups.includes(ProductGroups.TV_STANDALONE) ||
      plan.groups.includes(ProductGroups.TV_OPTION)
    ) {
      return setPlanState((currentPlanState) =>
        getTVPlanState({
          plan,
          availableTv: availability.promotions.tvPromotions,
          fallbackPlanState: address ? currentPlanState : defaultPlanState,
        }),
      );
    }

    setPlanState((currentPlanState: PlanState) =>
      isFTTHPresale
        ? getNotConnectedPlanState({
            plan,
            ftthPresalesInformation: availability.ftthPresalesInformation,
            fallbackPlanState: address ? currentPlanState : defaultPlanState,
            promotions: availability.promotions.order,
            portfolio,
          })
        : getPlanState({
            plan,
            plannedAvailability: availability.planned,
            maxAvailableDownload: availability.maxDownload,
            fallbackPlanState: address ? currentPlanState : defaultPlanState,
            promotions: availability.promotions.order,
            portfolio,
            availableTv: availability.promotions.tvPromotions,
          }),
    );
  }, [
    availability.maxDownload,
    availability.planned,
    plan?.internet?.download,
    plan?.group,
    availability.ftthPresalesInformation,
    address,
    isFTTHPresale,
    availability.promotions.order,
    CABLE_TV_COMBI_GROUPS.includes(plan?.group),
    availability.promotions.tvPromotions,
    portfolio,
  ]);

  return planState;
}
