import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Availability } from '../interfaces';
import { initialAvailability } from './initialValues';

interface AvailabilityStoreState {
  setAddress: (address: Availability['address']) => void;
  availability: Availability;
  setAvailability: (availability: Availability) => void;
  hasAvailability: boolean;
  setHasAvailability: (hasAvailability: boolean) => void;
  resetAvailability: () => void;
  setIsChecked: (isChecked: boolean) => void;
}

export const useAvailabilityStore = create<AvailabilityStoreState>()(
  persist(
    (set, get) => ({
      setAddress: (address) =>
        set({
          availability: { ...get().availability, address },
        }),
      availability: initialAvailability,
      setAvailability: (availability) => set({ availability }),
      hasAvailability: false,
      setHasAvailability: (hasAvailability) => set({ hasAvailability }),
      resetAvailability: () =>
        set({
          availability: initialAvailability,
          hasAvailability: false,
        }),
      setIsChecked: (isChecked) =>
        set({
          availability: { ...get().availability, isChecked },
        }),
    }),
    {
      name: 'availabilityStore',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
