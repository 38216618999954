import { FC } from 'react';
import { Grid, PageTitle as PageTitleUI, PageTitleProps } from 'dss-ui-library';

const PageTitle: FC<PageTitleProps> = (props) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <PageTitleUI {...props} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default PageTitle;
