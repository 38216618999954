import { isNC } from '@ncs-frontend-monorepo/utils';
import { AddressFields } from '../components';
import { AvailabilityResponse, FetchAddressReponse } from '../interfaces';
import { Technology } from '../template';
import { cableFallback } from './cableFallback';

export function getFallbackResponse(
  technology: Technology.CABLE,
  addressFields: AddressFields,
): AvailabilityResponse {
  const address: FetchAddressReponse = {
    ...addressFields,
    source: '',
    areaCode: '',
    district: '',
    hausLfdnr: 0,
    mandant: isNC() ? 'NC' : 'NA',
  };

  switch (technology) {
    case Technology.CABLE:
      return { address, ...cableFallback };

    default:
      return { address, ...cableFallback };
  }
}
