import { GridMode, GridModeContext, FootnoteContext } from 'dss-ui-library';
import 'dss-ui-library/lib/index.css';
import { ContentProvider } from '@ncs-frontend-monorepo/content-library';
import App, { AppContext, AppProps } from 'next/app';
import { useEffect } from 'react';
import { AuthProvider } from '../components/FTTH/AuthContext';
import GoogleTagManager from '../components/GoogleTagManager';
import { ModalProvider } from '../components/ModalContext';
import { OrderProvider } from '../components/OrderContext';
import { initialState, SiteProvider } from '../components/SiteContext';
import { Page, Sitemap } from '../interfaces';
import '../styles/globals.scss';
import { getContentConfig } from '../utils';
import {
  Env,
  getEnv,
  setEnv,
  useInitBreakpointStore,
  useInitCookieConsent,
  MediaContextProvider,
} from '@ncs-frontend-monorepo/utils';
import { useInitializeAvailabilityStore } from '@ncs-frontend-monorepo/availability';

export interface ModifiedAppInitialProps<A = { [key in string]: string }> {
  appProps: A;
}

export interface ExtendedAppProps<
  P = {
    path?: string;
    page?: Page;
    sitemap?: Sitemap;
  },
  A = { env: Env },
> extends AppProps<P>,
    ModifiedAppInitialProps<A> {}

function CustomApp({ Component, pageProps, appProps }: ExtendedAppProps) {
  // effect to ensure scroll position on load
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      if (!hash) return;
      const elementToScroll = document.getElementById(hash.replace('#', ''));
      if (!elementToScroll) return;
      elementToScroll.scrollIntoView();
    };

    scrollToHashElement();
    const scrollTimeout = setTimeout(scrollToHashElement, 300);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, []);

  // initialize zustand stores
  useInitBreakpointStore();
  useInitCookieConsent();
  useInitializeAvailabilityStore();

  setEnv(appProps.env);
  return (
    <MediaContextProvider disableDynamicMediaQueries>
      <GridModeContext.Provider value={GridMode.LARGE_MARGINS}>
        <FootnoteContext>
          <SiteProvider
            value={{
              ...initialState,
              ...(pageProps?.sitemap && { sitemap: pageProps.sitemap }),
              ...(pageProps?.page && { currentPageId: pageProps.page.pageId }),
              ...(pageProps?.path && { path: pageProps.path }),
            }}
          >
            <OrderProvider>
              <ContentProvider {...getContentConfig()}>
                <AuthProvider>
                  <ModalProvider>
                    <GoogleTagManager>
                      <Component {...pageProps} />
                    </GoogleTagManager>
                  </ModalProvider>
                </AuthProvider>
              </ContentProvider>
            </OrderProvider>
          </SiteProvider>
        </FootnoteContext>
      </GridModeContext.Provider>
    </MediaContextProvider>
  );
}

/* This getInitialProps method is used here for data that must be available on every page,
 * in this case the sitemap for the navigation and the environment variables.
 **/
CustomApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const env = getEnv();
  return { ...appProps, appProps: { env } };
};

export default CustomApp;
