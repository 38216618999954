import { FC, ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Counter.module.scss';
import { Grid, Spacer, Text, RichText, TextElements } from 'dss-ui-library';
import Timer from './Timer';

export interface CounterProps {
  backgroundColor: 'white' | 'blue' | 'grey';
  headline?: string;
  headlineElement: TextElements;
  text?: string;
  datetime: string;
  embedded?: boolean;
  bannerHeadline?: string;
  bannerText?: string;
}

interface CounterWrapperProps {
  children: ReactNode;
  embedded?: boolean;
}

const CounterWrapper = ({ children, embedded }: CounterWrapperProps) => {
  return embedded ? (
    <>{children}</>
  ) : (
    <Grid>
      <Grid.Row>
        <Grid.Column>{children}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const Counter: FC<CounterProps> = ({
  backgroundColor,
  headline = '',
  headlineElement,
  text = '',
  datetime,
  embedded = false,
  bannerHeadline = '',
  bannerText = '',
}) => {
  const [endedCounter, setEndedCounter] = useState(false);
  const [visibleHeadline, setVisibleHeadline] = useState<string>(
    headline || bannerHeadline,
  );
  const [visibleText, setVisibleText] = useState<string>(text || bannerText);
  const target = new Date(datetime);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diff = target.getTime() - now.getTime();
      setEndedCounter(diff <= 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (endedCounter) {
      setVisibleHeadline(bannerHeadline);
      setVisibleText(bannerText);
    }
  }, [endedCounter]);

  return endedCounter && !embedded ? (
    <></>
  ) : (
    <div
      className={cn(styles.counter, styles[backgroundColor], {
        [styles.embedded]: embedded,
      })}
    >
      <CounterWrapper embedded={embedded}>
        {visibleHeadline && (
          <Spacer b={2} block>
            <Text
              element={headlineElement}
              appearance="t1_2"
              className={styles.headline}
            >
              {visibleHeadline}
            </Text>
          </Spacer>
        )}

        {visibleText && (
          <RichText
            text={visibleText}
            onBackground={backgroundColor === 'blue' ? 'blue' : 'white'}
          />
        )}

        {datetime && !endedCounter && (
          <div className={styles.timer}>
            <Timer datetime={datetime} />
          </div>
        )}
      </CounterWrapper>
    </div>
  );
};

export default Counter;
