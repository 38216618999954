import { ImageProps, TextElements } from 'dss-ui-library';
import { FormikValues } from 'formik';
import {
  BreadcrumbItem,
  ContactTilesGroupProps,
  ContentType,
  DynamicContentType,
} from '@ncs-frontend-monorepo/content-library';
import { AvailabilityEmbeddedProps } from '../components/AvailabilityCheck/Embedded';
import { ExpansionCheckProps } from '../components/ExpansionCheck';
import { LeadContactProps } from '../components/FTTH/LeadContact';
import {
  IconSectionProps,
  LinksSectionProps,
} from '../components/Navigation/DesktopProductMenu/DropDown/Row/Sections';
import { Teaser } from '../components/Navigation/Teaser';
import { PlanColumnsProps } from '../components/PlanColumns';
import { PlanDetailProps } from '../components/PlanDetail';
import { PlanRowsProps } from '../components/PlanRows';
import { ExpansionDistricts } from '../components/ExpansionMap';
import { Meta } from '../components/Layout';
import { TariffAdvisor } from '../components/TariffAdvisor';
import {
  PriceInfo,
  ProductId,
  BasketPosition,
  BasketProduct,
  Footnote,
} from '@ncs-frontend-monorepo/order';
import { Mandant } from '@ncs-frontend-monorepo/utils';
import { Portfolio } from '@ncs-frontend-monorepo/availability';
import {
  FAQCategoriesProps,
  FAQCategoryPageProps,
  FAQPageProps,
} from '@ncs-frontend-monorepo/faq';

export interface Sitemap {
  pageTree: SitemapEntry[];
  globals: {
    facebookUrl?: string;
    youtubeUrl?: string;
    xingUrl?: string;
  };
}

export enum PageLayoutType {
  default = 0,
  hybrid = 1,
}

/**
 * Entry in the sitemap for dynamic content
 * Maps paths to pageIds
 */
export interface SitemapEntry {
  pageId: number;
  title: string;
  path: string;
  /** The url property indicates that the entry is a "link" and should be treated more link a redirect */
  url: string | null;
  hidden: boolean | null;
  display: 'none' | 'navigation' | 'meta' | 'footer' | 'folder';
  subpages: SitemapEntry[] | null;
  layout: PageLayoutType;
}

export interface InternalLink {
  title: string;
  path: string;
  url?: string;
  pageId?: string;
}

export interface APIRedirects {
  redirects: CustomRedirect[];
}

export interface CustomRedirect {
  sourceHost: string;
  sourcePath: string;
  isRegexp?: boolean;
  respectQueryParameters?: boolean;
  keepQueryParameters?: boolean;
  target: string;
  targetStatuscode: 301 | 302 | 303 | 307 | 308;
}

export enum DefaultRuntime {
  Default = 'default',
  Disabled = 'disabled',
}

type PlanLink = {
  [key in keyof typeof ProductId]?: SitemapEntry['pageId'];
};

interface PlanParamsProps extends PlanLink {
  templateId: ProductId;
  bestseller?: ProductId;
  topprice?: ProductId;
  highlighted?: ProductId;
  recommendation?: string;
  ftthPrePresale?: string;
  uncheckToggleOptionsByDefault?: string;
  iadDevices?: string;
  defaultRuntime?: DefaultRuntime;
  portfolio?: Portfolio;
  withoutCheck?: string;
  kundenweltURL?: string;
}

type PlanProps = {
  params: PlanParamsProps;
  type: 'planDetail' | 'planRows' | 'planColumns';
  runtimeInfo?: string;
  togglePhone?: boolean;
  toggleNetTv?: boolean;
  footnotes?: Footnote[];
};

export interface ContentTemplateEngine extends Pick<DynamicContentType, 'id'> {
  component: ContentType.TEMPLATE_ENGINE;
  props: PlanProps;
}

export interface ContentPlanRows extends Pick<DynamicContentType, 'id'> {
  component: ContentType.PLAN_ROWS;
  props: PlanRowsProps;
}

export interface ContentPlanColumns extends Pick<DynamicContentType, 'id'> {
  component: ContentType.PLAN_COLUMNS;
  props: PlanColumnsProps;
}

export interface ContentPlanDetail extends Pick<DynamicContentType, 'id'> {
  component: ContentType.PLAN_DETAIL;
  props: PlanDetailProps;
}

export interface ContentProductInfo extends Pick<DynamicContentType, 'id'> {
  component: ContentType.PRODUCT_INFO;
  props: ProductInfoWithPrice;
}

export interface ProductInfoWithPrice extends ProductInfo {
  priceInfo?: PriceInfo & {
    runtime?: number | null;
  };
}

export interface ProductComparisonProps {
  headline: string;
  text: string;
  backgroundColor: 'white' | 'superlight-grey';
  templateId?: ProductId;
  items: ContentProductInfo[];
  headlineElement?: TextElements;
}
export interface ContentProductComparison
  extends Pick<DynamicContentType, 'id'> {
  component: ContentType.PRODUCT_COMPARISON;
  props: ProductComparisonProps;
}

export interface ContentExpansionCheck
  extends Omit<DynamicContentType, 'component'> {
  component: ContentType.EXPANSION_CHECK;
  props: ExpansionCheckProps;
}

export interface ContentTooltipImage extends Pick<DynamicContentType, 'id'> {
  component: ContentType.TOOLTIP_IMAGE;
  props: {
    tooltipImage: string;
  };
}

export interface FAQCategories extends Pick<DynamicContentType, 'id'> {
  component: ContentType.FAQ_CATEGORIES;
  props: FAQCategoriesProps;
}

export interface FAQCategoryDetail extends Pick<DynamicContentType, 'id'> {
  component: ContentType.FAQ_CATEGORY_PAGE;
  props: FAQCategoryPageProps;
}

export interface FAQDetail extends Pick<DynamicContentType, 'id'> {
  component: ContentType.FAQ_PAGE;
  props: FAQPageProps;
}

export interface DynamicReplacementConfigProps {
  // if value is not "true" or "1" the replacement is disabled
  enabled?: string;
  // optional comma separated list of cities with enabled replacement
  replaceIn?: string;
}

export interface StaticExpansionMap extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.ExpansionMap;
  params: ExpansionDistricts;
}

export interface StaticTariffAdvisor extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.TariffAdvisor;
  params: TariffAdvisor;
  footnotes?: Footnote[];
}

export enum StaticContentTypes {
  AvailabilityTv = 'availabilityTv',
  AvailabilityTvStandalone = 'availabilityTvStandalone',
  AvailabilityNetSpeed = 'availabilityNetSpeed',
  AvailabilitySmall = 'availabilitySmall',
  CancellationForm = 'cancellationForm',
  LeadContactForm = 'leadContactForm',
  BuildingOwnerForm = 'buildingOwnerForm',
  AssociationForm = 'associationForm',
  ContactPK = 'contactPK',
  DynamicReplacementConfig = 'dynamicReplacementConfig',
  ExpansionMap = 'expansionMap',
  SmartcardActivation = 'smartcardActivation',
  TariffAdvisor = 'tariffAdvisor',
  ResellerContact = 'resellerContact',
}

export type StaticContentType =
  | StaticAvailabilityTV
  | StaticAvailabilityTVStandalone
  | StaticAvailabilityNetSpeed
  | StaticAvailabilitySmall
  | StaticCancellationForm
  | StaticLeadContactForm
  | StaticBuildingOwnerForm
  | StaticAssociationForm
  | StaticDynamicReplacementConfig
  | StaticContactFormPK
  | StaticExpansionMap
  | StaticSmartcardActivation
  | StaticTariffAdvisor
  | StaticResellerContact;

export interface StaticAvailabilityTV extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.AvailabilityTv;
  params: AvailabilityEmbeddedProps['params'];
}

export interface StaticAvailabilityTVStandalone
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.AvailabilityTvStandalone;
  params: AvailabilityEmbeddedProps['params'];
}

export interface StaticAvailabilityNetSpeed
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.AvailabilityNetSpeed;
  params: AvailabilityEmbeddedProps['params'];
}

export interface StaticAvailabilitySmall
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.AvailabilitySmall;
  params: AvailabilityEmbeddedProps['params'];
}

export interface StaticCancellationForm extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.CancellationForm;
}

export interface StaticLeadContactForm extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.LeadContactForm;
  params: LeadContactProps;
}

export interface StaticBuildingOwnerForm
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.BuildingOwnerForm;
}

export interface StaticAssociationForm extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.AssociationForm;
}

export interface StaticDynamicReplacementConfig
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.DynamicReplacementConfig;
  params: DynamicReplacementConfigProps;
}

export interface StaticContactFormPK extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.ContactPK;
}

export interface StaticResellerContact extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.ResellerContact;
}

export interface ContentStaticContent extends Pick<DynamicContentType, 'id'> {
  component: ContentType.STATIC_CONTENT;
  props: StaticContentType;
}

export interface StaticSmartcardActivation
  extends Pick<DynamicContentType, 'id'> {
  type: StaticContentTypes.SmartcardActivation;
}

export type CombinedContentType =
  | DynamicContentType
  | ContentTemplateEngine
  | ContentPlanRows
  | ContentPlanColumns
  | ContentPlanDetail
  | ContentProductComparison
  | ContentExpansionCheck
  | ContentTooltipImage
  | ContentStaticContent
  | FAQCategories
  | FAQCategoryDetail
  | FAQDetail;

export interface Page {
  pageId: number;
  content: CombinedContentType[] | DynamicContentType[];
  meta: Meta;
  breadcrumbs: BreadcrumbItem[];
}

export interface Link {
  url: string;
  text?: string;
  title?: string;
  target?: string;
  pageId: number;
}

export interface Item {
  link: Link;
  linkText: string;
}

export interface Links {
  headline: string;
  items: Item[];
}

interface BaseContentBlock {
  status: number;
  content: {
    component: string;
    id: number;
  };
}

export interface DropDownColumnProps {
  links: Links[];
  teasers: Teaser[];
  textLink?: {
    url: string;
    target: null;
    title: string;
    text: string;
    pageId: number;
  };
  textLinkTracking?: string;
}

type Section = IconSectionProps | LinksSectionProps;

interface Column {
  headline: string;
  rows: Section[];
}

export interface DropDownRowProps {
  textLink: {
    text: string;
    url: string;
  };
  textLinkTracking?: string;
  columns: Column[];
  teasers: Teaser[];
}

export interface ProductInfo {
  id: string;
  name?: string;
  shortDesc?: string;
  flag?: string;
  footnoteFlag?: Footnote['footnote'];
  image?: ImageProps;
  icon?: number;
  description: string;
  category: 'basic' | 'premium';
  type?: 'service' | 'device';
  tooltip?: string;
  footnote?: Footnote['footnote'];
  disabled?: boolean;
}

export interface DropDownColumnBlock extends BaseContentBlock {
  content: {
    component: string;
    id: number;
    props: DropDownColumnProps;
  };
}

export interface DropDownRowBlock extends BaseContentBlock {
  content: {
    component: string;
    id: number;
    props: DropDownRowProps;
  };
}

export interface ProductInfoContentBlock extends BaseContentBlock {
  content: {
    component: string;
    id: number;
    props: ProductInfo;
  };
}

export interface DynamicContentBlock extends BaseContentBlock {
  content: DynamicContentType;
}

export interface ContactTilesGroupContentBlock extends BaseContentBlock {
  content: {
    component: string;
    id: number;
    props: ContactTilesGroupProps;
  };
}

export enum Stage {
  // Only in the server
  SSR = 'SSR',
  // Only client
  CLIENT = 'Client',
  // Server and client
  UNIVERSAL = 'Universal',
}

export enum ProductTypes {
  internet,
  tv,
  telephony,
}

export interface OrderRequest {
  order: BasketProduct;
  basket: BasketPosition[];
  orderDetails: FormikValues;
  salesAttribution?: {
    vkz: string;
    viewvkz?: string;
    seovkz?: string;
  };
  mandant: Mandant;
}

export enum PartnerName {
  GENERIC = 'GENERIC',
  AWIN = 'AWIN',
  TELLJA = 'TELLJA',
  LEADALLIANCE = 'LEADALLIANCE',
}

export interface OrderResponse {
  obst: string;
  partner?: {
    name: PartnerName;
    orderId: string;
    products: {
      affiliateId: string;
      name: string;
      productId?: string;
    }[];
    ochannel?: string;
    programmId?: string;
  };
}
