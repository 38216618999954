import { PriceInfo } from '../';
import { getFormattedPriceString } from '@ncs-frontend-monorepo/utils';
export function getPromotionInfo(priceInfo: PriceInfo) {
  if (
    priceInfo?.specialOfferPrice === null &&
    typeof priceInfo !== 'undefined'
  ) {
    return null;
  }
  return (
    <>
      ab dem{' '}
      <span data-e2e="special-offer-duration">
        {(priceInfo?.specialOfferDuration || 0) + 1}
      </span>
      . Monat{' '}
      <strong data-e2e="regular-offer-price">
        {getFormattedPriceString(priceInfo?.price)}
      </strong>{' '}
      mtl.
    </>
  );
}
