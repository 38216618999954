import { Grid, Separator, Spacer, TabSlider, Text } from 'dss-ui-library';
import {
  SubContentElement,
  useSubContentElements,
} from './useSubContentElements';

export type TabSliderModule = {
  uid: number;
  pid: number;
  type: 'nccontentelements_tab';
  header: string;
  headerLink: string | null;
  headerPosition: string;
  subheader: string;
  headerLayout: 'h2';
  date: null;
  colPos: 0;
  contentElementProvider: 'FLUX';
  subContentElements: SubContentElement[];
  visibleTabsPerBreakPoint: {
    s: number;
    m: number;
    l: number;
  };
};

export default function TabSliderContent({
  header,
  subContentElements = [],
  visibleTabsPerBreakPoint,
}: TabSliderModule) {
  const tabs = useSubContentElements({ subContentElements });
  const { s = 2, m = 3, l = 4 } = visibleTabsPerBreakPoint;

  if (!tabs.length) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Text appearance="t1_2" color="blue">
            {header}
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Spacer t={1} />
      <TabSlider
        tabs={tabs}
        options={{ visibleTabsPerBreakpoint: { s, m, l } }}
      />
      <Spacer t={1} />
      <Separator height={2} color="superlight-grey" />
    </Grid>
  );
}
