import { useEffect } from 'react';
import { create } from 'zustand';
import { CookiebotType } from '../../types/cookiebot';

interface CookieConsentProps {
  statistics: boolean;
  marketing: boolean;
  preferences: boolean;
}

interface CookieConsentState {
  statistics: boolean;
  marketing: boolean;
  preferences: boolean;
  updateCookieConsent(props: CookieConsentProps): void;
}

export const useCookieConsentStore = create<CookieConsentState>((set) => ({
  statistics: false,
  marketing: false,
  preferences: false,
  updateCookieConsent: (props: CookieConsentProps) => {
    set(props);
  },
}));

declare global {
  interface Window {
    Cookiebot: CookiebotType;
  }
}

export const useInitCookieConsent = () => {
  const updateCookieConsent = useCookieConsentStore(
    (state) => state.updateCookieConsent,
  );

  useEffect(() => {
    const update = () => {
      if (window['Cookiebot']) {
        const { statistics, marketing, preferences } =
          window['Cookiebot'].consent;
        updateCookieConsent({ statistics, marketing, preferences });
      }
    };

    window.addEventListener('CookiebotOnDecline', update);
    window.addEventListener('CookiebotOnAccept', update);

    return () => {
      window.removeEventListener('CookiebotOnDecline', update);
      window.removeEventListener('CookiebotOnAccept', update);
    };
  }, []);
};
