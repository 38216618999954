export enum PropertyOwnerRole {
  tenant = 'TENANT',
  owner = 'OWNER',
  partOwner = 'PART_OWNER',
}

export enum PropertyOwnerRoleMapper {
  TENANT = 'TENANT',
  OWNER = 'OWNER',
  PART_OWNER = 'PART_OWNER',
}

export enum ResidentialUnit {
  one = 'ONE',
  moreThanOne = 'MORE_THAN_ONE',
}

export enum ResidentialUnitMappper {
  ONE = 'ONE',
  MORE_THAN_ONE = 'MORE_THAN_ONE',
}

export enum ResidentStatus {
  NO_RESIDENT = 'NO_RESIDENT',
  RESIDENT_LESS_THAN_SIX_MONTHS = 'RESIDENT_LESS_THAN_SIX_MONTHS',
  RESIDENT_SIX_OR_MORE_MONTHS = 'RESIDENT_SIX_OR_MORE_MONTHS',
}

export enum OrderCheckoutVariant {
  CUSTOMER_NEW = 'CUSTOMER_NEW',
  CUSTOMER_EXISTING = 'CUSTOMER_EXISTING',
  AGENT_CUSTOMER_NEW = 'AGENT_CUSTOMER_NEW',
  AGENT_CUSTOMER_EXISTING = 'AGENT_CUSTOMER_EXISTING',
}
