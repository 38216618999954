import { ExpansionStatus, PLANNED_FTTH_STATUS } from '../../constants';

export function hasFtthStatus({
  expansionStatus,
  isFiberTechnology,
}: {
  expansionStatus: ExpansionStatus;
  isFiberTechnology: boolean;
}) {
  return isFiberTechnology
    ? false
    : PLANNED_FTTH_STATUS.includes(expansionStatus);
}
