import { Button, ButtonProps, Text, TextProps } from 'dss-ui-library';
import { FC, ReactNode } from 'react';
import { LinkProps, PlainLink, LinkLocation } from '../Link';

export interface ButtonLinkProps {
  children?: ReactNode;
  type?:
    | 'primary_blue'
    | 'primary_red'
    | 'primary_white'
    | 'secondary_blue'
    | 'secondary_white';
  text?: string;
  contactTrack?: string;
  link: LinkProps;
  btnFullwidth?: boolean;
  textColor?: TextProps['color'];
  btnColor?: ButtonProps['color'];
  btnLocation?: LinkLocation;
  onClick?: () => void;
}

// This is a type that appears in the TYPO3 API
export interface ButtonObject {
  button: ButtonLinkProps;
}

// This is a type that appears in the TYPO3 API
export type ButtonList = ButtonObject[];

export const ButtonLink: FC<ButtonLinkProps> = ({
  type,
  children,
  link,
  contactTrack = '',
  btnFullwidth,
  textColor,
  btnColor,
  onClick,
  btnLocation,
}) => {
  let buttonVariant: ButtonProps['variant'] = 'primary';
  let buttonColor: ButtonProps['color'] = 'blue';

  if (type) {
    buttonVariant = type.split('_')[0] as ButtonProps['variant'];
    buttonColor = type.split('_')[1] as ButtonProps['color'];
  }

  if (btnColor) {
    buttonColor = btnColor;
  }

  // TODO: a button inside an anchor is not valid; read more: https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-a-element
  return (
    <PlainLink {...link} linkLocation={btnLocation} contactTrack={contactTrack}>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        e2e={contactTrack}
        fullWidth={btnFullwidth}
        onClick={onClick}
      >
        <Text appearance="t4_2" color={textColor}>
          {children}
        </Text>
      </Button>
    </PlainLink>
  );
};
