import { FC, ReactElement } from 'react';
import { WidgetButtonProps } from '../index';
import Script from 'next/script';
import { ButtonLink } from '../../ButtonLink';
import { SlideProps } from 'dss-ui-library';

export enum HeyFlowWidget {
  HeyFlowWidget = 'heyflow',
}

export type HeyFlowWidgetParams = {
  widgetParam?: string;
  button: WidgetButtonProps | ReactElement;
  backgroundColor: SlideProps['backgroundColor'];
};

export const HEYFLOW_WIDGET = 'heyflow';

function isWidgetButton(
  button: WidgetButtonProps | ReactElement,
): button is WidgetButtonProps {
  return (button as WidgetButtonProps).text !== undefined;
}

export const HeyFlow: FC<HeyFlowWidgetParams> = ({
  widgetParam = '',
  button,
  backgroundColor,
}) => {
  // add heyflow elements to the dom
  const initHeyFlow = () => {
    const heyflowModal = document.createElement('heyflow-modal');
    heyflowModal.setAttribute('flow-id', widgetParam);
    heyflowModal.setAttribute('pass-parameters', 'true');
    heyflowModal.setAttribute('dynamic-height', 'true');

    // hack: style has to be insert manually
    const heyflowCss = document.createElement('link');
    heyflowCss.setAttribute(
      'href',
      'https://storage.googleapis.com/heyflow-eu-static/widget/v1-1/style.css',
    );
    heyflowCss.setAttribute('rel', 'stylesheet');
    heyflowCss.setAttribute('type', 'text/css');
    heyflowCss.setAttribute('media', 'all');
    document.head.appendChild(heyflowCss);
    document.body.appendChild(heyflowModal);

    // hack to init heyflow because for some reason next.js does not run window "load" events
    // check if hey flow exists on window object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hey: any = 'hey' in window ? window.hey : null;
    // init heyflow script
    hey?.onload();
  };

  return (
    <>
      {!isWidgetButton(button) ? (
        button
      ) : (
        <ButtonLink
          link={{ url: `#heyflow-${widgetParam}` }}
          contactTrack={button.e2e}
          type={
            button.variant === 'primary'
              ? 'primary_red'
              : ['white', 'grey'].includes(backgroundColor as string)
              ? 'secondary_blue'
              : 'secondary_white'
          }
        >
          {button.text}
        </ButtonLink>
      )}

      <Script
        src={`https://storage.googleapis.com/heyflow-eu-static/widget/v1-6-1/index.min.js`}
        onLoad={initHeyFlow}
      />
    </>
  );
};

export default HeyFlow;
