export type ActionCode = (typeof ACTION_CODES)[keyof typeof ACTION_CODES];

export const ACTION_CODES_PK = {
  CONTACT_UPLOAD_FORM: 'PK-CF244',
} as const;

export const ACTION_CODES_MKW = {
  DOCUMENTS: '2-B3NH',
  TV_CABLE: '2-UKJA',
  TV_COMBI: '2-XFWM',
  RELOCATION: '2-1J79',
  TV_INTERNET: '2-XFWM',
};

export const ACTION_CODES = {
  ...ACTION_CODES_PK,
  ...ACTION_CODES_MKW,
} as const;
