import { Mandant } from '.';
import { Env } from '../env';

export type MandantType = 'match' | 'mismatch' | null;
const VALID_MANDANTS: Mandant[] = ['NA', 'NC', 'ND'];
const VALID_NC_MANDANTS: Mandant[] = ['NC', 'ND'];

export function checkMandantType(
  site: Env['SITE'],
  mandant: Mandant,
): MandantType {
  if (!VALID_MANDANTS.includes(mandant)) return null;

  switch (site) {
    case 'NetCologne':
      return VALID_NC_MANDANTS.includes(mandant) ? 'match' : 'mismatch';

    case 'NetAachen':
      return mandant === 'NA' ? 'match' : 'mismatch';

    default:
      return null;
  }
}
