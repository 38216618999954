import { Availability, LandlinePromotion } from '../interfaces';

export type MaxDownloads = Pick<Availability, 'maxDownload' | 'planned'>;

interface MaxDownloadsProps {
  availablePromotions: LandlinePromotion[] | null;
  presalePromotion: LandlinePromotion | null;
}

export function getMaxDownloads({
  availablePromotions,
  presalePromotion,
}: MaxDownloadsProps): MaxDownloads {
  const maxDownload = availablePromotions?.length
    ? availablePromotions?.reduce((p, c) =>
        p.maxDownload > c.maxDownload ? p : c,
      )?.maxDownload
    : 0;

  const planned = presalePromotion
    ? {
        plannedAvailabilityDate: presalePromotion.plannedAvailabilityDate,
        plannedAvailabilityDateDescription:
          presalePromotion.plannedAvailabilityDateDescription,
        maxAvailableDownload: presalePromotion.maxDownload,
      }
    : null;
  return {
    maxDownload,
    planned,
  };
}
