import cx from 'classnames';
import { IconProps, Text, TextAppearances } from 'dss-ui-library';
import { default as NextLink } from 'next/link';
import { FC, MouseEvent, ReactElement, ReactNode } from 'react';
import styles from './BaseLink.module.scss';
import { formatUrl, isInternalLink } from './helpers';

// it is used for gk tracking
export enum LinkLocation {
  BREADCRUMB = 'breadcrumb',
  CARD_TEASER = 'card-teaser',
  COMPARISON_TABLE = 'comparison-table',
  FOOTER = 'footer',
  HERO = 'hero',
  HERO_WIDE_IMAGE = 'hero-wide-image',
  ICON_CARD_TEASER = 'icon-card-teaser',
  IMAGE_TEASER = 'image-teaser',
  LINK_LIST = 'link-list',
  MIXED_CONTENT = 'mixed-content',
  NAVIGATION = 'navigation',
  STAGE = 'stage',
  TEASER_WIDE_IMAGE = 'teaser-wide-image',
  TECHNOLOGY_CHECK = 'technology-check',
  TESTIMONIAL = 'testimonial',
  TWO_COLUMN_LIST = 'two-column-list',
  TWO_COLUMN_TEXT = 'two-column-text',
  TWO_COLUMN_TEXT_ACTION = 'two-column-text-action',
  TWO_COLUMN_TEXT_LIST = 'two-column-text-list',
}

export interface LinkProps {
  url: string;
  target?: string;
  title?: string;
  className?: string;
  e2e?: string;
  pageId?: number; // from typo but not in use
}

export interface BaseLinkProps extends LinkProps {
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  className?: string;
  disabled?: boolean;
  icon?: ReactElement<IconProps>;
  underlined?: boolean;
  appearance?: TextAppearances;
  color?: 'blue' | 'white' | 'black' | 'darkGrey';
  e2e?: string;
  noEventBubbling?: boolean;
  // props from gk
  ariaCurrent?: 'page' | false;
  linkLocation?: LinkLocation;
  contactTrack?: string;
}

export const BaseLink: FC<BaseLinkProps> = ({
  url,
  onClick,
  className,
  disabled = false,
  underlined = false,
  icon,
  color = 'blue',
  appearance = 't5',
  children,
  pageId, // eslint-disable-line @typescript-eslint/no-unused-vars
  contactTrack,
  linkLocation,
  ariaCurrent,
  e2e,
  noEventBubbling = false,
  ...rest
}) => {
  const _onClick = (e: MouseEvent<HTMLElement>) => {
    if (noEventBubbling) {
      e.stopPropagation();
    }
    if (!disabled) {
      onClick?.(e);
    }
  };
  const props = {
    'data-location': linkLocation,
    'aria-current': ariaCurrent,
    title: rest.title,
    target: rest.target,
    ...((onClick || noEventBubbling) && { onClick: _onClick }),
    ...(e2e && { 'data-e2e': `link-${e2e}` }),
    ...(rest.target && rest.target === '_blank' && { rel: 'noopener' }),
    ...rest,
  };

  if (isInternalLink(url) && !onClick) {
    return (
      <NextLink
        passHref
        href={formatUrl(url, contactTrack)}
        className={cx(className, styles.link, styles[color], {
          [styles.disabled]: disabled,
          [styles.underlined]: underlined,
        })}
        {...props}
      >
        {icon && <span className={styles.icon}>{icon}</span>}
        <Text appearance={appearance} color="inherit">
          <span className={styles.innerText}>{children}</span>
        </Text>
      </NextLink>
    );
  }

  return (
    <a
      href={url}
      className={cx(className, styles.link, styles[color], {
        [styles.disabled]: disabled,
        [styles.underlined]: underlined,
      })}
      {...props}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <Text appearance={appearance} color="inherit">
        <span className={styles.innerText}>{children}</span>
      </Text>
    </a>
  );
};
