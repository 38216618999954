import { FC } from 'react';
import {
  Grid,
  Text,
  Spacer,
  Background,
  RichText,
  TextElements,
} from 'dss-ui-library';
import { ButtonLink, ButtonLinkProps } from '../../components';
import styles from './TwoColumns.module.scss';
import { PartnerWidgetProps } from '../../components';
import HeyFlow, {
  HEYFLOW_WIDGET,
} from '../../components/PartnerWidget/HeyFlow';

interface ButtonList {
  button: ButtonLinkProps;
  partnerWidget?: PartnerWidgetProps['widget'];
  partnerWidgetParam?: PartnerWidgetProps['widgetParam'];
}

export interface TwoColumnProps {
  text1?: string;
  text2?: string;
  buttonList?: ButtonList[];
  backgroundColor?: 'white' | 'superlight-grey';
  headline?: string;
  headlineElement?: TextElements;
}

export const TwoColumn: FC<TwoColumnProps> = ({
  text1,
  text2,
  buttonList,
  backgroundColor = 'white',
  headline,
  headlineElement = 'h2',
}) => {
  return (
    <Background
      color={backgroundColor === 'superlight-grey' ? 'grey' : backgroundColor}
    >
      <Grid>
        {headline && (
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t1_2" color="blue" element={headlineElement}>
                {headline}
              </Text>
              <Spacer b={3} block />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column m={5} show hideM>
            {text1 && (
              <>
                <RichText text={text1} />
                <Spacer t={3} block />
              </>
            )}
          </Grid.Column>
          <Grid.Column m={5} hide showM>
            {text1 && <RichText text={text1} />}
          </Grid.Column>
          <Grid.Column m={5} offsetStartM={7}>
            {text2 && <RichText text={text2} />}

            {buttonList && (
              <Spacer t={3} block>
                <div className={styles.buttonListWrapper}>
                  {buttonList.map(
                    ({ button, partnerWidget, partnerWidgetParam }, index) => (
                      <div
                        className={styles.buttonItem}
                        key={`${index}-${button.link}`}
                      >
                        {partnerWidget === HEYFLOW_WIDGET ? (
                          <HeyFlow
                            widgetParam={partnerWidgetParam}
                            button={
                              <ButtonLink
                                {...button}
                                link={{
                                  url: `#heyflow-${partnerWidgetParam}`,
                                }}
                              >
                                {button.text}
                              </ButtonLink>
                            }
                            backgroundColor={
                              backgroundColor === 'superlight-grey'
                                ? 'grey'
                                : backgroundColor
                            }
                          />
                        ) : (
                          <ButtonLink {...button}>{button.text}</ButtonLink>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </Spacer>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Background>
  );
};

export default TwoColumn;
