import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { BREAKPOINTS, BreakpointKey } from 'dss-ui-library';

interface BreakpointState {
  breakpoint: BreakpointKey;
  update: (newBreakpoint: BreakpointKey) => void;
}

export const useBreakpointStore = create<BreakpointState>()((set) => ({
  breakpoint: 'xs',
  update: (newBreakpoint) => set(() => ({ breakpoint: newBreakpoint })),
}));

/**
 * Updates the global breakpoint state on init and window resize.
 * Should only be used at the root level of the project.
 */
export const useInitBreakpointStore = () => {
  const setBreakpoint = useBreakpointStore((state) => state.update);

  useEffect(() => {
    const update = () => {
      const width = window.innerWidth;
      if (width >= BREAKPOINTS.xl) return setBreakpoint('xl');
      if (width >= BREAKPOINTS.l) return setBreakpoint('l');
      if (width >= BREAKPOINTS.m) return setBreakpoint('m');
      if (width >= BREAKPOINTS.s) return setBreakpoint('s');
      return setBreakpoint('xs');
    };

    update();
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);
};

export const useBreakpoint = () => {
  const { breakpoint } = useBreakpointStore();

  /**
   * Compare a breakpoint to the current state.
   * @param {string} method Compare method (isSmaller | isLarger | isEqual)
   * @param {string} comparedBreakpoint Breakpoint to compare with
   */
  const compare = useCallback(
    (
      method: 'isSmaller' | 'isLarger' | 'isEqual',
      comparedBreakpoint: BreakpointKey,
    ): boolean => {
      switch (method) {
        case 'isSmaller':
          return BREAKPOINTS[comparedBreakpoint] > BREAKPOINTS[breakpoint];
        case 'isLarger':
          return BREAKPOINTS[comparedBreakpoint] < BREAKPOINTS[breakpoint];
        case 'isEqual':
          return BREAKPOINTS[comparedBreakpoint] === BREAKPOINTS[breakpoint];
      }
    },
    [breakpoint],
  );

  return { breakpoint, compare };
};
