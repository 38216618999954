import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr/immutable';
import {
  ACTION_CODES_MKW,
  isNC,
  Mandant,
  useSalesAttribution,
} from '@ncs-frontend-monorepo/utils';
import {
  DEFAULT_CLASSIC_TECHNOLOGY,
  DEFAULT_FIBER_TECHNOLOGY,
  isFiberPlan,
  OrderPromotion,
  Portfolio,
  Technology,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import {
  ProductId,
  TemplateGroup,
  Product,
  getProducts,
  DynamicPlan,
  FTTH_FACTORY_GROUPS,
  useOrder,
  ProductModifiers,
} from '../../';

interface ProductParams {
  templateId: ProductId;
  technology: Technology;
  zipCode?: string;
  mandant?: Mandant;
  pubId?: string;
}

export const usePlans = ({
  plan,
  dynamicPlan,
  modifier,
  skipUpdate = false,
  portfolio = 'FIBER',
}: {
  plan: Product;
  dynamicPlan?: DynamicPlan;
  modifier?: ProductModifiers;
  skipUpdate?: boolean;
  portfolio?: Portfolio;
}) => {
  const [productParams, setProductParams] = useState<ProductParams>();
  const { isCustomer } = useOrder();
  const { address, availability } = useAvailability();
  const [usedPlan, setUsedPlan] = useState(plan);
  const { data } = useSWR(
    () => (productParams ? productParams : null),
    getProducts,
  );
  const { pubId } = useSalesAttribution();
  const mandant = isNC() ? 'NC' : 'NA';
  const technologyDefault =
    portfolio === 'FIBER'
      ? DEFAULT_FIBER_TECHNOLOGY
      : DEFAULT_CLASSIC_TECHNOLOGY;
  const isTvCableNewCustomer =
    plan.group === TemplateGroup.TV_CABLE && plan.id === ProductId.TV_CABLE;

  const updateProductParams = useCallback(
    (productId: ProductId) => {
      setProductParams({
        templateId: productId,
        mandant,
        zipCode: '',
        technology: technologyDefault,
        pubId,
      });
    },
    [mandant, technologyDefault, pubId],
  );

  const getModifiedProduct = (promotions: OrderPromotion[]) => {
    let modifiedPlan = plan;
    if (
      modifier &&
      modifier !== ProductModifiers.withoutModifier &&
      dynamicPlan?.[modifier]
    ) {
      modifiedPlan = dynamicPlan[modifier];
    }

    if (skipUpdate && FTTH_FACTORY_GROUPS.includes(plan.group)) {
      // if it's a FTTH plan, set houseConnectionPrice to the value of the a-check
      modifiedPlan.houseConnection = {
        pricing: {
          onetime: {
            price:
              typeof availability.ftthPresalesInformation
                ?.houseConnectionPrice == 'number'
                ? availability.ftthPresalesInformation?.houseConnectionPrice
                : null,
            discountedPrices: [],
            discounts: [],
          },
        },
      };
      return setUsedPlan(modifiedPlan);
    }

    if (
      !availability.address ||
      skipUpdate ||
      !promotions ||
      promotions?.length === 0
    ) {
      return pubId
        ? updateProductParams(modifiedPlan.id)
        : setUsedPlan(modifiedPlan);
    }
    // get specific promotion
    let technology =
      promotions
        .sort((a, b) => (a.maxDownload < b.maxDownload ? -1 : 1))
        .filter(
          (promotion) =>
            promotion.maxDownload >= Number(usedPlan.internet?.download),
        )?.[0]?.technology || null;

    switch (true) {
      case technology === null:
        return pubId
          ? updateProductParams(modifiedPlan.id)
          : setUsedPlan(modifiedPlan);
      case portfolio === 'FIBER' && !isFiberPlan(technology):
        technology = DEFAULT_FIBER_TECHNOLOGY;
        break;
      case portfolio === 'CLASSIC' && isFiberPlan(technology):
        technology = DEFAULT_CLASSIC_TECHNOLOGY;
        break;
      default:
        break;
    }

    setProductParams({
      templateId: modifiedPlan.id,
      technology,
      pubId,
      zipCode: address?.zipCode,
      mandant: address?.mandant,
    });
  };

  const checkoutActionCode = useMemo(() => {
    const isExistingTVCableCustomer =
      plan.id === ProductId.TV_CABLE &&
      usedPlan.id === ProductId.TV_CABLE_EXISTING_CUSTOMER;
    const isExistingGWGNeussCustomer =
      plan.id === ProductId.TV_CABLE_GWG_NEUSS && !isTvCableNewCustomer;

    if (isExistingTVCableCustomer || isExistingGWGNeussCustomer) {
      return ACTION_CODES_MKW.TV_CABLE;
    }
    return '';
  }, [plan.id, usedPlan.id, isTvCableNewCustomer]);

  const promotionsJSON = JSON.stringify(availability.promotions?.order); // for dep comparison
  useEffect(() => {
    if (!isTvCableNewCustomer) {
      const orderPromotions: OrderPromotion[] = promotionsJSON
        ? JSON.parse(promotionsJSON)
        : [];

      getModifiedProduct(orderPromotions);
    }
  }, [modifier, promotionsJSON, isTvCableNewCustomer]);

  const getTemplateIdCable = useCallback(() => {
    if (isCustomer && usedPlan.id === ProductId.TV_CABLE_GWG_NEUSS)
      return ProductId.TV_CABLE_GWG_NEUSS;

    if (isCustomer) return ProductId.TV_CABLE_EXISTING_CUSTOMER;

    return ProductId.TV_CABLE;
  }, [usedPlan.id, isCustomer]);

  useEffect(() => {
    if (isTvCableNewCustomer) {
      updateProductParams?.(getTemplateIdCable());
    }
  }, [isCustomer, plan.group, plan.id, isTvCableNewCustomer]);

  useEffect(() => {
    if (data) {
      setUsedPlan(data);
    }
  }, [data]);

  useEffect(() => {
    pubId &&
      updateProductParams(
        isTvCableNewCustomer ? getTemplateIdCable() : plan.id,
      );
  }, [pubId, plan.id, updateProductParams, isTvCableNewCustomer]);

  return {
    usedPlan,
    initialPlanId: plan.id,
    checkoutActionCode,
  };
};
