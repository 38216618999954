export enum FormState {
  INITAL = 'INITAL',
  WAITING = 'WAITING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export type FormError = null | {
  code: number;
  message: string;
};
