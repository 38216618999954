import {
  FC,
  ReactNode,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './SlideTransition.module.scss';

export interface SlideTransitionProps {
  index: number;
  scrollTopAfterChange?: boolean;
  children?: ReactNode;
}

export const childFactoryCreator = (props: any) => {
  return (child: React.ReactElement<any>) => cloneElement(child, props);
};

export const SlideTransition: FC<SlideTransitionProps> = ({
  children,
  index,
  scrollTopAfterChange = true,
}) => {
  const lastIndex = useRef(index);

  useEffect(() => {
    lastIndex.current = index;
  }, [index]);

  const scrollTop = useCallback(() => {
    if (scrollTopAfterChange && typeof window !== 'undefined') {
      window.scroll(0, 0);
    }
  }, [scrollTopAfterChange]);

  return (
    <TransitionGroup
      className={styles.transitionWrapper}
      childFactory={(child) => {
        let direction;
        if (index > lastIndex.current) {
          direction = 'left';
        } else if (index < lastIndex.current) {
          direction = 'right';
        }
        return childFactoryCreator({
          classNames: direction,
        })(child);
      }}
    >
      <CSSTransition
        key={index}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 800,
          exit: 400,
        }}
        onExited={scrollTop}
      >
        <div className={styles.transitionElement}>{children}</div>
      </CSSTransition>
    </TransitionGroup>
  );
};
