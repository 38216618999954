import { CustomWindow } from '../interfaces';
declare let window: CustomWindow;

export const addObjectToDataLayer = (obj: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('datalayerpush', obj);
  }

  if (window.dataLayer) {
    window.dataLayer.push(obj);
  }
};
