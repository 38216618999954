export type DynamicTextContext = Record<string, unknown>;

/**
 * Replaces dynamic text in a string with the given context.
 * @example replaceDynamicText('Hello {{name || World}}', { name: 'John' }) // Hello John
 * @param source - The source string
 * @param context - The context object
 */
export function replaceDynamicText(
  source = '',
  context: DynamicTextContext = {},
): string {
  return source.replace(
    /\{\{\s?([\w.]+)\s?(?:\|\|\s?([^}]+)\s?)?\}\}/g,
    (match, key, fallback = '') => {
      const value = key
        .trim()
        .split('.')
        .reduce((obj: any, index: string) => obj?.[index], context);
      return value ? String(value).trim() : fallback.trim();
    },
  );
}

/**
 * Replaces dynamic texts recursively in an object with the given context.
 * @param source - The source object
 * @param context - The context object
 * @param attributes - The attributes to deeply check for dynamic texts
 */
export function replaceDynamicTexts<T>(
  source: T,
  context: DynamicTextContext = {},
  attributes: string[] = ['headline', 'text', 'items', 'list'],
): T {
  if (typeof source === 'string') {
    return replaceDynamicText(source, context) as unknown as T;
  } else if (Array.isArray(source)) {
    return source.map((item) =>
      replaceDynamicTexts(item, context),
    ) as unknown as T;
  } else if (source !== null && typeof source === 'object') {
    const result = {} as T;
    Object.keys(source).forEach((key) => {
      const value = (source as any)[key];
      result[key as keyof T] = attributes.includes(key)
        ? replaceDynamicTexts(value, context)
        : value;
    });
    return result;
  }

  return source;
}
