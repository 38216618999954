import {
  SpacerProps,
  TextProps,
  InformationBarProps,
  FootnoteListProps,
} from 'dss-ui-library';
import { FC, lazy, Suspense } from 'react';
import { BannerVariant } from '../../contentModules';
import {
  ContentType,
  DynamicContentProps,
  DynamicContentType,
} from '../../interfaces';
import styles from './DynamicContent.module.scss';
import { BusinessUnit } from '@ncs-frontend-monorepo/utils';

const Accordion = lazy(() => import('../../contentModules/Accordion'));

const Video = lazy(() => import('../../contentModules/Video'));

const ContentSimpleImageSlider = lazy(
  () => import('../../contentModules/SimpleImageSlider'),
);
const ContentSlider = lazy(() => import('../../contentModules/Slider'));
const ContentText = lazy(() => import('../../contentModules/Text'));

const ContentPageTitle = lazy(() => import('../../contentModules/PageTitle'));
const ContentImage = lazy(() => import('../../contentModules/Image'));
const ContentTilesGroup = lazy(() => import('../../contentModules/TilesGroup'));
const ContentContactTilesGroup = lazy(
  () => import('../../contentModules/ContactTilesGroup'),
);
const ContentCard = lazy(() => import('../../contentModules/Card'));
const ContentIconImageTeaser = lazy(
  () => import('../../contentModules/IconImageTeaser'),
);
const InformationBar = lazy<FC<InformationBarProps>>(() =>
  import('dss-ui-library').then(({ InformationBar }) => ({
    default: InformationBar,
  })),
);
const FootnoteList = lazy<FC<FootnoteListProps>>(() =>
  import('dss-ui-library').then(({ FootnoteList }) => ({
    default: FootnoteList,
  })),
);
const Grid = lazy(() =>
  import('dss-ui-library').then(({ Grid }) => ({ default: Grid })),
);
const Text = lazy<FC<TextProps>>(() =>
  import('dss-ui-library').then(({ Text }) => ({ default: Text })),
);
const RemoteIp = lazy(() => import('../../contentModules/RemoteIp'));
const Spacer = lazy<FC<SpacerProps>>(() =>
  import('dss-ui-library').then(({ Spacer }) => ({ default: Spacer })),
);
const TwoColumn = lazy(() => import('../../contentModules/TwoColumn'));
const MixedContent = lazy(() => import('../../contentModules/MixedContent'));
const ComparisonTableContent = lazy(
  () => import('../../contentModules/ComparisonTable'),
);

const Banner = lazy(() => import('../../contentModules/Banner'));
const Counter = lazy(() => import('../../contentModules/Counter'));
const Testimonial = lazy(() => import('../../contentModules/Testimonial'));
const BackLink = lazy(() => import('../../contentModules/BackLink'));
const LinkList = lazy(() => import('../../contentModules/LinkList'));
const ChannelList = lazy(() => import('../../contentModules/ChannelList'));
const TabSliderContent = lazy(() => import('../../contentModules/TabSlider'));

const GetContentModule: FC<
  DynamicContentType & {
    withTopSpacing?: DynamicContentProps['withTopSpacing'];
    spacerSize?: DynamicContentProps['spacerSize'];
    getElementId?: DynamicContentProps['getElementId'];
    businessUnit?: BusinessUnit;
  }
> = ({
  withTopSpacing = false,
  spacerSize = 5,
  getElementId,
  businessUnit = 'PK',
  ...contentModule
}) => {
  let component: JSX.Element;
  let hasTopSpacing = withTopSpacing;
  let hasOverlap = false;

  switch (contentModule.component) {
    case ContentType.VIDEO:
      component = <Video {...contentModule.props} />;
      break;
    case ContentType.ACCORDION:
      component = <Accordion {...contentModule.props} />;
      break;
    case ContentType.SLIDER:
      component = <ContentSlider {...contentModule.props} />;
      hasTopSpacing = false;
      break;
    case ContentType.SIMPLEIMAGESLIDER:
      component = <ContentSimpleImageSlider {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    case ContentType.TEXT:
      component = <ContentText {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.PAGETITLE:
      component = <ContentPageTitle {...contentModule.props} />;
      break;
    case ContentType.IMAGE:
      component = <ContentImage {...contentModule.props} />;
      break;
    case ContentType.TILESGROUP:
      component = <ContentTilesGroup {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.CONTACTTILESGROUP:
      component = <ContentContactTilesGroup {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.CARD:
      component = <ContentCard {...contentModule.props} />;
      break;
    case ContentType.ICONIMAGETEASER:
      component = <ContentIconImageTeaser {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.FOOTNOTELIST:
      component = <FootnoteList {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    case ContentType.TWOCOLUMN:
      component = <TwoColumn {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.MIXEDCONTENT:
      component = <MixedContent {...contentModule.props} />;
      break;
    case ContentType.COMPARISONTABLE:
      component = <ComparisonTableContent {...contentModule.props} />;
      break;
    case ContentType.BANNER:
      component = <Banner {...contentModule.props} />;
      hasTopSpacing = false;
      hasOverlap =
        contentModule.props?.variant === BannerVariant.Overlap || false;
      break;
    case ContentType.BACKLINK:
      component = <BackLink {...contentModule.props} />;
      spacerSize = 2;
      hasTopSpacing = true;
      break;
    case ContentType.LINKLIST:
      component = <LinkList {...contentModule.props} />;
      hasTopSpacing = !(
        contentModule.props.backgroundColor &&
        contentModule.props.backgroundColor !== 'white'
      );
      break;
    case ContentType.COUNTER:
      component = <Counter {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    case ContentType.TESTIMONIAL:
      component = <Testimonial {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    case ContentType.INFORMATIONBAR:
      component = <InformationBar {...contentModule.props} />;
      hasTopSpacing = false;
      spacerSize = 0;
      break;
    case ContentType.REMOTEIP:
      component = <RemoteIp {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    case ContentType.CHANNELLIST:
      component = (
        <ChannelList {...contentModule.props} businessUnit={businessUnit} />
      );
      hasTopSpacing = true;
      break;
    case ContentType.TAB_SLIDER:
      component = <TabSliderContent {...contentModule.props} />;
      hasTopSpacing = true;
      break;
    default:
      component = (
        <Grid>
          <Text appearance="t2_2">
            Content {contentModule['component']} not found!
          </Text>
        </Grid>
      );
      break;
  }
  return (
    <Suspense>
      <Spacer
        id={getElementId ? getElementId(contentModule.id) : undefined}
        b={spacerSize}
        t={(withTopSpacing && hasTopSpacing && spacerSize) || 0}
        block
        className={hasOverlap ? styles.overlap : ''}
      >
        {component}
      </Spacer>
    </Suspense>
  );
};

export const DynamicContent: FC<DynamicContentProps> = ({
  content,
  withTopSpacing = false,
  spacerSize = 5,
  getElementId,
  businessUnit,
}) => {
  const contentModules = Array.isArray(content) ? content : [content];
  return (
    <div>
      {contentModules.map((module, i) => (
        <GetContentModule
          key={module.id + module.component}
          {...module}
          withTopSpacing={i === 0 && withTopSpacing}
          spacerSize={spacerSize}
          getElementId={getElementId}
          businessUnit={businessUnit}
        />
      ))}
    </div>
  );
};
