import { FC } from 'react';
import {
  ImageProps,
  Background,
  BackgroundProps,
  Grid,
  Spacer,
  Teaser,
  Text,
  ListIcon,
} from 'dss-ui-library';
import { PlainLink, LinkProps } from '../../components/Link';
import styles from './IconImageTeaser.module.scss';

export interface IconImageTeaserProps {
  headline?: string;
  text?: string;
  items: IconImageTeaserItem[];
  backgroundColor: BackgroundProps['color'];
}

interface IconImageTeaserItem {
  headline: string;
  link: LinkProps & { text?: string };
  linktext?: string;
  subline?: string;
  testColor?: number;
  image?: ImageProps & { title?: string; width?: number; height?: number };
  icon: number;
}

export const IconImageTeaser: FC<IconImageTeaserProps> = ({
  headline,
  text,
  items = [],
  backgroundColor,
}) => {
  const teaserColor = backgroundColor === 'white' ? 'white' : 'blue';
  return (
    <Background color={backgroundColor}>
      <Grid className={styles.iconImageTeaser}>
        {headline && (
          <Grid.Row>
            <Grid.Column>
              <Spacer b={3}>
                <Text
                  appearance="t1_2"
                  color={backgroundColor === 'blue' ? 'white' : 'blue'}
                  element="h2"
                >
                  {headline}
                </Text>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}
        {text && (
          <Grid.Row>
            <Grid.Column>
              <Spacer b={3}>
                <Text appearance="t4">{text}</Text>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={styles.iconImageTeaserWrapper}>
          {items.map((item, index) => {
            return (
              <Grid.Column
                s={3}
                m={3}
                key={`teaser-${index}-${item.linktext}`}
                marginBottom={3}
              >
                <PlainLink {...item.link}>
                  <Teaser
                    imageURL={item?.image?.src}
                    key={`imageteaser-${index}`}
                    e2e={`imageteaser-${index}`}
                    background={teaserColor}
                    icon={
                      <div className={styles.iconImageTeaserIconWrapper}>
                        <ListIcon
                          iconType={item.icon}
                          iconColor={teaserColor}
                          size="inherit"
                        />
                      </div>
                    }
                    title={item.headline}
                    subTitle={item.subline}
                    centered
                  />
                </PlainLink>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </Background>
  );
};
