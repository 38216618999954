import { PLANNED_FTTH_STATUS } from '../../constants';
import { LandlinePromotion, ObjectInformation } from '../../interfaces';
import { Technology } from '../../template';
import { hasTechnologies } from '.././hasTechnologies';

export function checkIfFTTHIsPlanned(
  objectInformation: ObjectInformation | null,
  availablePromotions: LandlinePromotion[] | null,
  presalePromotion: LandlinePromotion | null,
) {
  if (
    hasTechnologies({
      technologies: [
        Technology.GFAST,
        Technology.FTTH,
        Technology.FTTH_BSA_L2,
        Technology.FTTH_BSA_L2_DG,
      ],
      availablePromotions,
      presalePromotion,
    })
  ) {
    return false;
  }
  return (
    objectInformation?.status &&
    PLANNED_FTTH_STATUS.includes(objectInformation.status)
  );
}
