import {
  Animation,
  BaseLinkProps as DSSBaseLinkProps,
  withHover,
} from 'dss-ui-library';
import { FC } from 'react';
import { BaseLink, BaseLinkProps } from '../BaseLink';

export const Link: FC<BaseLinkProps & Omit<DSSBaseLinkProps, 'href'>> =
  withHover(({ children, icon, hover, ...props }) => (
    <BaseLink
      {...props}
      icon={
        icon && (
          <Animation.Scale in={hover} to={1.1}>
            {icon}
          </Animation.Scale>
        )
      }
    >
      {children}
    </BaseLink>
  ));
