import { createContext, FC, ReactNode, useContext } from 'react';
import { TelljaWidgets } from '../PartnerWidget/Tellja';

type TelljaWidget = {
  [key in TelljaWidgets]?: string;
};

export interface ContentContext {
  children?: ReactNode;
  site: 'NetCologne' | 'NetAachen';
  partnerWidgets: {
    tellja?: TelljaWidget;
  };
}

export const initialState: ContentContext = {
  site: 'NetCologne',
  partnerWidgets: {},
  children: null,
};

const Context = createContext<ContentContext | null>(null);
Context.displayName = 'ContentContext';

const useContentContext: () => ContentContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useContentContext must be used within a <ContentProvider> tag',
    );
  }
  return contextState;
};

const ContentProvider: FC<ContentContext> = (props) => {
  const value = {
    ...initialState,
    ...props,
  };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export { ContentProvider, useContentContext };
