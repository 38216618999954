import { FC, ReactNode, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import cn from 'classnames';
import styles from './Pricing.module.scss';
import { IconInfo, OfferInfo, Flag, PriceText } from 'dss-ui-library';
import { usePrice } from '../../hooks';
import { Product } from '../../interfaces';

interface PricingProps {
  plan: Product;
  isLongTerm: boolean;
  e2e?: string;
  onTooltipClick?: () => void;
  animateOnPriceChange?: boolean;
  layout?: 'vertical' | 'horizontal';
  priceTextInfo?: string;
  footnote?: ReactNode;
  wordBreakOnMobile?: boolean;
}

export const Pricing: FC<PricingProps> = ({
  plan,
  isLongTerm,
  e2e = '',
  onTooltipClick,
  animateOnPriceChange = true,
  layout = 'horizontal',
  priceTextInfo = 'mtl.*',
  footnote,
  wordBreakOnMobile = true,
}) => {
  const { price, planFlag, priceInfo } = usePrice(
    plan,
    isLongTerm,
    wordBreakOnMobile,
  );
  const nodeRef = useRef(null);
  const handleTooltipClick = () => {
    onTooltipClick?.();
  };

  const flag = planFlag && plan.businessUnit !== 'GK' && (
    <Flag
      className={cn({
        [styles.flagCenter]: layout === 'horizontal',
        [styles.flagVertical]: layout === 'vertical',
      })}
      e2e="offer-info"
    >
      {planFlag}
    </Flag>
  );

  const content = (
    <div
      ref={nodeRef}
      className={cn(styles.content, {
        [styles.vertical]: layout === 'vertical',
      })}
    >
      <div className={styles.container}>
        <div className={styles.priceWrapper}>
          <PriceText
            e2e={`${e2e}-price`}
            price={price}
            info={priceTextInfo}
            footnote={footnote}
          />

          {layout === 'horizontal' && flag}
          {onTooltipClick && (
            <IconInfo
              e2e={`${e2e}-toggle`}
              width={24}
              height={24}
              color="blue"
              onClick={handleTooltipClick}
            />
          )}
        </div>
      </div>
      {priceInfo && (
        <span className={styles.offerInfo}>
          <OfferInfo offerInfo={priceInfo} />
        </span>
      )}
      {layout === 'vertical' && flag}
    </div>
  );

  return animateOnPriceChange ? (
    <SwitchTransition mode="out-in">
      <CSSTransition
        nodeRef={nodeRef}
        key={price}
        timeout={400}
        classNames={{
          enter: styles.priceEnter,
          enterActive: styles.priceEnterActive,
          exit: styles.priceExit,
          exitActive: styles.priceExitActive,
        }}
      >
        {content}
      </CSSTransition>
    </SwitchTransition>
  ) : (
    content
  );
};
