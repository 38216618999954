import { UserManagerSettings } from 'oidc-client';
import { EnvPK, isNC } from '@ncs-frontend-monorepo/utils';

export const getUserManagerSettings = (
  authDomain: EnvPK['NEXT_PUBLIC_AUTH_DOMAIN'],
): UserManagerSettings => ({
  authority: authDomain, //(string): The URL of the OIDC provider.
  client_id: isNC()
    ? process.env.NEXT_PUBLIC_AUTH_CLIENT_ID_NC
    : process.env.NEXT_PUBLIC_AUTH_CLIENT_ID_NA, //(string): Your client application's identifier as registered with the OIDC provider.
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  scope: 'openid', //(string, default: 'openid'): The scope being requested from the OIDC provider.
  response_type: 'id_token', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  metadata: {
    issuer: authDomain,
    jwks_uri: authDomain + '/jwks',
    authorization_endpoint: authDomain + '/authorize',
    token_endpoint: authDomain + '/accessToken',
    userinfo_endpoint: authDomain + '/profile',
    end_session_endpoint: authDomain + '/logout',
    revocation_endpoint: authDomain + '/revoke',
    introspection_endpoint: authDomain + '/introspect',
  },
});
