export enum ProductId {
  NETSPEED_25 = 'netspeed-25',
  NETSPEED_50 = 'netspeed-50',
  NETSPEED_100 = 'netspeed-100',
  NETSPEED_175 = 'netspeed-175',
  NETSPEED_250 = 'netspeed-250',
  NETSPEED_500 = 'netspeed-500',
  NETSPEED_1000 = 'netspeed-1000',
  NETSPEED_YOUNG_25 = 'netspeed-young-25',
  NETSPEED_YOUNG_50 = 'netspeed-young-50',
  NETSPEED_YOUNG_100 = 'netspeed-young-100',
  NETSPEED_YOUNG_175 = 'netspeed-young-175',
  NETSPEED_YOUNG_250 = 'netspeed-young-250',
  NETSPEED_YOUNG_500 = 'netspeed-young-500',
  NETSPEED_YOUNG_1000 = 'netspeed-young-1000',
  NETSPEED_PHONE_25 = 'netspeed-phone-25',
  NETSPEED_PHONE_50 = 'netspeed-phone-50',
  NETSPEED_PHONE_100 = 'netspeed-phone-100',
  NETSPEED_PHONE_175 = 'netspeed-phone-175',
  NETSPEED_PHONE_250 = 'netspeed-phone-250',
  NETSPEED_PHONE_500 = 'netspeed-phone-500',
  NETSPEED_PHONE_1000 = 'netspeed-phone-1000',
  NETSPEED_TV_25 = 'netspeed-tv-25',
  NETSPEED_TV_50 = 'netspeed-tv-50',
  NETSPEED_TV_100 = 'netspeed-tv-100',
  NETSPEED_TV_175 = 'netspeed-tv-175',
  NETSPEED_TV_250 = 'netspeed-tv-250',
  NETSPEED_TV_500 = 'netspeed-tv-500',
  NETSPEED_TV_1000 = 'netspeed-tv-1000',
  NETSPEED_PHONE_TV_25 = 'netspeed-phone-tv-25',
  NETSPEED_PHONE_TV_50 = 'netspeed-phone-tv-50',
  NETSPEED_PHONE_TV_100 = 'netspeed-phone-tv-100',
  NETSPEED_PHONE_TV_175 = 'netspeed-phone-tv-175',
  NETSPEED_PHONE_TV_250 = 'netspeed-phone-tv-250',
  NETSPEED_PHONE_TV_500 = 'netspeed-phone-tv-500',
  NETSPEED_PHONE_TV_1000 = 'netspeed-phone-tv-1000',
  NETSPEED_YOUNG_PHONE_25 = 'netspeed-young-phone-25',
  NETSPEED_YOUNG_PHONE_50 = 'netspeed-young-phone-50',
  NETSPEED_YOUNG_PHONE_100 = 'netspeed-young-phone-100',
  NETSPEED_YOUNG_PHONE_175 = 'netspeed-young-phone-175',
  NETSPEED_YOUNG_PHONE_250 = 'netspeed-young-phone-250',
  NETSPEED_YOUNG_PHONE_500 = 'netspeed-young-phone-500',
  NETSPEED_YOUNG_PHONE_1000 = 'netspeed-young-phone-1000',
  NETSPEED_YOUNG_TV_25 = 'netspeed-young-tv-25',
  NETSPEED_YOUNG_TV_50 = 'netspeed-young-tv-50',
  NETSPEED_YOUNG_TV_100 = 'netspeed-young-tv-100',
  NETSPEED_YOUNG_TV_175 = 'netspeed-young-tv-175',
  NETSPEED_YOUNG_TV_250 = 'netspeed-young-tv-250',
  NETSPEED_YOUNG_TV_500 = 'netspeed-young-tv-500',
  NETSPEED_YOUNG_TV_1000 = 'netspeed-young-tv-1000',
  NETSPEED_YOUNG_PHONE_TV_25 = 'netspeed-young-phone-tv-25',
  NETSPEED_YOUNG_PHONE_TV_50 = 'netspeed-young-phone-tv-50',
  NETSPEED_YOUNG_PHONE_TV_100 = 'netspeed-young-phone-tv-100',
  NETSPEED_YOUNG_PHONE_TV_175 = 'netspeed-young-phone-tv-175',
  NETSPEED_YOUNG_PHONE_TV_250 = 'netspeed-young-phone-tv-250',
  NETSPEED_YOUNG_PHONE_TV_500 = 'netspeed-young-phone-tv-500',
  NETSPEED_YOUNG_PHONE_TV_1000 = 'netspeed-young-phone-tv-1000',
  TV_CABLE = 'tv-cable',
  TV_CABLE_GWG_NEUSS = 'tv-cable-gwg-neuss',
  TV_CABLE_EXISTING_CUSTOMER = 'tv-cable-existing-customers',
  TV_CABLE_HD = 'tv-cable-hd',
  TV_CABLE_PREMIUM_HD = 'tv-cable-premium-hd',
  TV_CABLE_FAMILY_HD = 'tv-cable-family-hd',
  TV_INTERNET = 'tv-internet',
  FTTH_FACTORY_2023_09_50 = 'ftth-factory-2023-09-50',
  FTTH_FACTORY_2023_09_100 = 'ftth-factory-2023-09-100',
  FTTH_FACTORY_2023_09_250 = 'ftth-factory-2023-09-250',
  FTTH_FACTORY_2023_09_500 = 'ftth-factory-2023-09-500',
  FTTH_FACTORY_2023_09_1000 = 'ftth-factory-2023-09-1000',
  FTTH_FACTORY_2023_09_PHONE_50 = 'ftth-factory-2023-09-phone-50',
  FTTH_FACTORY_2023_09_PHONE_100 = 'ftth-factory-2023-09-phone-100',
  FTTH_FACTORY_2023_09_PHONE_250 = 'ftth-factory-2023-09-phone-250',
  FTTH_FACTORY_2023_09_PHONE_500 = 'ftth-factory-2023-09-phone-500',
  FTTH_FACTORY_2023_09_PHONE_1000 = 'ftth-factory-2023-09-phone-1000',
  FTTH_FACTORY_2023_09_TV_50 = 'ftth-factory-2023-09-tv-50',
  FTTH_FACTORY_2023_09_TV_100 = 'ftth-factory-2023-09-tv-100',
  FTTH_FACTORY_2023_09_TV_250 = 'ftth-factory-2023-09-tv-250',
  FTTH_FACTORY_2023_09_TV_500 = 'ftth-factory-2023-09-tv-500',
  FTTH_FACTORY_2023_09_TV_1000 = 'ftth-factory-2023-09-tv-1000',
  FTTH_FACTORY_2023_09_PHONE_TV_50 = 'ftth-factory-2023-09-phone-tv-50',
  FTTH_FACTORY_2023_09_PHONE_TV_100 = 'ftth-factory-2023-09-phone-tv-100',
  FTTH_FACTORY_2023_09_PHONE_TV_250 = 'ftth-factory-2023-09-phone-tv-250',
  FTTH_FACTORY_2023_09_PHONE_TV_500 = 'ftth-factory-2023-09-phone-tv-500',
  FTTH_FACTORY_2023_09_PHONE_TV_1000 = 'ftth-factory-2023-09-phone-tv-1000',
  NETSPEED_TV_CABLE_25 = 'netspeed-tv-cable-25',
  NETSPEED_TV_CABLE_50 = 'netspeed-tv-cable-50',
  NETSPEED_TV_CABLE_100 = 'netspeed-tv-cable-100',
  NETSPEED_TV_CABLE_175 = 'netspeed-tv-cable-175',
  NETSPEED_TV_CABLE_250 = 'netspeed-tv-cable-250',
  NETSPEED_TV_CABLE_500 = 'netspeed-tv-cable-500',
  NETSPEED_TV_CABLE_1000 = 'netspeed-tv-cable-1000',
  NETSPEED_PHONE_TV_CABLE_25 = 'netspeed-phone-tv-cable-25',
  NETSPEED_PHONE_TV_CABLE_50 = 'netspeed-phone-tv-cable-50',
  NETSPEED_PHONE_TV_CABLE_100 = 'netspeed-phone-tv-cable-100',
  NETSPEED_PHONE_TV_CABLE_175 = 'netspeed-phone-tv-cable-175',
  NETSPEED_PHONE_TV_CABLE_250 = 'netspeed-phone-tv-cable-250',
  NETSPEED_PHONE_TV_CABLE_500 = 'netspeed-phone-tv-cable-500',
  NETSPEED_PHONE_TV_CABLE_1000 = 'netspeed-phone-tv-cable-1000',
  FTTH_FACTORY_2024_09_TV_500 = 'ftth-factory-2024-09-tv-500',
  FTTH_FACTORY_2024_09_TV_50 = 'ftth-factory-2024-09-tv-50',
  FTTH_FACTORY_2024_09_TV_250 = 'ftth-factory-2024-09-tv-250',
  FTTH_FACTORY_2024_09_TV_1000 = 'ftth-factory-2024-09-tv-1000',
  FTTH_FACTORY_2024_09_TV_100 = 'ftth-factory-2024-09-tv-100',
  FTTH_FACTORY_2024_09_PHONE_TV_500 = 'ftth-factory-2024-09-phone-tv-500',
  FTTH_FACTORY_2024_09_PHONE_TV_50 = 'ftth-factory-2024-09-phone-tv-50',
  FTTH_FACTORY_2024_09_PHONE_TV_250 = 'ftth-factory-2024-09-phone-tv-250',
  FTTH_FACTORY_2024_09_PHONE_TV_1000 = 'ftth-factory-2024-09-phone-tv-1000',
  FTTH_FACTORY_2024_09_PHONE_TV_100 = 'ftth-factory-2024-09-phone-tv-100',
  FTTH_FACTORY_2024_09_PHONE_500 = 'ftth-factory-2024-09-phone-500',
  FTTH_FACTORY_2024_09_PHONE_50 = 'ftth-factory-2024-09-phone-50',
  FTTH_FACTORY_2024_09_PHONE_250 = 'ftth-factory-2024-09-phone-250',
  FTTH_FACTORY_2024_09_PHONE_1000 = 'ftth-factory-2024-09-phone-1000',
  FTTH_FACTORY_2024_09_PHONE_100 = 'ftth-factory-2024-09-phone-100',
  FTTH_FACTORY_2024_09_500 = 'ftth-factory-2024-09-500',
  FTTH_FACTORY_2024_09_50 = 'ftth-factory-2024-09-50',
  FTTH_FACTORY_2024_09_250 = 'ftth-factory-2024-09-250',
  FTTH_FACTORY_2024_09_1000 = 'ftth-factory-2024-09-1000',
  FTTH_FACTORY_2024_09_100 = 'ftth-factory-2024-09-100',
  PRO_FLEX_25 = 'pro-flex-25',
  PRO_FLEX_50 = 'pro-flex-50',
  PRO_FLEX_100 = 'pro-flex-100',
  PRO_FLEX_175 = 'pro-flex-175',
  PRO_FLEX_250 = 'pro-flex-250',
  PRO_FLEX_500 = 'pro-flex-500',
  PRO_FLEX_1000 = 'pro-flex-1000',
  DOPPEL_FLAT_IP_25 = 'doppel-flat-ip-25',
  DOPPEL_FLAT_IP_50 = 'doppel-flat-ip-50',
  DOPPEL_FLAT_IP_100 = 'doppel-flat-ip-100',
  DOPPEL_FLAT_IP_175 = 'doppel-flat-ip-175',
  DOPPEL_FLAT_IP_250 = 'doppel-flat-ip-250',
  DOPPEL_FLAT_IP_500 = 'doppel-flat-ip-500',
  DOPPEL_FLAT_IP_1000 = 'doppel-flat-ip-1000',
}
