type SizeKey = 'xs' | 's';
type Size = {
  width: number;
  height: number;
};

export const IMAGE_SIZE: { [key in SizeKey]: Size } = {
  xs: { width: 150, height: 150 },
  s: { width: 300, height: 300 },
} as const;
