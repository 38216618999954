export const BUSINESS_UNIT = {
  PK: 'PK',
  GK: 'GK',
  GKandPK: 'GK-PK',
} as const;

export type BusinessUnit = (typeof BUSINESS_UNIT)[keyof typeof BUSINESS_UNIT];

/**
 * Convert a given string to a BusinessUnit
 * @param {string} businessUnit
 * @returns {BusinessUnit}
 */
export function getBusinessUnitFromString(businessUnit?: string): BusinessUnit {
  if (businessUnit === 'GK') return BUSINESS_UNIT.GK;
  return BUSINESS_UNIT.PK;
}
