import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { pushPageView } from '../../utils/dataLayer';

const GoogleTagManager = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pushPageView);
    return function cleanup() {
      router.events.off('routeChangeComplete', pushPageView);
    };
  }, [router.events]);

  return children;
};

export default GoogleTagManager;
