export enum DeviceCategory {
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  BYOD = 'BYOD',
  CI_MODULE = 'CI_MODULE',
  TV_DEVICE_DESCRIPTION = 'TV_DEVICE_DESCRIPTION',
  TV_DEVICE_BYOD_DESCRIPTION = 'TV_DEVICE_BYOD_DESCRIPTION',
  NET_TV_BOX = 'NET_TV_BOX',
  ONT = 'ONT',
}

export enum DeviceOwnership {
  BUY = 'BUY',
  RENT = 'RENT',
}
