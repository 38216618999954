import { LandlinePromotion, OrderPromotion } from '../interfaces';
import { getPortfolio } from './portfolio';

export const getTechnologyDownloads = (
  promotions: LandlinePromotion[] | null,
  presalePromotion: LandlinePromotion | null,
): OrderPromotion[] => [
  ...(presalePromotion
    ? [
        {
          technology: presalePromotion.technology,
          maxDownload: presalePromotion.maxDownload,
          portfolio: getPortfolio([presalePromotion]),
        },
      ]
    : []),
  ...(promotions?.map((promotion) => ({
    technology: promotion.technology,
    maxDownload: promotion.maxDownload,
    portfolio: getPortfolio([promotion]),
  })) || []),
];
