import {
  fiberTechnologies,
  Technology,
} from '@ncs-frontend-monorepo/availability';
import { ProductGroups } from '../constants';
import { Product } from '../interfaces';

// todo update to product.groups.includes(ProductGroups.FIBER)
export const isFiberPlan = (technology: Technology): boolean =>
  fiberTechnologies.includes(technology);

export const isYoungPlan = (groups: Product['groups']): boolean =>
  groups.includes(ProductGroups.YOUNG);

export const isCombiPlan = (groups: Product['groups']): boolean =>
  groups.includes(ProductGroups.COMBINED);
