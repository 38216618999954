export function scrollToAnchor(anchor?: string): boolean {
  let anchorTarget;
  try {
    if (anchor) {
      anchorTarget = document.querySelector(anchor);
    }
  } catch (e) {
    // If the selector is invalid don't do anything
  }

  let didscroll = false;

  if (anchorTarget) {
    anchorTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    didscroll = true;
  }

  return didscroll;
}
