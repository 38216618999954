import {
  AvailabilityStatus,
  ExpansionAccessType,
  ExpansionStatus,
  Fallback,
  GeeStatus,
  NetTvType,
} from '../constants';
import { AvailabilityResponse } from '../interfaces';
import { Technology } from '../template';

export const cableFallback: Omit<AvailabilityResponse, 'address'> = {
  fallback: Fallback.TECHNOLOGY,
  objectInformation: {
    status: ExpansionStatus.CABLE,
    landingpagePk: '',
    landingpageGk: '',
    availabilityDate: null,
    availabilityDateRaw: null,
    availabilityDateHalfYear: null,
    plannedBandwidth: '1000 Mbit/s',
    maxBandwidth: '1000 Mbit/s',
    minBandwidth: 0,
    houseConnectionPrice: 0,
    technicalPromotionBandwidth: 1000,
    geeStatus: GeeStatus.UNBEKANNT,
    geeValidFrom: null,
    ausbauAccessTyp: ExpansionAccessType.UNKNOWN,
  },
  landlinePromotions: [
    {
      technology: Technology.CABLE,
      isNetCologneNetwork: true,
      promotionId: '2-2WRC1GG',
      maxDownload: 1000,
      minDownload: 0,
      availability: AvailabilityStatus.REALIZABLE,
      plannedAvailabilityDateDescription: '',
      priceListName: 'PL PK 2023-09',
      priceListRowId: 'G-0033Y8',
      priority: 0,
    },
  ],
  tvPromotions: [NetTvType.NET_TV_INTERNET],
};
