import { useCallback } from 'react';
import { useOrder } from '..';
import { useAvailability } from '@ncs-frontend-monorepo/availability';
import { useNotConnected } from '../store';

export const useReset = () => {
  const { reset: resetOrder, resetBasket: resetBasketOrder } = useOrder();
  const { resetAvailabilityCheck } = useAvailability();
  const { reset: resetNotConnected, resetBasket: resetBasketNotConnected } =
    useNotConnected();

  const resetAll = useCallback(
    (
      { keepFormData }: { keepFormData?: boolean } = { keepFormData: false },
    ) => {
      resetOrder();
      resetAvailabilityCheck();
      resetNotConnected();
      if (!keepFormData) {
        //todo clear form store WEB-8284
      }
    },
    [resetAvailabilityCheck, resetNotConnected, resetOrder],
  );

  const resetBasket = useCallback(() => {
    resetBasketNotConnected();
    resetBasketOrder();
  }, [resetBasketNotConnected, resetBasketOrder]);

  return {
    resetAll,
    resetBasket,
  };
};
