import { LandlinePromotion } from '../interfaces';
import { Technology } from '../template';

/**
 * Checks if either given `availablePromotions` or `presalePromotion` are part of given `technologies`.
 */
export function hasTechnologies({
  technologies,
  availablePromotions,
  presalePromotion,
}: {
  technologies: Technology[];
  availablePromotions?: LandlinePromotion[] | null;
  presalePromotion?: LandlinePromotion | null;
}): boolean {
  const isInAvailablePromotions = availablePromotions?.some((promotion) =>
    technologies.includes(promotion?.technology),
  );

  const hasPresalePromotion = presalePromotion
    ? technologies.includes(presalePromotion.technology)
    : false;

  return isInAvailablePromotions || hasPresalePromotion;
}
