import {
  Animation,
  DownloadLinkProps,
  IconDownload,
  px2rem,
  withHover,
} from 'dss-ui-library';
import { FC } from 'react';
import { BaseLink, BaseLinkProps } from '../BaseLink';

export const DownloadLink: FC<BaseLinkProps & Omit<DownloadLinkProps, 'href'>> =
  withHover(({ children, hover, indent, ...props }) => (
    <BaseLink
      {...props}
      icon={
        <Animation.Slide in={hover} direction="down" distance={2}>
          <IconDownload
            width={24}
            height={24}
            style={
              indent && {
                marginLeft: px2rem(-6),
              }
            }
          />
        </Animation.Slide>
      }
    >
      {children}
    </BaseLink>
  ));
