import { useNotConnectedStore } from './';
import { useAvailability } from '@ncs-frontend-monorepo/availability';
import { useEffect, useState } from 'react';

export const useNotConnected = () => {
  const store = useNotConnectedStore();
  const [isInitialized, setIsInitialized] = useState(false);
  const { availability } = useAvailability();

  useEffect(() => {
    if (
      store?.info?.address.hausLfdnr === availability.address?.hausLfdnr ||
      !availability.ftthPresalesInformation ||
      !availability.address
    ) {
      return;
    }

    store?.updateInfo({
      address: availability.address,
      ftthPresalesInformation: availability.ftthPresalesInformation,
      inkassoTypeValidFrom:
        availability.objectInformation?.inkassoTypeValidFrom,
    });
  }, [
    availability.address,
    availability.ftthPresalesInformation,
    availability.objectInformation,
    store,
    store?.info?.address.hausLfdnr,
  ]);

  useEffect(() => {
    setIsInitialized(true);
  }, [store]);

  return {
    isInitialized,
    ...store,
  };
};
