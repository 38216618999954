import dynamic from 'next/dynamic';
import React, {
  createContext,
  FC,
  ReactNode,
  Suspense,
  useContext,
  useState,
} from 'react';
import { LoadingSpinner } from 'dss-ui-library';

const Modal = dynamic(
  () => import('dss-ui-library').then(({ Modal }) => Modal),
  {
    ssr: false,
  },
);

interface HandleModalProps {
  content: ReactNode;
  e2e?: string;
}

export interface ModalContext {
  modalContent: ReactNode;
  handleModal: (props: HandleModalProps) => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

const Context = createContext<ModalContext | null>(null);

Context.displayName = 'ModalContext';

const useModalContext: () => ModalContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useModalContext must be used within a <ModalProvider> tag',
    );
  }
  return contextState;
};

const ModalProvider: FC<{ children: ReactNode }> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>();
  const [e2e, setE2e] = useState<string>();

  const handleModal = ({
    content,
    e2e = 'global-modal-wrapper',
  }: HandleModalProps) => {
    setIsModalOpen(true);
    if (content) {
      setModalContent(content);
      setE2e(e2e);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setE2e(null);
  };

  return (
    <Context.Provider
      value={{ handleModal, modalContent, closeModal, isModalOpen }}
    >
      {props.children}
      <Suspense fallback={<LoadingSpinner theme="blue" />}>
        {isModalOpen && (
          <Modal e2e={e2e} open onClose={closeModal}>
            {modalContent}
          </Modal>
        )}
      </Suspense>
    </Context.Provider>
  );
};

export { ModalProvider, useModalContext };
