import { OrderStep } from '../../store';

export enum OrderStepIds {
  ownerRole = 'ownerRole',
  product = 'product',
  options = 'options',
  personal = 'personal',
  installation = 'installation',
  installationTV = 'installationTV',
  providerchange = 'providerchange',
  bank = 'bank',
  pod = 'pod',
  summary = 'summary',
  thankyou = 'thankyou',
}

export const PRODUCT_STEP = {
  id: OrderStepIds.product,
  path: 'tarif',
  title: 'Tarif',
  shortTitle: 'Tarif',
  isFormStep: true,
};

export const OPTIONS_STEP = {
  id: OrderStepIds.options,
  path: 'optionen',
  title: 'Deine Optionen',
  shortTitle: 'Optionen',
  isFormStep: true,
};

export const PERSONAL_STEP = {
  id: OrderStepIds.personal,
  path: 'persoenliche-daten',
  title: 'Deine persönlichen Daten',
  shortTitle: 'Daten',
  isFormStep: true,
};

export const PERSONAL_GK_STEP = {
  id: OrderStepIds.personal,
  path: 'persoenliche-daten',
  title: 'Bestellung',
  isFormStep: true,
};

export const INSTALLATION_STEP = {
  id: OrderStepIds.installation,
  path: 'anschlussdaten',
  title: 'Dein Anschluss',
  shortTitle: 'Anschluss',
  isFormStep: true,
};

export const INSTALLATION_TV_STEP = {
  id: OrderStepIds.installationTV,
  path: 'anschlussdaten',
  title: 'Dein Anschluss',
  shortTitle: 'Anschluss',
  isFormStep: true,
};

export const PROVIDER_CHANGE_STEP = {
  id: OrderStepIds.providerchange,
  path: 'anbieterwechsel',
  title: 'Anbieterwechsel',
  isFormStep: true,
};

export const BANK_STEP = {
  id: OrderStepIds.bank,
  path: 'kontodaten',
  title: 'Deine Kontodaten',
  shortTitle: 'Kontodaten',
  isFormStep: true,
};

const POD_STEP = {
  id: OrderStepIds.pod,
  path: 'gee',
  title: 'Grundstückseigentümererklärung',
  shortTitle: 'GEE',
  isFormStep: true,
};

const SUMMARY_STEP = {
  id: OrderStepIds.summary,
  path: 'bestelluebersicht',
  title: 'Übersicht',
  isFormStep: true,
};

const THANK_YOU_STEP = {
  id: OrderStepIds.thankyou,
  path: 'danke',
  title: 'Danke für Ihre Bestellung',
  isFormStep: false,
};

export const orderStepsPK: OrderStep[] = [
  PERSONAL_STEP,
  INSTALLATION_STEP,
  PROVIDER_CHANGE_STEP,
  BANK_STEP,
  SUMMARY_STEP,
  THANK_YOU_STEP,
];

export const orderStepsGK: OrderStep[] = [
  PERSONAL_GK_STEP,
  SUMMARY_STEP,
  THANK_YOU_STEP,
];

export const cableTVStepsPK: OrderStep[] = [
  PERSONAL_STEP,
  INSTALLATION_TV_STEP,
  BANK_STEP,
  SUMMARY_STEP,
  THANK_YOU_STEP,
];

export const ftthNewCustomerSteps: OrderStep[] = [
  PRODUCT_STEP,
  OPTIONS_STEP,
  PERSONAL_STEP,
  INSTALLATION_STEP,
  PROVIDER_CHANGE_STEP,
  BANK_STEP,
  POD_STEP,
  SUMMARY_STEP,
  THANK_YOU_STEP,
];

export const ftthExistingCustomerSteps: OrderStep[] = [
  PRODUCT_STEP,
  OPTIONS_STEP,
  PERSONAL_STEP,
  INSTALLATION_STEP,
  POD_STEP,
  SUMMARY_STEP,
  THANK_YOU_STEP,
];
