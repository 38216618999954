import {
  BUSINESS_UNIT,
  businessUnitHeader,
  correlationIdHeader,
  fetcher,
  getCorrelationId,
  getEnv,
  requestedDownloadHeader,
  userInteractionHeader,
} from '@ncs-frontend-monorepo/utils';
import {
  Availability,
  ERROR_CODES_NO_AVAILABILITY,
  getFallbackResponse,
  shouldShowFallback,
  Technology,
} from '..';
import { normalizeAvailability } from '../utils/normalizeAvailability';
import { initialAvailability } from '../store/initialValues';

export interface AvailabilityLoggingInfo {
  manualChecked: boolean;
  requestedDownload?: number;
}

export interface CheckAvailabilityParams {
  zipCode: string;
  street: string;
  houseNumber: string;
  city?: string;
  loggingInfo?: AvailabilityLoggingInfo;
}

export async function checkAvailability({
  zipCode,
  street,
  houseNumber,
  city,
  loggingInfo,
}: CheckAvailabilityParams): Promise<Availability> {
  const { manualChecked = false, requestedDownload } = loggingInfo || {};
  const searchParams = new URLSearchParams({
    zipCode,
    street,
    houseNumber,
    ...(city && { city }),
  });

  const headerContent = {
    [correlationIdHeader]: getCorrelationId(),
    [businessUnitHeader]: BUSINESS_UNIT.PK,
    ...(manualChecked && {
      [userInteractionHeader]: 'true',
      ...(requestedDownload && {
        [requestedDownloadHeader]: String(requestedDownload),
      }),
    }),
  };

  try {
    const response = await fetcher(
      `${getEnv().ORDER_SERVICE_URL}/availability?${searchParams}`,
      {
        headers: headerContent,
      },
    );
    return normalizeAvailability(response);

    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    if (
      typeof error?.cause !== 'number' ||
      ERROR_CODES_NO_AVAILABILITY.includes(error.cause) ||
      !shouldShowFallback({ zipCode })
    ) {
      return initialAvailability;
    }

    const fallback = getFallbackResponse(Technology.CABLE, {
      zipCode,
      street,
      houseNumber,
      city: city ?? '',
    });

    return normalizeAvailability(fallback);
  }
}
