import { Fallback, PLANNED_FTTH_STATUS } from '../../constants';
import { LandlinePromotion, ObjectInformation } from '../../interfaces';
import { getPortfolio } from './getPortfolio';
import { hasFtthStatus } from './hasFtthStatus';
import { isFiberPlan } from './checkPlan';
import { isGFASTInPromotions } from '../checkPromotions';

export function createAvailabilityList({
  objectInformation,
  availablePromotions,
  presalePromotion,
  fallback,
}: {
  objectInformation: ObjectInformation;
  availablePromotions?: LandlinePromotion[] | null;
  presalePromotion: LandlinePromotion | null;
  fallback: Fallback | null;
}): string[] {
  const hasFtthDate = objectInformation?.availabilityDate;

  const availablePromotionsLength = availablePromotions?.length ?? 0;

  const hasAvailability =
    fallback !== Fallback.ADSL && availablePromotionsLength > 0;

  const isFiberTechnology =
    availablePromotions?.some(({ technology }) => isFiberPlan(technology)) ||
    false;

  // not available
  if (
    !availablePromotions?.length &&
    !presalePromotion &&
    !PLANNED_FTTH_STATUS.includes(objectInformation?.status)
  )
    return ['NOT_AVAILABLE'];

  // adsl
  if (
    fallback === Fallback.ADSL &&
    !(
      PLANNED_FTTH_STATUS.includes(objectInformation?.status) ||
      presalePromotion
    )
  )
    return ['ADSL'];

  // presale
  if (presalePromotion)
    return [
      getPortfolio([presalePromotion]),
      ...(hasAvailability ? ['AVAILABLE'] : []),
      ...(isGFASTInPromotions([presalePromotion]) ? ['GFAST'] : []),
      'PRESALE',
    ];

  return [
    ...(hasAvailability ? [getPortfolio(availablePromotions)] : []),
    ...(hasAvailability ? ['AVAILABLE'] : []),
    ...(hasFtthStatus({
      expansionStatus: objectInformation?.status,
      isFiberTechnology,
    })
      ? [
          hasFtthDate
            ? `${objectInformation.status}_DATE`
            : objectInformation.status,
        ]
      : []),
  ];
}
