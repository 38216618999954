const MAINTENANCE_MODES = ['enabled', 'disabled'] as const;
export type MaintenanceMode = (typeof MAINTENANCE_MODES)[number];

/**
 * return value if it is a proper MaintenanceMode or fallback to "disabled"
 *
 * @param value
 * @returns MaintenanceMode
 */
export const getMaintenanceModeValue = (value: string): MaintenanceMode =>
  MAINTENANCE_MODES.includes(value as MaintenanceMode)
    ? (value as MaintenanceMode)
    : 'disabled';
