export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  AVAILABLE_INSTALL_GFAST = 'AVAILABLE_INSTALL_GFAST',
  PRESALE = 'PRESALE',
  REALIZABLE = 'REALIZABLE',
  REALIZABLE_INSTALL_GFAST = 'REALIZABLE_INSTALL_GFAST',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum AccessType {
  CABLE = 'CABLE',
  FTTH = 'FTTH',
  G_FAST = 'G_FAST',
  VDSL_2_FTTB = 'VDSL_2_FTTB',
  FTTH_BSA_L2 = 'FTTH_BSA_L2',
  FTTH_BSA_L2_DG = 'FTTH_BSA_L2_DG',
  UNKNOWN = 'UNKNOWN',
}

export enum WholeBuyPartnerAccessType {
  FTTH_BSA_L2 = 'FTTH-BSA-L2',
  FTTH_BSA_L2_DG = 'FTTH-BSA-L2-DG',
}

export interface WholeBuy {
  accessType: WholeBuyPartnerAccessType | null;
  eigentuemerdatenErforderlich: boolean;
  partner: WholeBuyPartner | null;
  partnerLabel: string;
}

export enum WholeBuyPartner {
  TCOM = 'TCOM',
  DGF = 'DGF',
}

export enum GeeStatus {
  OFFEN = 'OFFEN',
  VORHANDEN = 'VORHANDEN',
  ERTEILT = 'ERTEILT',
  VERWEIGERT = 'VERWEIGERT',
  UNBEKANNT = 'UNBEKANNT',
}

export enum Fallback {
  NONE = 'NONE',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
  TECHNOLOGY = 'TECHNOLOGY',
  ADSL = 'ADSL',
}

export enum NetTvType {
  NET_TV_APP = 'NET_TV_APP',
  NET_TV_INTERNET = 'NET_TV_INTERNET',
  NET_TV_CABLE = 'NET_TV_CABLE',
  NET_TV_FTTH = 'NET_TV_FTTH',
  NET_TV_CABLE_OPTIONS = 'NET_TV_CABLE_OPTIONS',
  NET_TV_CABLE_GWG_NEUSS = 'NET_TV_CABLE_GWG_NEUSS',
}

// Time window in which presale promotions will be directly booked instead of pre-ordering
export const MIN_PRESALE_BOOKING_WEEKS = 5;
