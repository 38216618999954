import { BusinessUnit, getEnv, isNC } from '@ncs-frontend-monorepo/utils';
import { AddressFields } from '../../components';
import { createAddressParams } from './urlAddress';

export function getMandantUrl(
  address: AddressFields,
  businessUnit: BusinessUnit,
) {
  let path = '';
  if (typeof window !== 'undefined') {
    const prefix = process.env.NEXT_PUBLIC_SITE_PREFIX ?? '';
    path = window.location.pathname.replace(prefix, '');
  }
  const addressParams = createAddressParams(address);
  switch (businessUnit) {
    case 'PK':
      return `${
        isNC()
          ? `${getEnv().PUBLIC_FE_URL_NA}/privatkunden`
          : `${getEnv().PUBLIC_FE_URL_NC}/privatkunden`
      }${path}?${addressParams}`;
    case 'GK':
      return `${
        isNC()
          ? `${getEnv().PUBLIC_FE_URL_NA}/geschaeftskunden`
          : `${getEnv().PUBLIC_FE_URL_NC}/geschaeftskunden`
      }${path}?${addressParams}`;
  }
}
