import {
  FC,
  ReactNode,
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { Sitemap } from '../../interfaces';

type SiteState = {
  sitemap: Sitemap | null;
  currentPageId: number | null;
  path: string;
  menuOpen?: boolean;
};

export interface SiteContext {
  state: SiteState;
  dispatch: Dispatch<SiteAction>;
}

export const initialState: SiteState = {
  sitemap: null,
  currentPageId: null,
  path: '',
  menuOpen: false,
};

export enum SiteActionType {
  FETCH_SITE = 'FETCH_SITE',
  OPEN_MENU = 'OPEN_MENU',
  CLOSE_MENU = 'CLOSE_MENU',
  TOGGLE_MENU = 'TOGGLE_MENU',
}

type SiteAction = {
  type: SiteActionType;
  // eslint-disable-next-line
  payload?: any;
};

const Context = createContext<SiteContext | null>(null);
Context.displayName = 'SiteContext';

const useSiteContext: () => SiteContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useSiteContext must be used within a <SiteProvider> tag');
  }
  return contextState;
};

type SiteProviderProps = {
  value?: SiteState;
  children?: ReactNode;
};

const SiteProvider: FC<SiteProviderProps> = ({ value = null, children }) => {
  const [state, dispatch] = useReducer(
    (state: SiteState, action: SiteAction) => {
      switch (action.type) {
        case SiteActionType.FETCH_SITE:
          return {
            ...state,
            sitemap: action.payload.sitemap,
            currentPageId: action.payload.currentPageId,
            path: action.payload.path,
          };
        case SiteActionType.OPEN_MENU:
          return {
            ...state,
            menuOpen: true,
          };
        case SiteActionType.CLOSE_MENU:
          return {
            ...state,
            menuOpen: false,
          };
        case SiteActionType.TOGGLE_MENU:
          return {
            ...state,
            menuOpen: !state.menuOpen,
          };
        default:
          return state;
      }
    },
    { ...initialState, ...value },
  );
  useEffect(() => {
    if (value?.sitemap || value?.currentPageId || value?.path) {
      dispatch({ type: SiteActionType.FETCH_SITE, payload: value });
    }
  }, [value]);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { SiteProvider, useSiteContext };
