import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from './useCookies';

const DEFAULT_VKZ = 'ECOWBS022';

export const useSalesAttribution = (): {
  setVKZByQuery: () => null | string;
  getSalesAttribution: () => {
    vkz: string;
    pubId?: string;
    viewvkz?: string;
    seovkz?: string;
  } | null;
  pubId: string;
} => {
  const { isReady, query } = useRouter();
  const { setCookie, getCookie } = useCookies();
  const [pubId, setPubId] = useState('');
  const queryPubId = query.pubId as string;

  const setVKZByQuery = useCallback((): null | string => {
    if (isReady && query.vkz) {
      const { aid = '', awc = '', pubId: queryPubId = '' } = query;
      let { vkz } = query;
      if (
        !(vkz as string).match(
          /(BCOUT|CCNESU|CCOUT|CCSEMA|ECOWBS|IVDVIN|IVDVPK|IVFH|IVKPPK|IVPKBK|IVRT|IVSH|NETSEL|PKAM|PKISV|PKKD|VERNNA|VERNNC|VERUNA|VERUNC){1}([a-zA-Z0-9]{3,6})\b/,
        )
      ) {
        if (getCookie('vkz')) {
          return null;
        }
        vkz = DEFAULT_VKZ;
      }
      const stringifiedObject = JSON.stringify({
        vkz,
        aid,
        awc,
        pubId: queryPubId,
      });

      return setCookie({
        name: 'vkz',
        value: stringifiedObject,
        expiresInDays: 30,
      });
    }
    return null;
  }, [isReady]);

  useEffect(() => {
    const { pubId: cookiePubId } = getSalesAttribution();

    if (cookiePubId) {
      setPubId(cookiePubId);
      return;
    }

    if (isReady && queryPubId) {
      setPubId(queryPubId);
    }
  }, [isReady, queryPubId]);

  const getSalesAttribution = () => {
    const vkz = getCookie('vkz');
    const viewvkz = getCookie('viewvkz');
    const seovkz = getCookie('seovkz');

    return {
      vkz: vkz ? JSON.parse(vkz)?.vkz : '',
      pubId: vkz ? JSON.parse(vkz)?.pubId : '',
      viewvkz: viewvkz ? JSON.parse(viewvkz)?.vkz : '',
      seovkz: seovkz ? JSON.parse(seovkz)?.vkz : '',
    };
  };

  return {
    setVKZByQuery,
    getSalesAttribution,
    pubId,
  };
};
