export type ImageOrientation = 'imageRightTextBeside' | 'imageLeftTextBeside';

const SUPPORTED_IMAGE_ORIENTATIONS: ImageOrientation[] = [
  'imageLeftTextBeside',
  'imageRightTextBeside',
];

export function getImageOrientation(
  imageOrientation: ImageOrientation,
): ImageOrientation {
  if (SUPPORTED_IMAGE_ORIENTATIONS.includes(imageOrientation)) {
    return imageOrientation;
  }
  return 'imageLeftTextBeside';
}
