import {
  hasCableOptions,
  hasCableTV,
  NetTvType,
  Promotions,
} from '@ncs-frontend-monorepo/availability';
import { Product, ProductGroups, ProductId } from '../../../';
import { PlanState } from './usePlanState';

export function getTVPlanState({
  plan,
  availableTv,
  fallbackPlanState,
}: {
  plan: Product;
  availableTv: Promotions['tvPromotions'];
  fallbackPlanState: PlanState;
}) {
  if (!availableTv) {
    return fallbackPlanState;
  }

  let isDisabled = false;

  if (plan.id === ProductId.TV_INTERNET) {
    isDisabled = !availableTv.includes(NetTvType.NET_TV_INTERNET);
  } else {
    isDisabled = plan.groups.includes(ProductGroups.TV_STANDALONE)
      ? !hasCableTV(availableTv)
      : !hasCableOptions(availableTv);
  }

  return {
    isDisabled,
    isPresale: false,
    isPlanStateChecked: true,
    portfolioMismatch: false,
    hasNetTVInternet: availableTv.includes(NetTvType.NET_TV_INTERNET),
  };
}
