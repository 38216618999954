import {
  useState,
  useRef,
  useEffect,
  DependencyList,
  RefObject,
  FC,
  memo,
  forwardRef,
} from 'react';
import classNames from 'classnames/bind';
import { listen, pointer, value, spring } from 'popmotion';
import styler from 'stylefire';
import {
  Spacer,
  Text,
  IconChevron,
  IconPlus,
  PriceText,
  Grid,
  RichText,
  ListIcon,
} from 'dss-ui-library';
import {
  TableRowProps,
  PriceColumnsProps,
  PriceRowProps,
  ComparisonTableProps,
} from './interfaces';
import { TextLink, ButtonLink } from '../../components';
import styles from './ComparisonTable.module.scss';

const cx = classNames.bind(styles);

const SPRING_SETTINGS = {
  stiffness: 300,
  damping: 80,
  restDelta: 4,
  restSpeed: 4,
};

function detectMoveDirection({
  onHorizontalMove,
  onVerticalMove,
}: {
  onHorizontalMove?: () => any;
  onVerticalMove?: () => any;
}): void {
  const pointerTracker = pointer({
    preventDefault: false,
    x: 0,
    y: 0,
  }).start(({ x, y }: { x: number; y: number }) => {
    const distance = Math.hypot(x, y);

    const MOVE_THRESHOLD = 6;
    if (distance >= MOVE_THRESHOLD) {
      if (pointerTracker) {
        pointerTracker.stop();
      }

      if (Math.abs(x) > Math.abs(y)) {
        if (onHorizontalMove) {
          onHorizontalMove();
        }
      } else {
        if (onVerticalMove) {
          onVerticalMove();
        }
      }
    }
  });
}

function columnAtOffset(slideOffset: number, columnWidth: number): number {
  // When moving to the next slide the slideOffset goes into the negative but we want to
  // know the progress we made. Hance, we flip the sign.
  return Math.round((slideOffset * -1) / columnWidth);
}

function offsetOfColumn(column: number, columnWidth: number): number {
  return column * columnWidth * -1;
}

function assertNum(val: any): number {
  if (typeof val === 'number') {
    return val;
  }
  throw new Error('Value not a number: ' + val);
}

function isSingleColumnLayout(): boolean {
  return typeof window === 'undefined' || window.innerWidth < 768;
}

function getShownColumns(): number {
  if (isSingleColumnLayout()) {
    return 1;
  }
  if (typeof window === 'undefined' || window.innerWidth < 1440) {
    return 2;
  }
  return 3;
}

/**
 * The index of the last slide that can be active. If we have 4 columns and show 2 columns
 * at a time, the 2nd (zero-based so 3rd) slide would be the last selectable slide.
 *
 * Returns 0 if no sliding is necessary.
 */
function getLastSlideIndex(columnCount: number): number {
  const lastSlideIndex = columnCount - getShownColumns();
  if (lastSlideIndex > 0) {
    return lastSlideIndex;
  }

  // lastSlideIndex can be negative if we have fewer columns then displayed columns.
  // I.e. we have 2 columns but on desktop 3 columns are shown, hence the last slide index is -1
  return 0;
}

/**
 * Clamp between 0 and last possible active column index
 */
function clampColumnIndex(columnIndex: number, columnCount: number) {
  columnIndex = Math.max(0, columnIndex);
  columnIndex = Math.min(getLastSlideIndex(columnCount), columnIndex);

  return columnIndex;
}

function getColumnWidth(bodySc: Element, columnCount: number) {
  const newColumnWidth = isSingleColumnLayout()
    ? bodySc.clientWidth / columnCount
    : bodySc.clientWidth / (columnCount + 1);

  return newColumnWidth;
}

/**
 * Get column index we should snap to
 */
const getColumnIndexToSnapTo = (
  currentOffset: number,
  currentVelocity: number,
  columnWidth: number,
  columnCount: number,
) => {
  // With the current velocity, we would come to a stop at this position. 0.3 is just a magic
  // number here. Just something that felt good. Velocity is in pixels per second. So if we are
  // currently moving with 1800px per second we would keep sliding for another 540px (1800 * 0.3)
  // before we come to a stop.
  const decayPos = currentOffset + currentVelocity * 0.3;

  // The column that is currently active
  const currentActive = columnAtOffset(currentOffset, columnWidth);

  // The element that would be active if the scroll pos decays to the decayPos
  const decayToActive = columnAtOffset(decayPos, columnWidth);

  // How many elements would we skip?
  const decaySkipsColumns = decayToActive - currentActive;

  // We should not skip over more than one element at once. That would feel unnatural.
  let targetColumn = decayToActive;
  if (decaySkipsColumns < -1) {
    targetColumn = currentActive - 1;
  } else if (decaySkipsColumns > 1) {
    targetColumn = currentActive + 1;
  }

  return clampColumnIndex(targetColumn, columnCount);
};

/**
 * Softly clamps a value between lower and upper bounds.
 * It applies a kind of rubberband effect to limit the value between the bounds.
 *
 * This archives an effect similar to the overscroll behavior in iOS and macOS.
 *
 * Inspired by:
 * https://stackoverflow.com/questions/42406064/how-to-implement-the-rubber-band-effect
 */
function rubberBandEffect(
  val: number,
  lowerBounds: number,
  upperBounds: number,
  padding: number,
): number {
  const lowerLimit = lowerBounds - padding;
  const upperLimit = upperBounds + padding;

  if (val < lowerLimit) {
    const offset = Math.abs(val - lowerBounds);
    const newOffset = (Math.log2(offset / padding) + 1) * padding;
    return lowerBounds - newOffset;
  } else if (val > upperLimit) {
    const offset = val - upperBounds;
    const newOffset = (Math.log2(offset / padding) + 1) * padding;
    return upperBounds + newOffset;
  }

  return val;
}

type OnPassCb = (
  side: 'top' | 'bottom',
  scrollDirection: 'upwards' | 'downwards',
) => any;

/**
 * Add IntersectionObservers to find out when elements are sticky
 *
 * Every time the overserved element enters or leaves the viewport the onPass callback
 * is called with the side on which the viewport was passed and the direction that the
 * user was scrolling.
 *
 * See: https://developers.google.com/web/updates/2017/09/sticky-headers
 */
function registerStickyGuard(element: Element, onPass: OnPassCb): () => void {
  if (typeof IntersectionObserver === 'undefined') {
    // If IntersectionObserver is not supported, we just do nothing and accept
    // the sticky elements how they are. Progressive enhancement ftw.
    return () => undefined;
  }

  const observer = new IntersectionObserver(
    (records) => {
      for (const record of records) {
        const targetInfo = record.boundingClientRect;
        // TS seems to have wrong types for this... it's alwasys a DOMRectReadOnly
        const rootBounds = record.rootBounds as DOMRectReadOnly;

        // The element has passed the viewport on the top while scrolling downwards
        if (targetInfo.bottom < rootBounds.top) {
          onPass('top', 'downwards');
        }

        // The element has passed the viewport on the top while scrolling upwards
        else if (
          targetInfo.bottom >= rootBounds.top &&
          targetInfo.bottom < rootBounds.bottom
        ) {
          onPass('top', 'upwards');
        }

        // The element has passed the viewport on the bottom while scrolling downwards
        if (targetInfo.top > rootBounds.bottom) {
          onPass('bottom', 'upwards');
        }

        // The element has passed the viewport on the bottom while scrolling upwards
        else if (
          targetInfo.top <= rootBounds.bottom &&
          targetInfo.top > rootBounds.top
        ) {
          onPass('bottom', 'downwards');
        }
      }
    },
    { threshold: [0] },
  );

  observer.observe(element);

  return () => {
    observer.unobserve(element);
  };
}

function useStickyGuard<T extends HTMLElement>(
  callback: OnPassCb,
  dependencies: DependencyList = [],
): RefObject<T> {
  const stickyGuardRef = useRef<T>(null);
  useEffect(() => {
    if (!stickyGuardRef.current) {
      throw new Error('Illegal state: Refs can not be null here');
    }

    return registerStickyGuard(stickyGuardRef.current, callback);
  }, dependencies);

  return stickyGuardRef;
}

function useSlideControls(columnCount: number) {
  const [activeColumn, setActiveColumn] = useState<number>(0);

  const tableRef = useRef<HTMLTableElement>(null);

  // "Sc" means Slide Container and the element should use the "slideContainer" css class
  const headerScRef = useRef<HTMLDivElement>(null);
  const bodyScRef = useRef<HTMLDivElement>(null);

  // We use a state here to make these "handlers" available outside the useEffect below
  const [goToPrev, setGoToPrev] = useState<() => void>(() => undefined);
  const [goToNext, setGoToNext] = useState<() => void>(() => undefined);
  const [goToNth, setGoToNth] = useState<(nth: number) => void>(
    () => undefined,
  );

  // This effect adds the sliding behavior
  useEffect(() => {
    const table = tableRef.current;
    const headerSc = headerScRef.current;
    const bodySc = bodyScRef.current;

    if (!headerSc || !bodySc || !table) {
      throw new Error('Illegal state: All refs should be used');
    }

    // The currently shown columns
    let currentColumn = activeColumn;

    const headerScStyler = styler(headerSc);
    const bodyScStyler = styler(bodySc);

    // We need a query selector here because we can't useRef N times
    const rowHeaderStylers = Array.from(
      table.querySelectorAll(`.${styles.cellRowHeader}`),
    ).map((el) => styler(el));

    const slideOffset = value(0, (x: number) => {
      const columnWidth = getColumnWidth(bodySc, columnCount);

      const newCurrentColumn = clampColumnIndex(
        columnAtOffset(x, columnWidth),
        columnCount,
      );
      if (newCurrentColumn !== currentColumn) {
        currentColumn = newCurrentColumn;
        setActiveColumn(currentColumn);
      }

      headerScStyler.set({ x });
      bodyScStyler.set({ x });

      // Invert offset on row header to make them stay still
      // Simulates the first column beeing sticky
      rowHeaderStylers.forEach((rowHeaderStyler) => {
        rowHeaderStyler.set({ x: Math.min(x, 0) * -1 });
      });
    });

    const snapTo = (columnIndex: number) => {
      const currentOffset = assertNum(slideOffset.get());
      const currentVelocity = assertNum(slideOffset.getVelocity());
      const columnWidth = getColumnWidth(bodySc, columnCount);

      spring({
        from: currentOffset,
        velocity: currentVelocity,
        to: offsetOfColumn(columnIndex, columnWidth),
        ...SPRING_SETTINGS,
      }).start(slideOffset);
    };

    const startDragging = () => {
      pointer({
        preventDefault: true,
        x: assertNum(slideOffset.get()),
        y: 0,
      })
        .pipe(({ x }: { x: number }) => x)
        .pipe((x: number) => {
          const columnWidth = getColumnWidth(bodySc, columnCount);
          const maxTotal = columnWidth * getLastSlideIndex(columnCount);
          const padding = 25;

          return rubberBandEffect(x, -maxTotal, 0, padding);
        })
        .start(slideOffset);
    };

    const startSnapping = () => {
      const currentOffset = assertNum(slideOffset.get());
      const currentVelocity = assertNum(slideOffset.getVelocity());
      const columnWidth = getColumnWidth(bodySc, columnCount);

      snapTo(
        getColumnIndexToSnapTo(
          currentOffset,
          currentVelocity,
          columnWidth,
          columnCount,
        ),
      );
    };

    const downListener = listen(bodySc, 'touchstart').start(() => {
      detectMoveDirection({
        onHorizontalMove: startDragging,
      });
    });

    const upListener = listen(document, 'touchend').start(() => {
      startSnapping();
    });

    const resizeListener = listen(window, 'resize').start(() => {
      const columnWidth = getColumnWidth(bodySc, columnCount);
      const newOffset = offsetOfColumn(
        clampColumnIndex(currentColumn, columnCount),
        columnWidth,
      );
      slideOffset.update(newOffset);
    });

    setGoToPrev(
      () => () => snapTo(clampColumnIndex(currentColumn - 1, columnCount)),
    );

    setGoToNext(
      () => () => snapTo(clampColumnIndex(currentColumn + 1, columnCount)),
    );

    setGoToNth(
      () => (nth: number) => snapTo(clampColumnIndex(nth, columnCount)),
    );

    return () => {
      slideOffset.stop();
      downListener.stop();
      upListener.stop();
      resizeListener.stop();
    };
  }, [columnCount]);

  return {
    tableRef,
    headerScRef,
    bodyScRef,
    activeColumn,
    goToPrev,
    goToNext,
    goToNth,
  };
}

const ModuleHeadlineText: FC<{
  headline: string;
  text?: string;
}> = memo(({ headline, text }) => (
  <Grid>
    <Text element="h2" appearance="t1_2" color="blue">
      {headline}
    </Text>
    <Spacer b={3} block />
    {text && (
      <>
        <Text element="p" appearance="t4">
          {text}
        </Text>
        <Spacer b={3} block />
      </>
    )}
  </Grid>
));

const Dots: FC<{
  count: number;
  activeColumn: number;
  onDotClick: (index: number) => any;
}> = memo(({ count, activeColumn, onDotClick }) => (
  <div className={cx('dotsGroup')}>
    {[...Array(count)].map((_, index) => (
      <div
        key={index}
        className={cx('dot', { dotActive: activeColumn === index })}
        onClick={() => onDotClick(index)}
      />
    ))}
  </div>
));

const NavButtonPrev: FC<{
  goToPrev: () => any;
  activeColumn: number;
}> = memo(({ goToPrev, activeColumn }) => (
  <button
    className={cx('navButton', 'navButtonPrev')}
    onClick={goToPrev}
    disabled={activeColumn === 0}
  >
    <IconChevron width={24} height={24} rotation="left" />
  </button>
));

const NavButtonNext: FC<{
  goToNext: () => any;
  activeColumn: number;
  lastSlideIndex: number;
}> = memo(({ goToNext, activeColumn, lastSlideIndex }) => (
  <button
    className={cx('navButton', 'navButtonNext')}
    onClick={goToNext}
    disabled={activeColumn === lastSlideIndex}
  >
    <IconChevron width={24} height={24} rotation="right" />
  </button>
));

function tableRowColumns(
  row: TableRowProps,
): { icon?: number; text?: string }[] {
  const columns = [];

  columns.push({
    icon: row.icon1,
    text: row.text1,
  });
  columns.push({
    icon: row.icon2,
    text: row.text2,
  });
  columns.push({
    icon: row.icon3,
    text: row.text3,
  });
  columns.push({
    icon: row.icon4,
    text: row.text4,
  });
  columns.push({
    icon: row.icon5,
    text: row.text5,
  });
  columns.push({
    icon: row.icon6,
    text: row.text6,
  });
  columns.push({
    icon: row.icon7,
    text: row.text7,
  });
  columns.push({
    icon: row.icon8,
    text: row.text8,
  });

  return columns;
}

const TableContent = memo(
  forwardRef<HTMLTableElement, ComparisonTableProps>(
    ({ tableHead, rows, priceRow, actionRow }, ref) => {
      const columnCount = tableHead.length;

      return (
        <table ref={ref} className={cx('table')}>
          <thead className={cx('tableHead')}>
            <tr className={cx('rowHeader')}>
              <td className={cx('cell', 'cellRowHeader')}></td>

              {tableHead.map((header, index) => (
                <th
                  key={index}
                  className={cx('cell', 'cellHeader', {
                    cellBestseller: header.ctBestSeller,
                  })}
                  scope="col"
                >
                  <div className={cx('cellContent')}>
                    <RichText appearance="t4_2" text={header.headline} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((row: TableRowProps, index: number) => (
              <tr key={index} className={cx('rowData')}>
                <th className={cx('cell', 'cellRowHeader')} scope="row">
                  <div className={cx('cellContent')}>
                    {row.headline && (
                      <RichText appearance="t4_2" text={row.headline} />
                    )}
                    {row.subline && (
                      <RichText appearance="t5" text={row.subline} />
                    )}
                  </div>
                </th>

                {tableRowColumns(row)
                  .slice(0, columnCount)
                  .map((column, colIndex) => (
                    <td
                      className={cx('cell', 'cellData', {
                        cellBestseller:
                          tableHead[colIndex] &&
                          tableHead[colIndex].ctBestSeller,
                      })}
                      key={colIndex}
                    >
                      <div className={cx('cellContent')}>
                        <div className={cx('cellLabel')}>
                          {row.headline && (
                            <RichText appearance="t4_2" text={row.headline} />
                          )}
                          {row.subline && (
                            <RichText appearance="t5" text={row.subline} />
                          )}
                        </div>

                        <div className={cx('cellValue')}>
                          {!!column.icon && column.icon > 0 && (
                            <span className={cx('icon')}>
                              <ListIcon
                                iconType={column.icon}
                                iconColor="blue"
                              />
                            </span>
                          )}
                          {column.text && (
                            <RichText appearance="t5" text={column.text} />
                          )}
                        </div>
                      </div>
                    </td>
                  ))}
              </tr>
            ))}

            {priceRow &&
              priceRow
                .slice(0, columnCount)
                .map((row: PriceRowProps, index: number) => (
                  <tr key={index} className={cx('rowData')}>
                    <th className={cx('cell', 'cellRowHeader')} scope="row">
                      <div className={cx('cellContent')}>
                        {row.headline && (
                          <RichText appearance="t4_2" text={row.headline} />
                        )}
                      </div>
                    </th>

                    {row.priceColumns &&
                      row.priceColumns
                        .slice(0, columnCount)
                        .map((col: PriceColumnsProps, colIndex: number) => {
                          return (
                            <td
                              key={colIndex}
                              className={cx('cell', 'cellData', {
                                cellBestseller:
                                  tableHead[colIndex] &&
                                  tableHead[colIndex].ctBestSeller,
                              })}
                            >
                              <div className={cx('cellContent')}>
                                <div className={cx('cellLabel')}>
                                  {row.headline && (
                                    <RichText
                                      appearance="t4_2"
                                      text={row.headline}
                                    />
                                  )}
                                </div>

                                <div className={cx('cellValue')}>
                                  <PriceText
                                    price={col.price}
                                    infoColor="black"
                                    info={col.priceInterval}
                                  />
                                </div>
                              </div>
                            </td>
                          );
                        })}
                  </tr>
                ))}
          </tbody>

          <tfoot>
            {actionRow &&
              actionRow.map((row, index) => (
                <tr key={index}>
                  <td className={cx('cell', 'cellRowHeader')}></td>

                  {row.columns.slice(0, columnCount).map((col, colIndex) => (
                    <th
                      key={colIndex}
                      className={cx('cell', 'cellFooter', {
                        cellBestseller:
                          tableHead[colIndex] &&
                          tableHead[colIndex].ctBestSeller,
                      })}
                      scope="col"
                    >
                      <div className={cx('cellContent')}>
                        {col.link && col.link.text && (
                          <Spacer t={2} block>
                            <TextLink {...col.link} appearance="t4_2">
                              <span className={cx('detailsLink')}>
                                <IconPlus width={24} height={24} />
                                {col.link.text}
                              </span>
                            </TextLink>
                          </Spacer>
                        )}

                        {col.button && (
                          <Spacer t={4} block>
                            <ButtonLink {...col.button} btnFullwidth>
                              {col.button.text}
                            </ButtonLink>
                          </Spacer>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
          </tfoot>
        </table>
      );
    },
  ),
);

export const ComparisonTable: FC<ComparisonTableProps> = (props) => {
  const { tableHead, headline, text } = props;
  const columnCount = tableHead ? tableHead.length : 0;

  const [lastSlideIndex, setLastSlideIndex] = useState<number>(columnCount - 1);

  useEffect(() => {
    function updateLastSlideIndex() {
      setLastSlideIndex(getLastSlideIndex(columnCount));
    }

    updateLastSlideIndex();

    window.addEventListener('resize', updateLastSlideIndex);
    return () => {
      window.removeEventListener('resize', updateLastSlideIndex);
    };
  }, [columnCount]);

  const {
    tableRef,
    headerScRef,
    bodyScRef,
    activeColumn,
    goToPrev,
    goToNext,
    goToNth,
  } = useSlideControls(columnCount);

  const [headerIsStuck, setHeaderIsStuck] = useState<boolean>(false);
  const [dotsAreStuck, setDotsAreStuck] = useState<boolean>(false);

  const headerStickyGuardTopRef = useStickyGuard<HTMLDivElement>(
    (side, scrollDirection) => {
      if (side === 'top') {
        setHeaderIsStuck(scrollDirection === 'downwards');
      }
    },
  );

  const headerStickyGuardBottomRef = useStickyGuard<HTMLDivElement>(
    (side, scrollDirection) => {
      if (side === 'top') {
        setHeaderIsStuck(scrollDirection === 'upwards');
      }
    },
  );

  const dotsStickyGuardTopRef = useStickyGuard<HTMLDivElement>(
    (side, scrollDirection) => {
      if (side === 'bottom') {
        setDotsAreStuck(scrollDirection === 'downwards');
      }
    },
  );

  const dotsStickyGuardBottomRef = useStickyGuard<HTMLDivElement>(
    (side, scrollDirection) => {
      if (side === 'bottom') {
        setDotsAreStuck(scrollDirection === 'upwards');
      }
    },
  );

  useEffect(() => {
    document.body.classList.toggle('headerStuck', headerIsStuck);
  }, [headerIsStuck]);

  const hasBestseller = tableHead.some((head) => head.ctBestSeller);
  const hasSliding = lastSlideIndex !== 0;

  if (columnCount === 0) {
    return <Text appearance="t4">Error: Empty table</Text>;
  }

  return (
    <div style={{ ['--column-count' as any]: columnCount }}>
      <ModuleHeadlineText headline={headline} text={text} />
      <Grid className={cx('gridContainer', { hasBestseller, hasSliding })}>
        <div className={cx('stickyArea')}>
          <div
            ref={headerStickyGuardTopRef}
            className={cx('stickyGuard', 'headerStickyGuardTop')}
          />
          <div
            ref={headerStickyGuardBottomRef}
            className={cx('stickyGuard', 'headerStickyGuardBottom')}
          />
          <div
            ref={dotsStickyGuardTopRef}
            className={cx('stickyGuard', 'dotsStickyGuardTop')}
          />
          <div
            ref={dotsStickyGuardBottomRef}
            className={cx('stickyGuard', 'dotsStickyGuardBottom')}
          />

          {hasSliding && (
            <div className={cx('navButtonContainer')}>
              <NavButtonPrev activeColumn={activeColumn} goToPrev={goToPrev} />
              <NavButtonNext
                activeColumn={activeColumn}
                goToNext={goToNext}
                lastSlideIndex={lastSlideIndex}
              />
            </div>
          )}

          <div
            className={cx('stickyHeader', {
              stickyHeaderStuck: headerIsStuck,
            })}
          >
            <div className={cx('slideContainer')}>
              <div className={cx('slideContent')} ref={headerScRef}>
                <div className={cx('headerContent')}>
                  {tableHead.map((header, index) => (
                    <div
                      key={index}
                      className={cx('cell', 'cellHeader', {
                        cellBestseller:
                          tableHead[index] && tableHead[index].ctBestSeller,
                      })}
                    >
                      <div className={cx('cellContent')}>
                        <RichText appearance="t4_2" text={header.headline} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={cx('slideContainer')}>
            <div className={cx('slideContent')} ref={bodyScRef}>
              <TableContent ref={tableRef} {...props} />
            </div>
          </div>

          {hasSliding && (
            <>
              <Spacer t={2} block />

              <div
                className={cx('stickyDots', {
                  stickyDotsStuck: dotsAreStuck,
                })}
              >
                <div className={cx('dotsBar')}>
                  <NavButtonPrev
                    activeColumn={activeColumn}
                    goToPrev={goToPrev}
                  />

                  <Dots
                    activeColumn={activeColumn}
                    count={lastSlideIndex + 1}
                    onDotClick={goToNth}
                  />

                  <NavButtonNext
                    activeColumn={activeColumn}
                    goToNext={goToNext}
                    lastSlideIndex={lastSlideIndex}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
    </div>
  );
};

export default ComparisonTable;
