import { default as NextLink } from 'next/link';
import { FC, MouseEvent, ReactNode } from 'react';
import { BaseLinkProps } from '../BaseLink';
import { formatUrl, isInternalLink } from '../helpers';

export interface AnchorProps {
  children?: ReactNode;
  href?: string;
  target?: string;
  title?: string;
  className?: string;
  e2e?: string;
  pageId?: number; // from typo but not in use
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const PlainLink: FC<BaseLinkProps> = ({
  children,
  url,
  onClick,
  contactTrack,
  className,
  e2e,
  pageId, // eslint-disable-line @typescript-eslint/no-unused-vars
  linkLocation,
  ariaCurrent,
  ...rest
}) => {
  const props = {
    'data-location': linkLocation,
    'aria-current': ariaCurrent,
    title: rest.title,
    target: rest.target,
    ...(e2e && { 'data-e2e': `link-${e2e}` }),
    ...(rest.target && rest.target === '_blank' && { rel: 'noopener' }),
    ...rest,
  };

  if (isInternalLink(url) && !onClick) {
    return (
      <NextLink
        passHref
        href={formatUrl(url, contactTrack)}
        className={className}
        {...props}
      >
        {children}
      </NextLink>
    );
  }

  return (
    <a href={url} className={className} onClick={onClick} {...props}>
      {children}
    </a>
  );
};
