import { useOrderStore } from './';
import { ProductGroups, ProductModifiers } from '../../constants';
import { useEffect, useMemo, useState } from 'react';

export const useOrder = () => {
  const store = useOrderStore();
  const [isInitialized, setIsInitialized] = useState(false);

  const hasProductOptions = (
    selectedOptions: ProductGroups[],
    options: ProductGroups[],
  ) => {
    if (selectedOptions.length !== options.length) {
      return false;
    }

    return (
      JSON.stringify(selectedOptions.sort()) === JSON.stringify(options.sort())
    );
  };

  const phoneEnabled = useMemo(
    () =>
      store.modifier !== ProductModifiers.withoutAll &&
      store.modifier !== ProductModifiers.withoutPhone,
    [store.modifier],
  );

  const tvEnabled = useMemo(
    () =>
      store.modifier !== ProductModifiers.withoutAll &&
      store.modifier !== ProductModifiers.withoutTv,
    [store.modifier],
  );

  useEffect(() => {
    setIsInitialized(true);
  }, [store]);

  return {
    hasProductOptions,
    phoneEnabled,
    tvEnabled,
    isInitialized,
    ...store,
  };
};
