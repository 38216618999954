import { useCallback } from 'react';
import { OrderStep, useNotConnected } from '../../store';
import {
  OrderCheckoutVariant,
  ProductId,
  TemplateGroup,
} from '../../constants';
import {
  cableTVStepsPK,
  ftthExistingCustomerSteps,
  ftthNewCustomerSteps,
  OrderStepIds,
  orderStepsGK,
  orderStepsPK,
} from './model';
import { BusinessUnit } from '@ncs-frontend-monorepo/utils';
import {
  FTTHPresalesInformation,
  NetTvType,
} from '@ncs-frontend-monorepo/availability';
import { hasExistingCustomerSteps, shouldRemoveGEEStep } from '../../helpers';

interface AdjacentSteps {
  previous: OrderStep | null;
  current: OrderStep | null;
  next: OrderStep | null;
}

interface UseStepsProps {
  businessUnit: BusinessUnit;
  templateId: ProductId;
  group: TemplateGroup;
  checkoutVariant?: OrderCheckoutVariant;
  tvPromotions?: Array<keyof typeof NetTvType>;
  ftthPresalesInformation?: FTTHPresalesInformation;
}

export const useSteps = ({
  businessUnit,
  templateId,
  group,
  checkoutVariant,
  tvPromotions,
  ftthPresalesInformation,
}: UseStepsProps) => {
  const { podInfo } = useNotConnected();

  const getSteps = useCallback((): OrderStep[] => {
    switch (true) {
      case !!ftthPresalesInformation: {
        let steps = ftthNewCustomerSteps;

        // on existing customer use existing steps model
        if (hasExistingCustomerSteps(checkoutVariant)) {
          steps = ftthExistingCustomerSteps;
        }

        // check if gee step is necessary
        if (
          shouldRemoveGEEStep(
            ftthPresalesInformation,
            podInfo?.propertyOwnerRole,
          )
        ) {
          steps = steps.filter((step) => step.id !== OrderStepIds.pod);
        }

        return steps;
      }
      case businessUnit === 'GK':
        return orderStepsGK;
      case group === TemplateGroup.TV_CABLE: {
        let tvSteps = cableTVStepsPK;

        // exclude installation step on ftth cable
        if (tvPromotions && !tvPromotions.includes(NetTvType.NET_TV_FTTH)) {
          tvSteps = tvSteps.filter(
            (step) => step.id !== OrderStepIds.installationTV,
          );
        }

        // exclude bank step for existing customers
        if (templateId === ProductId.TV_CABLE_EXISTING_CUSTOMER) {
          tvSteps = tvSteps.filter((step) => step.id !== OrderStepIds.bank);
        }

        return tvSteps;
      }
      default:
        return orderStepsPK;
    }
  }, [
    ftthPresalesInformation,
    businessUnit,
    group,
    checkoutVariant,
    podInfo?.propertyOwnerRole,
    tvPromotions,
    templateId,
  ]);

  const getAdjacentSteps = useCallback(
    (currentStepIndex: number): AdjacentSteps => {
      const steps = getSteps();
      const stepIndex = currentStepIndex > 0 ? currentStepIndex : 0;

      return {
        previous: (stepIndex !== 0 && steps[stepIndex - 1]) || null,
        current: steps[stepIndex],
        next: stepIndex < steps.length - 1 ? steps[stepIndex + 1] : null,
      };
    },
    [getSteps],
  );
  return {
    getSteps,
    getAdjacentSteps,
  };
};
