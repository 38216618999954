import {
  ButtonLinkProps,
  LinkProps,
  PartnerWidget,
  PartnerWidgetProps,
  PartnerWidgetType,
} from '../../components';
import { FC, ReactNode, SyntheticEvent } from 'react';
import cx from 'classnames';
import styles from './Card.module.scss';

interface CardTeaserWrapperProps {
  children?: ReactNode;
  button?: ButtonLinkProps;
  partnerWidget?: PartnerWidgetProps['widget'];
  partnerWidgetParam?: PartnerWidgetProps['widgetParam'];
  handleClick(e: MouseEvent | SyntheticEvent, link?: LinkProps): void;
}

export const CardTeaserWrapper: FC<CardTeaserWrapperProps> = ({
  button,
  partnerWidget,
  partnerWidgetParam = '',
  handleClick,
  children,
}) => {
  return partnerWidget ? (
    <PartnerWidget
      type={PartnerWidgetType.Wrapper}
      widget={partnerWidget}
      widgetParam={partnerWidgetParam}
      e2e={button?.contactTrack}
    >
      {children}
    </PartnerWidget>
  ) : (
    <div
      className={styles.wrapper}
      {...(button && {
        className: cx(styles.wrapper, styles.withPointer),
        onClick: (e) => handleClick(e, button?.link),
      })}
    >
      {children}
    </div>
  );
};
