import { getCorrelationId } from '../correlationId';
import { getEnv, isServerEnvironment } from '../env';

export const LOG_LEVELS = {
  ALL: 'ALL',
  INFO: 'INFO',
  ERROR: 'ERROR',
} as const;

export type LogLevel = (typeof LOG_LEVELS)[keyof typeof LOG_LEVELS];

interface FetchLogging {
  message?: string;
  url?: string;
  error: boolean;
}
export const fetchLogging = ({
  message = '',
  url = '',
  error,
}: FetchLogging) => {
  const correlationId = getCorrelationId();
  // always log errors
  if (error) {
    return console.log(
      '\x1b[31m%s\x1b[0m',
      `[FETCHING] Error | URL: ${url} - CorId: ${correlationId} - ${message}`,
    );
  }

  // no logging for client or error only logging
  if (!isServerEnvironment || getEnv().LOG_LEVEL !== LOG_LEVELS.ALL) {
    return;
  }

  // eslint-disable-next-line
  console.log(`[FETCHING] ${message} | URL: ${url} - CorId: ${correlationId}`);
};
