import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import {
  AvailableProductOptions,
  BasketProduct,
  PropertyOwnerRole,
  ResidentialUnit,
} from '../../';
import {
  FetchAddressReponse,
  FTTHPresalesInformation,
  ObjectInformation,
} from '@ncs-frontend-monorepo/availability';

export interface NotConnectedStoreState {
  info?: NotConnectedInfo | null;
  updateInfo: (info: NotConnectedInfo) => void;
  updateAvailableProductOptions: (
    availableProductOptions: AvailableProductOptions,
  ) => void;
  availableProductOptions: AvailableProductOptions | null;
  updateBasket: (basket: BasketProduct) => void;
  basket: BasketProduct | null;
  updateBasketId: (basketId: string) => void;
  resetBasket: () => void;
  basketId: string | null;
  podInfo: PODInfo | null;
  updatePODInfo: (podInfo: PODInfo) => void;
  reset: () => void;
}

export interface NotConnectedInfo {
  ftthPresalesInformation: FTTHPresalesInformation;
  address: FetchAddressReponse;
  inkassoTypeValidFrom: ObjectInformation['inkassoTypeValidFrom'];
}

export interface PODInfo {
  propertyOwnerRole?: PropertyOwnerRole | null;
  residentialUnit?: ResidentialUnit | null;
}

const defaultNotConnectedStore = {
  info: null,
  availableProductOptions: null,
  basket: null,
  basketId: null,
  selection: null,
  podInfo: null,
};

export const useNotConnectedStore = create<NotConnectedStoreState>()(
  persist(
    (set) => ({
      ...defaultNotConnectedStore,
      updateInfo: (info) =>
        set((state) => ({
          ...state,
          info,
        })),
      updateAvailableProductOptions: (availableProductOptions) =>
        set((state) => ({
          ...state,
          availableProductOptions,
        })),
      updateBasket: (basket) =>
        set((state) => ({
          ...state,
          basket,
        })),
      updateBasketId: (basketId) =>
        set((state) => ({
          ...state,
          basketId,
        })),
      resetBasket: () =>
        set((state) => ({
          ...state,
          basket: defaultNotConnectedStore.basket,
        })),
      updatePODInfo: (podInfo) =>
        set((state) => ({
          ...state,
          podInfo,
        })),
      reset: () =>
        set({
          ...defaultNotConnectedStore,
        }),
    }),
    {
      name: 'notConnectedStore',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
