import { ImageProps } from '../contentModules/Image';
import { SliderPropsFetched } from '../contentModules/Slider';
import { TextProps } from '../contentModules/Text';
import { TilesGroupProps } from '../contentModules/TilesGroup';
import { CardProps } from '../contentModules/Card';
import { TwoColumnProps } from '../contentModules/TwoColumn';
import { ContactTilesGroupProps } from '../contentModules/ContactTilesGroup';
import { IconImageTeaserProps } from '../contentModules/IconImageTeaser';
import { AccordionProps } from '../contentModules/Accordion';
import { VideoProps } from '../contentModules/Video';
import { ComparisonTableProps } from '../contentModules/ComparisonTable/interfaces';
import { BannerProps } from '../contentModules/Banner';
import { BackLinkProps } from '../contentModules/BackLink';
import { LinkListProps } from '../contentModules/LinkList';
import { MixedContentProps } from '../contentModules/MixedContent';
import { CounterProps } from '../contentModules/Counter';
import { TestimonialProps } from '../contentModules/Testimonial';
import { SimpleImageSliderProps } from '../contentModules/SimpleImageSlider';
import { RemoteIpProps } from '../contentModules/RemoteIp';
import { ChannelListProps } from '../contentModules/ChannelList';
import { TabSliderModule } from '../contentModules/TabSlider';

import {
  InformationBarProps,
  PageTitleProps,
  FootnoteListProps,
} from 'dss-ui-library';
import { BusinessUnit } from '@ncs-frontend-monorepo/utils';

export enum ContentType {
  VIDEO = 'ModuleVideo',
  ACCORDION = 'ModuleAccordion',
  HELLO = 'Hello',
  IMAGE = 'ModuleImage',
  SLIDER = 'ModuleSlider',
  SIMPLEIMAGESLIDER = 'ModuleSimpleImageSlider',
  TEXT = 'ModuleText',
  PAGETITLE = 'ModulePageTitle',
  TILESGROUP = 'ModuleTilesGroup',
  CONTACTTILESGROUP = 'ModuleContactTilesGroup',
  CARD = 'ModuleCard',
  FOOTNOTELIST = 'ModuleFootnoteList',
  TWOCOLUMN = 'ModuleTwoColumn',
  MIXEDCONTENT = 'ModuleMixedContent',
  ICONIMAGETEASER = 'ModuleIconImageTeaser',
  COMPARISONTABLE = 'ModuleComparisonTable',
  BANNER = 'ModuleBanner',
  COUNTER = 'ModuleCounter',
  TESTIMONIAL = 'ModuleTestimonial',
  BACKLINK = 'ModuleBackLink',
  LINKLIST = 'ModuleLinkList',
  INFORMATIONBAR = 'ModuleInformationBar',
  REMOTEIP = 'ModuleRemoteIp',
  CHANNELLIST = 'ModuleChannelList',
  TAB_SLIDER = 'nccontentelements_tab',
  TEMPLATE_ENGINE = 'ModuleTemplateEngine',
  PLAN_COLUMNS = 'ModulePlanColumns',
  PLAN_ROWS = 'ModulePlanRows',
  PLAN_DETAIL = 'ModulePlanDetail',
  PRODUCT_COMPARISON = 'ModuleProductComparison',
  PRODUCT_INFO = 'ModuleProductInfo',
  EXPANSION_CHECK = 'ModuleExpansionCheck',
  TOOLTIP_IMAGE = 'ModuleTooltipImage',
  STATIC_CONTENT = 'ModuleStaticContent',
  FAQ_CATEGORIES = 'ncfaq_headless',
  FAQ_CATEGORY_PAGE = 'ncfaq_categoryPage',
  FAQ_PAGE = 'ncfaq_page',
}

export type DynamicContentType = {
  id: number;
  contentElementProvider?: 'FLUX';
} & (
  | ContentVideo
  | ContentAccordion
  | ContentImage
  | ContentSlider
  | ContentSimpleImageSlider
  | ContentText
  | ContentPageTitle
  | ContentTilesGroup
  | ContentCard
  | ContentFootnoteList
  | ContentTwoColumn
  | ContentMixedContent
  | ContentIconImageTeaser
  | ContentComparisonTable
  | ContentContactTilesGroup
  | ContentBanner
  | ContentCounter
  | ContentTestimonial
  | ContentBackLink
  | ContentLinkList
  | ContentInformationBar
  | ContentRemoteIp
  | ChannelList
  | TabSliderContent
);

export type ContentVideo = {
  component: ContentType.VIDEO;
  props: VideoProps;
};

export type ContentAccordion = {
  component: ContentType.ACCORDION;
  props: AccordionProps;
};

export type ContentImage = {
  component: ContentType.IMAGE;
  props: ImageProps;
};

export type ContentSlider = {
  component: ContentType.SLIDER;
  props: SliderPropsFetched;
};

export type ContentSimpleImageSlider = {
  component: ContentType.SIMPLEIMAGESLIDER;
  props: SimpleImageSliderProps;
};

export type ContentText = {
  component: ContentType.TEXT;
  props: TextProps;
};

export type ContentPageTitle = {
  component: ContentType.PAGETITLE;
  props: PageTitleProps;
};

export type ContentTilesGroup = {
  component: ContentType.TILESGROUP;
  props: TilesGroupProps;
};

export type ContentContactTilesGroup = {
  component: ContentType.CONTACTTILESGROUP;
  props: ContactTilesGroupProps;
};

export type ContentCard = {
  component: ContentType.CARD;
  props: CardProps;
};

export type ContentFootnoteList = {
  component: ContentType.FOOTNOTELIST;
  props: FootnoteListProps;
};

export type ContentTwoColumn = {
  component: ContentType.TWOCOLUMN;
  props: TwoColumnProps;
};

export type ContentIconImageTeaser = {
  component: ContentType.ICONIMAGETEASER;
  props: IconImageTeaserProps;
};

export type ContentMixedContent = {
  component: ContentType.MIXEDCONTENT;
  props: MixedContentProps;
};

export type ContentComparisonTable = {
  component: ContentType.COMPARISONTABLE;
  props: ComparisonTableProps;
};

export type ContentBanner = {
  component: ContentType.BANNER;
  props: BannerProps;
};

export type ContentCounter = {
  component: ContentType.COUNTER;
  props: CounterProps;
};

export type ContentTestimonial = {
  component: ContentType.TESTIMONIAL;
  props: TestimonialProps;
};

export type ContentBackLink = {
  component: ContentType.BACKLINK;
  props: BackLinkProps;
};

export type ContentLinkList = {
  component: ContentType.LINKLIST;
  props: LinkListProps;
};

export type ContentInformationBar = {
  component: ContentType.INFORMATIONBAR;
  props: InformationBarProps;
};

export type ContentRemoteIp = {
  component: ContentType.REMOTEIP;
  props: RemoteIpProps;
};

export type ChannelList = {
  component: ContentType.CHANNELLIST;
  props: ChannelListProps;
};

export type TabSliderContent = {
  component: ContentType.TAB_SLIDER;
  props: TabSliderModule;
};

export interface DynamicContentProps {
  content: DynamicContentType | DynamicContentType[];
  withTopSpacing?: boolean;
  spacerSize?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  hasOverlap?: boolean;
  getElementId?: (id: number) => string;
  businessUnit?: BusinessUnit;
}
