import { FC } from 'react';
import {
  Grid,
  YouTubeVideo,
  Text,
  Spacer,
  Background,
  ImageProps,
  TextElements,
} from 'dss-ui-library';
import { LinkProps } from '../../components/Link';

export interface VideoProps {
  headline?: string;
  text?: string;
  link: LinkProps;
  image: ImageProps;
  headlineElement?: TextElements;
}

export const Video: FC<VideoProps> = ({ headlineElement = 'h2', ...props }) => (
  <Background color="white">
    <Grid>
      <Grid.Row>
        <Grid.Column m={8}>
          {props.headline && (
            <>
              <Text element={headlineElement} color="blue" appearance="t1_2">
                {props.headline}
              </Text>
              <Spacer b={3} block />
            </>
          )}
          {props.text && (
            <Spacer b={3} block>
              <Text element="h4" appearance="t4">
                {props.text}
              </Text>
            </Spacer>
          )}
          <YouTubeVideo
            youtubeUrl={props.link.url}
            image={props.image}
            isNetcologne={process.env.SITE === 'NetCologne'}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Background>
);

export default Video;
