import { Fallback, MIN_PRESALE_BOOKING_WEEKS } from '../constants';
import { Availability, AvailabilityResponse, Promotions } from '../interfaces';
import { differenceInWeeks } from 'date-fns';
import { createAvailabilityList } from './portfolio';
import { getMaxDownloads } from './getMaxDownloads';
import { getTechnologyDownloads } from './getTechnologyDowloads';
import { checkIfFTTHIsPlanned } from './notConnected/checkIfFTTHIsPlanned';
import { createFTTHPresaleInformatino } from './createFTTHPresalesInformation';
import { getAlternativeProductAvailability } from './getAlternativeProductAvailability';
import { checkIsValidAddress } from './checkIsValidAddress';

export const normalizeAvailability = (
  result: AvailabilityResponse,
): Availability => {
  const { landlinePromotions, tvPromotions, objectInformation, address } =
    result;
  let fallback = [Fallback.ADSL, Fallback.TECHNOLOGY].includes(result.fallback)
    ? result.fallback
    : null;

  let availablePromotions: Promotions['availablePromotions'] = null;
  let presalePromotion: Promotions['presalePromotion'] = null;

  if (landlinePromotions?.length) {
    const presalePromotionIndex = landlinePromotions.findIndex(
      (promotions) => promotions?.availability === 'PRESALE',
    );

    if (
      presalePromotionIndex > -1 &&
      result?.landlinePromotions?.[presalePromotionIndex]
    ) {
      presalePromotion = result.landlinePromotions[presalePromotionIndex];
      const weeksUntilAvailability = differenceInWeeks(
        new Date(presalePromotion.plannedAvailabilityDate || ''),
        new Date(),
      );

      // If time window of Presale promotion is too long, we must offer existing (available promotion)
      if (weeksUntilAvailability >= MIN_PRESALE_BOOKING_WEEKS) {
        availablePromotions =
          landlinePromotions.filter(
            (_, index) => index !== presalePromotionIndex,
          ) || null;

        // EDGE-Case!!! with Presale and ADSL-fallback, only presale should be available, fallback should be NONE
        if (fallback === Fallback.ADSL) {
          availablePromotions = [];
          fallback = Fallback.NONE;
        }
      } else {
        availablePromotions = [
          {
            ...presalePromotion,
            plannedAvailabilityDateDescription: '',
          },
        ];
        presalePromotion = null;
      }
    } else {
      availablePromotions = result.landlinePromotions ?? [];
    }
  }

  const availabilityList = createAvailabilityList({
    availablePromotions,
    objectInformation,
    presalePromotion,
    fallback,
  });

  const { maxDownload, planned } = getMaxDownloads({
    availablePromotions,
    presalePromotion,
  });

  const orderPromotions = getTechnologyDownloads(
    availablePromotions,
    presalePromotion,
  );

  const ftthPresalesInformation = createFTTHPresaleInformatino({
    objectInformation,
    alternativeProductAvailability: getAlternativeProductAvailability([
      ...(availablePromotions ? availablePromotions : []),
      ...(presalePromotion ? [presalePromotion] : []),
    ]),
  });

  const isFTTHPlanned = checkIfFTTHIsPlanned(
    objectInformation,
    availablePromotions,
    presalePromotion,
  );

  const isValidAddress = checkIsValidAddress({
    address,
    maxDownload,
    planned,
    status: ftthPresalesInformation?.status,
  });

  return {
    promotions: {
      order: orderPromotions,
      availablePromotions,
      presalePromotion,
      tvPromotions,
      fallback,
    },
    ftthPresalesInformation,
    isFTTHPlanned,
    portfolio: availabilityList.includes('CLASSIC') ? 'CLASSIC' : 'FIBER',
    maxDownload,
    planned,
    list: availabilityList,
    objectInformation,
    address,
    isChecked: true,
    isValidAddress,
  };
};
