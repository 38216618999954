import {
  ContentContext,
  ContentType,
  DynamicContentProps,
  TelljaWidgets,
} from '@ncs-frontend-monorepo/content-library';
import { GetStaticPropsContext } from 'next';
import { PlanColumnProps } from '../components/PlanColumns';
import { PlanRowProps } from '../components/PlanRows';
import {
  APIRedirects,
  CombinedContentType,
  DefaultRuntime,
  DynamicContentBlock,
  Page,
  ProductInfo,
  ProductInfoContentBlock,
  ProductTypes,
  Sitemap,
  SitemapEntry,
} from '../interfaces';
import {
  fetcher,
  getEnv,
  getFormattedPriceString,
} from '@ncs-frontend-monorepo/utils';
import { fallbackProductIds } from './promotion';
import { getPlanParts, getTemplateId } from './plan';
import {
  ProductId,
  DeviceOwnership,
  Product,
  getProducts,
  TV_GROUPS,
  TV_SHORT_DESCRIPTIONS,
  DynamicPlan,
  Footnote,
  Placement,
  ProductMap,
} from '@ncs-frontend-monorepo/order';
import {
  DEFAULT_CLASSIC_TECHNOLOGY,
  DEFAULT_FIBER_TECHNOLOGY,
  NetTvType,
  Technology,
} from '@ncs-frontend-monorepo/availability';

export function apiUrl(): string {
  const env = getEnv();
  return env.API_URL;
}

export function contentApiURL(): string {
  return `${apiUrl()}/content/`;
}

export function getProductInfoURL(): string {
  return `${getEnv().API_FILE_URL}/fileadmin/user_upload/${
    getEnv().SITE === 'NetAachen' ? 'netaachen' : 'privatkunden'
  }/pdf/produktinformationen/`;
}

export async function fetchSitemap(): Promise<Sitemap> {
  const url = `${apiUrl()}/pages`;
  const rawSitemap: Sitemap = await fetcher(url);

  // This is required in order to get only entries valid for the given subpage (PK or GK)
  let validSitemap = rawSitemap;
  if (process.env.NEXT_PUBLIC_SITE_PREFIX) {
    validSitemap = {
      ...rawSitemap,
      pageTree: [
        findPageByCondition(
          rawSitemap.pageTree,
          (p) => p.path === process.env.NEXT_PUBLIC_SITE_PREFIX + '/',
        ),
      ],
    };
  }
  return validSitemap;
}

export async function fetchPage(pageId: string): Promise<Page> {
  return await fetcher(`${apiUrl()}/pages/${pageId}`, null, true);
}

export async function fetchRedirects(): Promise<APIRedirects> {
  return await fetcher(`${apiUrl()}/redirect`, null, true);
}

interface GetDynamicPlansProps {
  basePlans: ProductId[];
  modifiers?: {
    togglePhone?: boolean;
    toggleNetTv?: boolean;
  };
  technology?: Technology;
}

export async function getDynamicPlans({
  basePlans = [],
  modifiers,
  technology,
}: GetDynamicPlansProps): Promise<ProductMap<DynamicPlan>> {
  const { togglePhone = false, toggleNetTv = false } = modifiers;
  if (togglePhone || toggleNetTv) {
    const dynamicPlans: ProductMap<DynamicPlan> = {};

    await Promise.all(
      basePlans.map(async (id) => {
        const {
          bandwidth,
          withCableTV,
          withTv,
          isYoung,
          withPhone,
          ftthPrefix,
        } = getPlanParts(id);
        const modifiedPlans = {
          ...(togglePhone && {
            withoutPhone: await getProducts({
              templateId: getTemplateId({
                ftthPrefix,
                bandwidth,
                isYoung,
                withTv,
                withCableTV,
              }),
              technology,
            }),
          }),
          ...(toggleNetTv &&
            !withCableTV && {
              withoutTv: await getProducts({
                templateId: getTemplateId({
                  ftthPrefix,
                  bandwidth,
                  isYoung,
                  withPhone,
                }),
                technology,
              }),
            }),
          ...(togglePhone &&
            toggleNetTv && {
              withoutAll: await getProducts({
                templateId: getTemplateId({ ftthPrefix, bandwidth, isYoung }),
                technology,
              }),
            }),
        };

        if (Object.keys(modifiedPlans).length > 0) {
          dynamicPlans[id] = modifiedPlans;
        }
      }),
    );
    return Object.keys(dynamicPlans).length > 0 ? dynamicPlans : null;
  }
  return null;
}

export const isRouter = (productInfo: ProductInfo) =>
  (productInfo.category === 'basic' || productInfo.category === 'premium') &&
  productInfo.type === 'device';

export function getComparisonPriceInfo(
  productInfo: ProductInfo,
  product: Product,
) {
  // Router
  if (isRouter(productInfo)) {
    const device = product?.devices?.find(
      ({ category, ownership }) =>
        category.toLowerCase() === productInfo.category &&
        ownership === DeviceOwnership.RENT,
    );
    return {
      ...device.priceInfo,
      // temporary fix until backend api returns new price information
      runtime: 24,
    };
  }

  // Installation Service
  if (productInfo.type === 'service') {
    return product.installationServices?.find(({ id }) => id === productInfo.id)
      .priceInfo;
  }

  // CI Module
  if (productInfo.id === 'CI_MODULE') {
    const ciModule = product.tv.devices.find(
      ({ id, ownership }) =>
        id === productInfo.id && ownership === DeviceOwnership.RENT,
    );
    return {
      ...ciModule.priceInfo,
      runtime: product.isWithoutRuntime ? 0 : product.runtime,
    };
  }

  return null;
}

export async function getOntData(
  deviceId: string,
): Promise<ProductInfoContentBlock> {
  return await fetcher(`${contentApiURL()}fiber-${deviceId}`);
}

export async function getIadData(
  deviceId: string,
): Promise<ProductInfoContentBlock> {
  return await fetcher(`${contentApiURL()}router-${deviceId}`);
}

export async function getInstallationData(
  installationId: string,
): Promise<ProductInfoContentBlock> {
  return await fetcher(
    `${contentApiURL()}installationservice-${installationId}`,
  );
}

export async function getDeviceData(
  deviceId: string,
): Promise<ProductInfoContentBlock> {
  return await fetcher(`${contentApiURL()}device-${deviceId}`);
}

export async function getContentByContentSlug(
  contentSlug: string,
): Promise<DynamicContentBlock> {
  return await fetcher(`${contentApiURL()}${contentSlug}`);
}

export function getNormalizedPath(
  context: GetStaticPropsContext,
  addTrailingSlash = false,
): string {
  let path: string = context.params.param
    ? Array.isArray(context.params.param)
      ? context.params.param.join('/')
      : context.params.param
    : '';
  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  if (!path.endsWith('/') && addTrailingSlash) {
    path = path + '/';
  }

  return path;
}

export function findPageByCondition(
  pages: SitemapEntry[],
  func: (entry: SitemapEntry) => boolean,
): SitemapEntry | null {
  for (const page of pages) {
    if (func(page)) {
      return page;
    }
    if (page.subpages) {
      const subpage = findPageByCondition(page.subpages, func);
      if (subpage) {
        return subpage;
      }
    }
  }
  return null;
}

export function filterPagesByCondition(
  pages: SitemapEntry[] = [],
  func: (entry: SitemapEntry) => boolean,
): SitemapEntry[] {
  return pages.reduce((pages, entry) => {
    if (func(entry)) {
      pages = [...pages, entry];
    }
    if (entry.subpages) {
      const subpages = filterPagesByCondition(entry.subpages, func);
      pages = [...pages, ...subpages];
    }
    return pages;
  }, []);
}

export function createStaticPaths(pages: SitemapEntry[]): Array<string> {
  return pages.reduce((paths: Array<string>, entry: SitemapEntry) => {
    if (entry.path && entry.display) {
      paths = [...paths, entry.path];
    }
    if (entry.subpages) {
      paths = [...paths, ...createStaticPaths(entry.subpages)];
    }
    return paths;
  }, []);
}

/**
 * @deprecated
 */
export const createPriceInfoPermanentlyHint = (
  plan: Product,
  isLongTerm: boolean,
): string => {
  if (!isLongTerm) {
    return 'ohne Vertragslaufzeit';
  }
  const permanentlyText = 'für die gesamte Vertragslaufzeit';
  return plan?.internet?.download === 500
    ? permanentlyText +
        ` statt regulär ${getFormattedPriceString(
          plan.pricing.monthly.price,
        )} mtl.`
    : permanentlyText;
};

export const createShortDescriptionByPlan = (plan: Product): string[] =>
  Object.keys(ProductTypes).reduce((prev, curr) => {
    if (plan[curr]) {
      switch (curr) {
        case 'internet':
          prev = [
            ...prev,
            plan.internet
              ? `Internet-Flat bis zu ${plan.internet.download} Mbit/s`
              : '',
          ];
          break;
        case 'telephony':
          prev = [...prev, 'Telefon-Flat'];
          break;
        case 'tv':
          prev = [...prev, getTVShortDescription(plan)];
          break;
        default:
          break;
      }
    }
    return prev;
  }, []);

export const getTVShortDescription = (plan: Product): string => {
  switch (true) {
    case TV_GROUPS.includes(plan.group):
      return TV_SHORT_DESCRIPTIONS?.[plan.id] || '';
    case plan?.tv?.type === NetTvType.NET_TV_INTERNET:
      return 'NetTV über Internet';
    case plan?.tv?.type === NetTvType.NET_TV_APP:
      return 'NetTV App';
    case plan?.tv?.type === NetTvType.NET_TV_CABLE:
      return 'NetTV über Kabel';
    default:
      return '';
  }
};

export const extractValidProductIdsFromString = (input = ''): ProductId[] =>
  input
    .replace(/\s+/g, '')
    .split(',')
    .filter((id: ProductId) =>
      Object.values(ProductId).includes(id),
    ) as ProductId[];

const getUseCases = async (
  templateId: ProductId,
): Promise<DynamicContentProps> => {
  return await fetcher(`${contentApiURL()}use-cases-${templateId}`);
};

export function getHybridContent(
  customContent: CombinedContentType[],
  sitemap: Sitemap,
): Promise<CombinedContentType[]> {
  return Promise.all(
    customContent.map(async (content) => {
      if (content.component === ContentType.TEMPLATE_ENGINE) {
        const { togglePhone = false, toggleNetTv = false } = content.props;
        const { portfolio = 'FIBER' } = content.props.params;
        let technology =
          portfolio === 'FIBER'
            ? DEFAULT_FIBER_TECHNOLOGY
            : DEFAULT_CLASSIC_TECHNOLOGY;

        switch (content.props.type) {
          case 'planDetail': {
            const templateId = content.props.params.templateId;
            if (fallbackProductIds.includes(templateId)) {
              technology = null;
            }
            const { withTv, withCableTV } = getPlanParts(templateId);

            const dynamicPlans = await getDynamicPlans({
              basePlans: [templateId],
              modifiers: {
                togglePhone,
                toggleNetTv,
              },
              technology,
            });

            content = {
              ...content,
              component: ContentType.PLAN_DETAIL,
              props: {
                ...content.props,
                ...(content.props.params?.portfolio
                  ? { portfolio: content.props.params.portfolio }
                  : { portfolio: 'FIBER' }),
                ...(await getProducts({
                  templateId,
                  technology,
                })),
                isBestseller: content.props.params?.bestseller === templateId,
                isTopprice:
                  content.props.params?.topprice ===
                  content.props.params.templateId,
                defaultRuntime:
                  content.props?.params.defaultRuntime ||
                  DefaultRuntime.Default,
                footnotes: content.props.footnotes || [],
                kundenweltURL: content.props.params?.kundenweltURL ?? null,
                withChannelList:
                  withTv || withCableTV
                    ? customContent.some(
                        (content) =>
                          content.component === ContentType.CHANNELLIST,
                      )
                    : false,
                useCases: await getUseCases(templateId)
                  .then((response) => response)
                  .catch(() => {
                    return null;
                  }),
                ...(content.props.params?.recommendation && {
                  recommendation: await getIadData(
                    content.props.params.recommendation.toLowerCase(),
                  )
                    .then((response) => response.content.props)
                    .catch(() => {
                      console.warn(
                        `No device recommendation found for id ${content.props}.`,
                      );
                      return null;
                    }),
                }),
                ...(dynamicPlans && {
                  dynamicPlan: dynamicPlans[templateId],
                }),
              },
            };
            break;
          }
          case 'planColumns':
          case 'planRows': {
            const {
              templateId,
              bestseller,
              topprice,
              highlighted,
              ftthPrePresale,
              uncheckToggleOptionsByDefault,
              iadDevices,
              defaultRuntime,
              ...rest
            } = content.props.params;
            const isFTTHPrePresale = ftthPrePresale === 'true';
            const uncheckToggleOptions =
              uncheckToggleOptionsByDefault === 'true';
            const templateIds = extractValidProductIdsFromString(templateId);
            const bestsellerTemplateIds =
              extractValidProductIdsFromString(bestseller);
            const toppriceTemplateIds =
              extractValidProductIdsFromString(topprice);
            const highlightedTemplateIds =
              extractValidProductIdsFromString(highlighted);
            if (isFTTHPrePresale) {
              technology = Technology.FTTH;
            }

            const plans = await Promise.all<
              PlanRowProps | PlanColumnProps | null
            >(
              templateIds.map((id, ix) =>
                getProducts({
                  templateId: id,
                  technology,
                })
                  .then(async (template) => {
                    if (!template) {
                      throw new Error(`Template "${id}" returns null.`);
                    }

                    let templatePage: SitemapEntry;
                    if (rest[id]) {
                      templatePage = findPageByCondition(
                        sitemap.pageTree,
                        (p) => p.pageId === rest[id],
                      );
                    }
                    return {
                      ...template,
                      isBestseller: bestsellerTemplateIds.includes(id),
                      isTopprice: toppriceTemplateIds.includes(id),
                      isHighlighted: highlightedTemplateIds.includes(id),
                      ...(templatePage && {
                        templatePageLink: templatePage.path,
                      }),
                      isFTTHPrePresale,
                      ...(iadDevices && {
                        iadDeviceInfo: await getIadData(
                          iadDevices.split(',')?.[ix],
                        )
                          .then((response) => {
                            return response.content.props;
                          })
                          .catch(() => {
                            console.warn(
                              `No device recommendation found for id ${
                                iadDevices.split(',')?.[ix]
                              }.`,
                            );
                            return null;
                          }),
                      }),
                    };
                  })
                  .catch((err) => {
                    console.warn(`No template found for id ${id}:`, err);
                    return null;
                  }),
              ),
            );
            const filteredPlans: (PlanColumnProps | PlanRowProps)[] =
              plans.filter((plan) => plan);

            if (content.props.type === 'planRows') {
              content = {
                ...content,
                component: ContentType.PLAN_ROWS,
                props: {
                  ...content.props,
                  defaultRuntime: defaultRuntime || DefaultRuntime.Default,
                  plans: filteredPlans,
                  kundenweltURL: content.props.params?.kundenweltURL ?? null,
                },
              };
            } else if (content.props.type === 'planColumns') {
              const dynamicPlans = await getDynamicPlans({
                basePlans: filteredPlans.map((p) => p.id),
                modifiers: {
                  togglePhone,
                  toggleNetTv,
                },
                technology,
              });

              content = {
                ...content,
                component: ContentType.PLAN_COLUMNS,
                props: {
                  ...content.props,
                  defaultRuntime: defaultRuntime || DefaultRuntime.Default,
                  plans: filteredPlans,
                  dynamicPlans,
                  isFTTHPrePresale,
                  uncheckToggleOptions,
                  portfolio: content.props.params.portfolio ?? 'FIBER',
                  withoutCheck: !!content.props.params.withoutCheck,
                  kundenweltURL: content.props.params?.kundenweltURL ?? null,
                },
              };
            }
            break;
          }
          default:
            break;
        }
      }

      if (content.component === ContentType.PRODUCT_COMPARISON) {
        const items = await Promise.all(
          content.props.items.map(async (productItem) => {
            const templateId =
              productItem.props.id === 'CI_MODULE'
                ? ProductId.TV_CABLE_FAMILY_HD
                : //@ts-ignore
                  content.props.templateId;

            let priceInfo = null;
            try {
              const product = await getProducts({
                templateId,
              });
              priceInfo = getComparisonPriceInfo(productItem.props, product);
            } catch (e) {
              console.error(
                `Failed to get product with template Id: ${templateId} and type: ${productItem.props.type}`,
                e,
              );
            }

            return {
              ...productItem,
              ...{
                props: {
                  ...productItem.props,
                  priceInfo,
                },
              },
            };
          }),
        );
        content = {
          ...content,
          props: {
            ...content.props,
            items,
          },
        };
      }

      return content;
    }),
  );
}

export const prepareFootnotes =
  (footnotes: Footnote[] = []) =>
  (value: Placement): Footnote['footnote'] =>
    footnotes.find((fn: Footnote) => fn.placement === value)?.footnote;

export const getUploadFootnoteKey = (plan: Product): Placement => {
  switch (plan.internet?.download) {
    case 250:
      return 'upload-250';
    case 1000:
      return 'upload-1000';
    default:
      return 'upload';
  }
};

export const getContentConfig = (): ContentContext => {
  const site = getEnv().SITE;

  return {
    site,
    partnerWidgets: {
      tellja: {
        [TelljaWidgets.NETCOLOGNE]: 'PsGA9MA',
        [TelljaWidgets.NETAACHEN]: 'PqVkV9g',
        [TelljaWidgets.NETSPEED]: site === 'NetCologne' ? 'PiA7X7Q' : 'PMkS-dg',
        [TelljaWidgets.NETSPEEDYOUNG]:
          site === 'NetCologne' ? 'PZSyJbQ' : 'PRcJ6fw',
        [TelljaWidgets.NETSPEEDGLASFASER]:
          site === 'NetCologne' ? 'P5shK1Q' : '',
      },
    },
  };
};
