import {
  AvailabilityEvents,
  ContactFormEvents,
  DataLayerEvents,
  FtthTransaction,
  FtthTransactionEvent,
  PageEvent,
  PODEvents,
  ProspectRegistrationEvent,
  TariffAdvisor,
  Transaction,
  TransactionEvent,
} from '../../interfaces/tracking';

type LayerEvents =
  | PageEvent
  | TransactionEvent
  | FtthTransactionEvent
  | AvailabilityEvents
  | ContactFormEvents
  | ProspectRegistrationEvent
  | TariffAdvisor
  | PODEvents;

export const pushDataLayer = (event: LayerEvents) => {
  // Uncomment for local testing purposes
  // if (!window['dataLayer']) {
  //   window['dataLayer'] = [];
  // }
  window && window['dataLayer'] && window['dataLayer'].push(event);
};

export const pushPageView = (url: string) => {
  const pageEvent: PageEvent = {
    event: DataLayerEvents.PageView,
    page: url,
  };
  pushDataLayer(pageEvent);
};

export const pushTransaction = (transaction: Transaction): void => {
  const transactionEvent: TransactionEvent = {
    event: 'transaction',
    ...transaction,
  };
  pushDataLayer(transactionEvent);
};

export const pushFtthTransaction = (transaction: FtthTransaction): void => {
  const transactionEvent: FtthTransactionEvent = {
    event: 'transaction_ftth',
    ...transaction,
  };
  pushDataLayer(transactionEvent);
};
